import WithAuth from '../../../components/WithAuth'
import {RSButton1} from "../../../components/buttons"
import RSNavCont from '../../../components/layout/RSNavCont'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MultiLineText from '../../../components/text/MultiLineText'
// import MainCard from '../../../components/cards/MainCard'
import Form from 'react-bootstrap/Form'
import useLocalStorage from '../../../hooks/useLocalStorage'
import { CANCEL_STORAGE_KEY } from '../../../modules/values'
import { navReq } from '../../../modules/apiConsume'
import { CANCEL_STEP_2_TEXT } from '../../../modules/values'
import { FC, useState } from 'react'


// Info Text
const { title, info, tagline, options } = CANCEL_STEP_2_TEXT

/**
 * Step2 Component
 */

const Step2: FC = () => {
	// State
	const [keyIndex, setKeyIndex] = useState(0)
	const [option, setOption] = useState<keyof typeof options>('hard')
	const [feedback, setFeedback] = useState('')

	// Hooks
	const { setValue } = useLocalStorage({ key: CANCEL_STORAGE_KEY })

	// On Option Change
	const onOptionChange = (newOption: keyof typeof options) => {
		setOption(newOption)
		setKeyIndex((curKeyIndex) => curKeyIndex + 1)
	}

	// On Feedback Change
	const onFeedbackChange = (e: any) => setFeedback(e?.target?.value || '')
	// On Submit
	const onSubmit = () => {
		setValue({
			reason: option,
			other_text: feedback,
		})
		navReq('/#/cancel/step-4')
	}

	// Render
	return (
		<RSNavCont>
			{/* Card */}
			<Row className="mb-5 d-flex justify-content-center account-container cancel-plan-card mt-4">
				<Col xs={12} md={6} lg={6} xl={6} className='mx-auto'>
					{/* <MainCard
						cardType="split"
						buttonAction={onSubmit}
						buttonText="Submit"
					> */}
					{/* Title */}
					<Row className="text-center mt-4 mb-4">
						<Col>
							<h3 className=''>{title}</h3>
						</Col>
					</Row>

					{/* Info */}
					<Row className="text-center mb-4 text-small">
						<Col>
							<MultiLineText text={info} />
						</Col>
					</Row>

					{/* Tagline */}
					<Row className="text-center mb-4">
						<Col>
							<h6 className="is-h7">{tagline}</h6>
						</Col>
					</Row>

					{/* Options */}
					<Row className="text-start mb-2 d-flex justify-content-center">
						<Col xs={12}>
							<Form>
								<Form.Group controlId="reason">
									{Object.keys(options).map((key) => (
										// <Form.Check
										// 	key={`r-${keyIndex}-${key}`}
										// 	type="radio"
										// 	label={options[key as keyof typeof options]}
										// 	checked={key === option}
										// 	onChange={onOptionChange.bind(
										// 		null,
										// 		key as keyof typeof options
										// 	)}
										// 	className="has-pointer mb-3"
										// />

										<Form.Check key={`r-${keyIndex}-${key}`} type="radio" id={`r-${keyIndex}-${key}`} className="mb-3 c-checkbox--wrapper ps-0">
											<Form.Check.Input
												type="radio"
												checked={key === option}
												onChange={onOptionChange.bind(
													null,
													key as keyof typeof options
												)}
											/>
											<Row className="mb-1">
												<Col xs={12} className="pe-0">
													<Form.Check.Label className="font-14 detail-text custom-checkbox">{options[key as keyof typeof options]}</Form.Check.Label>
												</Col>
											</Row>
										</Form.Check>
									))}
									<Form.Control
										as="textarea"
										rows={3}
										value={feedback}
										onChange={onFeedbackChange}
										style={{ width: '85%', margin: '0 auto' }}
									/>
								</Form.Group>
							</Form>
						</Col>
						{/* Cancel Button */}
						<Row className="text-center mt-4 mb-4">
							<Col>
								<RSButton1
									sx={{ width: '100%', maxWidth: '220px', textTransform: 'uppercase !important' }}
									onClick={onSubmit}
								>
									Continue
								</RSButton1>
							</Col>
						</Row>
					</Row>
					{/* </MainCard> */}
				</Col>
			</Row>
		</RSNavCont>
	)
}

export default WithAuth(Step2)
