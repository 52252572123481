import { FC, useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import levelSelection from '../training-plan/build/LevelSeletion.json'
import maintenanceTypeSelection from '../training-plan/build/MaintenanceTypeSelection.json'
import RSCircledSymbol from '../../components/buttons/RSCircledSymbol'
import { SH2, P1 } from '../../components/text/RSTypography'
import { AccountProfile } from '../../api/v2/account/Account.types'
import moment from 'moment';

export type ChooseAPlanTypeProps = {
	accountProfile: AccountProfile,
	allStageDetails: any,
	addDetails: any,
	handleIsStepComplete?: any
	setUpdatedBaseDetails: any,
	raceDetails: any,
	maintenanceDateEnd: any,
	manualPlanStartDate: any,
}

const ChooseAPlanType: FC<ChooseAPlanTypeProps> = ({ allStageDetails, addDetails, handleIsStepComplete, setUpdatedBaseDetails, raceDetails, manualPlanStartDate, maintenanceDateEnd }) => {
	const stageNumber = '0'
	const { level: lvl } = allStageDetails?.[stageNumber] || {}
	const [level, setLevel] = useState<number | undefined>(lvl || undefined);

	const isMaintenancePlan = raceDetails?.selectedRaceDistance.toLowerCase().includes('maintenance')

	const calculateWeeks = (startDate: any, endDate: any) => {
		const dateRangeDaysDiff = moment(endDate).diff(moment(startDate).subtract(1, 'd'), 'days')
		const weeksCount = Math.floor(dateRangeDaysDiff / 7)
		return weeksCount
	}

	useEffect(() => {
		addDetails(stageNumber, {
			level: level,
			stepResults: [],
			currentStep: 0
		})
		handleIsStepComplete(level ? true : false, stageNumber)

		allStageDetails?.['0']?.level != level && setUpdatedBaseDetails(true)

	}, [level])

	return <Box sx={{ px: 3 }}>
		<Grid container xs={12} justifyContent='center' alignItems='center'>
			<SH2 size={3} sx={{ pb: '10px', width: '100%' }}>
				{isMaintenancePlan ? "What's Your Running Goal?" : "How Do You Like to Train?"}
			</SH2>
			<P1 sx={{ pb: 3, width: '100%' }}>
				{isMaintenancePlan ? "Select whether you want to maintain your fitness or build your mileage. We'll tailor your plan to help you stay strong, consistent, and injury-free." : "Choose a plan that fits your style, and we'll help you reach the finish line feeling strong and prepared."}
			</P1>
			<Grid container xs={12} sx={{ flexDirection: 'column'}} justifyContent='center' alignItems='center'>
				{(isMaintenancePlan ? maintenanceTypeSelection : levelSelection).map((lvl: any, lvlI: number) =>
					<Grid container className='race-selection-container' key={lvlI + 'build-plan-lvl'} xs={12} sx={{ fontFamily: 'Poppins-Light !important', fontSize: '18px !important', mb: '10px' }} justifyContent="flex-start" alignItems="center">
						<RSCircledSymbol
							symbol={lvl.id}
							title={lvl.title_2}
							subtitle={isMaintenancePlan && lvl.id == 2 ? lvl.description + ` ${calculateWeeks(manualPlanStartDate, maintenanceDateEnd)} weeks of training` : lvl.description}
							onClick={() => setLevel(lvl.id)}
							secondary={level !== lvl.id}
							sx={{ p: '15px 20px !important' }}
							styleTwo
						/>
					</Grid>
				)}
			</Grid>
		</Grid>
	</Box>
}

export default ChooseAPlanType;
