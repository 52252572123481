/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import date from 'locutus/php/datetime/date'
import { strtotime } from 'locutus/php/datetime'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew'
import CalendarMonthIcon from '@mui/icons-material/EventNoteOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import Backdrop from '@mui/material/Backdrop';

import {
	UserNotesRecord,
	MarathonValRecord,
	RaceResult
} from '../../api/v2/types'

import { IRaceType } from './build/RaceType'
import { RaceOptions } from './build/RaceOptions'
import {
	DAYS_OF_WEEK,
	DAYS_OF_WEEK_ALT,
	//	DAYS_OF_WEEK_ABBV,
	//	DAYS_OF_WEEK_ALT_ABBV
} from './build/DaysOptions'

import WithAuth from '../../components/WithAuth'
import RSNavCont from '../../components/layout/RSNavCont'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import RSDrawer from '../../components/layout/RSDrawer'
import { H1 } from '../../components/text/RSTypography'
import TrainingCalendarModal from '../../components/your-training-plan-sidebar/Calendar';
import Options from '../../components/your-training-plan-sidebar/Options';
import DailyActivity from '../../components/your-training-plan-sidebar/DailyActivity';
import WeekSelector from '../../components/your-training-plan-sidebar/WeekSelector';

import useBuildTrainingPlan from './hooks/useBuildTrainingPlan'
import useCalendarBase from './hooks/useCalendarBase'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

import raceSelection from './build/RaceSelection.json'
import { getColorCode } from '../../modules/miscUtils'
import { AllRSCategories, AuthPageProps } from '../../modules/types'
import { GetUserInfo, GetUserOverride, GetUserVdotChanges, GetAllUserNotes, GetUserRestComments, GetMarathonValues, GetUserNotes, GetRaceResult } from '../../modules/trainingPlanActions'

import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore'
import { IRaceDetails } from './types'
import { getReq, postReq } from '../../modules/apiConsume'
import { IGarminStore } from '../../store/createGarminStore'
import { ActivityDetailsStorage, Summary, GarminWorkout, GarminWorkoutStep, Step } from '../../api/v2/garmin-connect/garmin-connect.types'
import useProgramPage from '../../hooks/useProgramPage'
import ProgramMobileView from '../programs/v2/ProgramMobileView'
import useFavorites from '../favorites/useFavorites'
import ExercisePage from '../exercises/ExercisePage'
import useQueryParams from '../../hooks/useQueryParams'
import { Video } from '../../modules/videos/allVideos'
import { isMobile, isIOS, isAndroid, isSafari } from 'react-device-detect'
import GenericModal from '../../components/misc/GenericModal'
import VideoPlayer from '../VideoPlayer'
import useRNBridge from '../../hooks/useRNBridge'
import useSWR from 'swr'

const YourTrainingPlanClone: FC<AuthPageProps> = ({ hasLoaded, userInfo: accountInfo }) => {
	const { sendDataToReactNative } = useRNBridge()
	const isMobileHook = useIsMobileScreen();

	const { setCurrentDayActivities, currentDayActivities, currentWeekActivities, setCurrentWeekActivities } = useStore((state: IGarminStore) => state)

	const [selectedDate, setSelectedDate] = useState('')
	const [userCustomPaces, setUserCustomPaces] = useState<Array<any>>([])

	const [userGarminDetails, setUserGarminDetails] = useState<any>()
	const [currentVdot, setCurrentVdot] = useState({ vdot: 0, targetm: 0 })

	const [userNotes, setUserNotes] = useState<UserNotesRecord | undefined>(
		undefined
	)

	const { planId, profile, requestKey: deviceKey } = useQueryParams()

	const [dayData, setDayData] = useState<
		| { marathon?: any; marathonPaces?: Array<any>; workouts?: Array<any>, date?: any }
		| undefined
	>(undefined)

	const [userInfo, setUserInfo] = useState<any>(null)
	//	const [daysOfWeekAbbv, setDaysOfWeekAbbv] = useState<Array<any>>([])
	const [showViewCalendar, setShowViewCalendar] = useState(false)
	const [showOptions, setShowOptions] = useState(false)
	const [reload, setReload] = useState(false)
	const [selectedDay, setSelectedDay] = useState<number>(0)
	const [marathonDailyValues, setMarathonDailyValues] = useState<any>([])
	const [daySelectorValues, setDaySelectorValues] = useState<any>({})
	const [weekDays, setWeekDays] = useState<Array<any>>([])
	const [editWeek, setEditWeek] = useState(false);
	const [selectedWeek, setSelectedWeek] = useState<{
		week: string
		key: number
	}>({ week: '', key: 1 })
	const [currentWeek, setCurrentWeek] = useState<{ week: string; key: number }>({ week: '', key: 1 })
	const [selectedRace, setSelectedRace] = useState<IRaceType | null>(null)
	const [raceDay, setRaceDay] = useState<number | undefined>(undefined)
	const [raceDetails, setRaceDetails] = useState<IRaceDetails | undefined>(undefined)
	const [allUserNotes, setAllUserNotes] = useState<Array<UserNotesRecord> | undefined>(undefined)
	const [title, setTitle] = useState<string | undefined>(undefined)
	const [loadingValues, setLoadingValues] = useState(true)
	const [calendarLoading, setCalendarLoading] = useState(true)
	const [weekLoading, setWeekLoading] = useState(false)
	const [isForward, setIsForward] = useState('')
	const [changeLoc, setChangeLoc] = useState(false)
	const [raceResults, setRaceResults] = useState<RaceResult | undefined>(undefined)
	const [calendarData, setCalendarData] = useState<any>({})
	//	const [padBot, setPadBot] = useState(0)
	const containerRef = useRef()

	const [showProgramView, setShowProgramView] = useState(false)
	const [showExerciseVideo, setShowExerciseVideo] = useState(false)
	const [showAssessmentVideo, setShowAssessmentVideo] = useState(false)
	const [programType, setProgramType] = useState<keyof AllRSCategories['programs']>('Base3')

	const navigate = useNavigate();

	const { currentUser } = useStore((state: ICurrentUser) => state)
	const { setCurrentLocation, setUserOverrides, userOverrides, setUserVdotAdjustments, setUserRestComments, userVdotAdjustments } = useStore((state: ITrainingPlanStore) => state)
	const { buildCalendarMonth: buildCalendarMonthBase, loadingValues: calendarBaseLoading } = useCalendarBase(userOverrides);
	const { getDailyActivityDisplay, getRaceDetailsValues, getRaceDayDetails, getRaceDistance, getRaceDistancePace, extractPace, getOverridePace, distanceInterpret, convertPaceBase, getWorkoutVideoDetails, computelWeekTotalDistance, handleAdjustPaceByLevel } = useBuildTrainingPlan(Number(userInfo?.training_level))
	const { getWeekDays, convertDateToUTC, ConvertM2Mt, convertDistance, getCurrentVDOT } = useTrainingPlanUtils()

	const { favorites, toggleFavorite, getFavorites, getIsFavorited } = useFavorites(accountInfo?.account_id as number, undefined, 'workouts', true)

	const [isPlaying, setIsPlaying] = useState(false)

	const [currentVideoId, setCurrentVideoId] = useState<number>()
	const [currentRecoveryVideo, setCurrentRecoveryVideo] = useState<Video | undefined>()
	const [isLoadingMark, setIsLoadingMark] = useState(false)
	const [isFavorite, setIsFavorite] = useState(false)

	const [finishedGarminSync, setFinishedGarminSync] = useState(false)
	const [hasGarminSyncError, setHasGarminSyncError] = useState(false)

	const requestKey = localStorage.getItem('deviceRequestKey') || undefined

	const [showUpdateModal, setShowUpdateModal] = useState(false)

	const [trainingTitle, setTrainingTitle] = useState('')

	const [calendarOpen, setCalendarOpen] = useState(false)

	const { data: accountProfile, isLoading: accountLoading, mutate } = useSWR(userInfo?.internal_id ? `/account-profile?account_id=${userInfo?.internal_id}` : null, { refreshInterval: 0 })

	const {
		currentWorkoutVideoId,
		currentWorkoutVideoProgressInfo,
		currentWorkoutVideo,
		title: videoTitle
	} = useProgramPage({ programType, videoId: currentVideoId, userInfo: accountInfo })


	const onLike = async (videoId: string) => {
		const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
		await toggleFavorite(accountInfo?.account_id as number, Number(videoId), !isLiked)
	}

	const handleUpdateApp = () => {
		setShowUpdateModal(false)
		if (isMobile && isIOS) {
			window.location.assign('https://apps.apple.com/app/1507476659')
		}
		else if (isMobile && isAndroid)
			window.location.assign('https://play.google.com/store/apps/details?id=com.runsmartonline');
	}

	const handleOpenVideo = (link: any, type?: string) => {

		// if (isMobileApp) {
		// 	window.scrollTo({
		// 		top: 0,
		// 		behavior: "smooth"
		// 	});

		// 	const splitLink = link.id ? undefined : link?.split('/')
		// 	const videoId = link?.id ? Number(link.id) : Number(splitLink[splitLink?.length - 1])
		// 	const selectedVideo = allVideos[videoId]
		// 	if (videoId && selectedVideo) {
		// 		if (googleCastStatus == 'playing' || googleCastStatus == 'active') {
		// 			playVideo(selectedVideo.id)
		// 		}
		// 		else if ((type && type === "recovery") || link.includes('foam-rolling') || link.includes('exercises')) {
		// 			setCurrentVideoId(Number(selectedVideo.id))
		// 			setShowExerciseVideo(true)
		// 		}
		// 		else {
		// 			const title = `${selectedVideo?.category.includes('All') ? selectedVideo?.category.split(' ')[1] : selectedVideo?.category} | ${selectedVideo?.headingTitle}`
		// 			setCurrentTitle(title)
		// 			setVimeoId(selectedVideo.id)
		// 			setCurrentList([])
		// 			setShowVimeoPlayer(true)
		// 			setIsFullScreen(true)
		// 			setIsShowControls(true)
		// 			setCustomPlayerPlay(true)
		// 		}
		// 	}
		// 	else {
		// 		const title = `${link?.category.includes('All') ? link?.category.split(' ')[1] : link?.category} | ${link?.headingTitle}`
		// 		setCurrentTitle(title)
		// 		setVimeoId(link.id)
		// 		setCurrentList([])
		// 		setShowVimeoPlayer(true)
		// 		setIsFullScreen(true)
		// 		setIsShowControls(true)
		// 		setCustomPlayerPlay(true)
		// 	}
		// }
		// else {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});

		if (type && type === "recovery") {
			setCurrentVideoId(Number(link.id))
			setShowExerciseVideo(true)

		}
		else if (!type && link && link.includes('exercises') && !link.includes('foam-rolling')) {
			setCurrentVideoId(Number(link.split('/')[5]))
			setShowExerciseVideo(true)
		}
		else if (type && type === "pre-assessment") {
			setCurrentVideoId(link?.id | 0)
			setCurrentRecoveryVideo(link)
			setShowAssessmentVideo(true)
		}
		else {
			const splitLink = link.split('/')
			setCurrentVideoId(Number(splitLink[splitLink.length - 1]))
			if (link.includes('foam-rolling')) {
				setShowExerciseVideo(true)
			}
			else {
				setProgramType(link.includes('base6') ? 'Base6' : link.includes('yoga') ? 'Yoga' : 'Base3')
				setShowProgramView(true)
			}
		}
		// }

	}

	const handleMarkAsFavorite = async (isChecked: boolean) => {
		if (currentWorkoutVideo) {
			setIsLoadingMark(true)
			await toggleFavorite(accountInfo?.account_id as number, currentWorkoutVideoId as number, isChecked)
			await getFavorites()
			setIsLoadingMark(false)
		}
	}

	const handleOnPlay = async () => {
		setIsPlaying(true)
	}

	const getRaceResult = async (abort: AbortSignal, updateOnly?: boolean) => {
		await GetRaceResult(userInfo?.internal_id as number, userInfo?.uid as number, abort, userInfo?.type as number).then((res) => {
			if (updateOnly)
				userInfo.raceResults = res?.data?.result
			else
				setRaceResults(res?.data?.result)
			setLoadingValues(false)
		})
	}

	const getUserOverrides = async (abort: AbortSignal, isReload?: boolean) => {
		let userOverridesNew;
		if (userOverrides && userOverrides?.length > 0 && !isReload)
			userOverridesNew = userOverrides
		else {
			userOverridesNew = await GetUserOverride(userInfo?.uid, abort)
			userOverridesNew = userOverridesNew?.data?.result
		}
		if (userOverridesNew?.length > 0) {
			setUserOverrides(userOverridesNew)
		}
	}

	const getUserVdotChanges = async (abort: AbortSignal) => {
		await GetUserVdotChanges(userInfo?.uid as number, abort).then((res) => {
			setUserVdotAdjustments(res?.data?.result)
			setRaceDetails(getRaceDetailsValues(raceDetails, userInfo, raceDay))
		})
	}

	const getAllUserNotes = async (abort?: AbortSignal) => {
		const allUserNotes = await GetAllUserNotes(userInfo?.uid, abort)
		if (
			allUserNotes?.data?.messages === 'success'
			// && allUserNotes?.data?.result.length > 0
		) {
			setAllUserNotes(allUserNotes?.data.result)
		}
	}

	const getUserRestComments = async (abort: AbortSignal) =>
		GetUserRestComments(userInfo?.uid, abort).then((res) =>
			setUserRestComments(res?.data?.result)
		)

	const buildMarathonPaces = (values: Array<any>) => {
		const paces: Array<any> = []
		values.forEach((value) => {
			paces.push({
				mar_id: value.paceMarId,
				paceId: value.paceId,
				pace: value.pace,
				notes: value.notes,
				orderid: value.paceOrderId,
			})
		})

		const uniqueValues = paces.filter(
			(a, i) => paces.findIndex((s) => a.paceId === s.paceId) === i
		)
		return uniqueValues
	}

	const buildWorkouts = (values: Array<any>) => {
		const workouts: Array<any> = []
		values.forEach((value) => {
			workouts.push({
				mid: value.workoutMarId,
				workoutId: value.woId,
				title: value.title,
				description: value.description,
				link: value.link,
				orderid: value.wOrderid,
			})
		})

		const uniqueValues = workouts.filter(
			(a, i) => workouts.findIndex((s) => a.workoutId === s.workoutId) === i
		)
		return uniqueValues
	}

	const getWeeklyActivities = async (signal: AbortSignal) => {
		const { type, weeks, distance, monday_start } = userInfo
		const details = getRaceDayDetails(userInfo)
		const saturdayRaceMondayStart = details.raceDay == 5 && details.saturdayRace
		const saturdayRaceSundayStart =
			details.raceDay == 6 && !details.saturdayRace
		//		let userNote

		const checkRaceWeek = () => {
			//sunday start - monday race
			if (details.raceDay == 1 && +weeks + 1 == selectedWeek.key && type != 4)
				return true
			//sunday start - sunday race
			else if (
				details.raceDay == 0 &&
				+weeks + 1 == selectedWeek.key &&
				type != 4
			)
				return true
			//sunday start - saturday race
			else if (
				saturdayRaceSundayStart &&
				weeks == selectedWeek.key &&
				type != 4
			)
				return true
			else if (
				saturdayRaceMondayStart &&
				weeks == selectedWeek.key &&
				type != 4
			) {
				return true
			} else return false
		}
		const isRaceWeek = checkRaceWeek()

		const values: Array<any> = []
		let marathons: Array<any> = []

		const days = monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK
		setLoadingValues(true)

		const params: MarathonValRecord = {
			marathon: type,
			type: weeks,
			training_goal: distance,
			week: selectedWeek?.key,
			distance: distance
		}
		setWeekLoading(true)
		weeks >= selectedWeek?.key && await GetMarathonValues(params, signal).then(async (res) => {
			if (res && res.data.messages === 'success') {
				marathons = res.data.result.marathon
			}
		})

		if (!isRaceWeek)
			for (const [index] of Object.entries(days)) {
				const indexVal = +(index as unknown as number) + 1
				const marathon = marathons?.filter((x: any) => x.day == indexVal)

				//				if (allUserNotes && allUserNotes.length > 0 && marathon)
				//					userNote = allUserNotes?.find((x) => x?.marid == marathon[0]?.id)

				if (marathon) {
					const data = {
						marathon: {
							id: marathon[0]?.id,
							marathon: marathon[0]?.marathon,
							type: marathon[0]?.type,
							training_goal: marathon[0]?.training_goal,
							week: marathon[0]?.week,
							day: marathon[0]?.day,
							distance: marathon[0]?.distance,
							race_day: marathon[0]?.race_day,
						},
						marathonPaces: buildMarathonPaces(marathon),
						workouts: buildWorkouts(marathon),
						// overrides: buildOverrides(marathon)
					}
					values.push({ ...data })
				} else values.push([])
			}
		else if (isRaceWeek)
			for (const [index] of Object.entries(days)) {
				const indexVal =
					+(index as unknown as number) + 2 - (saturdayRaceMondayStart ? 1 : 0)
				const marathon = marathons?.filter((x: any) => x.day == indexVal)
				//				if (allUserNotes && allUserNotes.length > 0 && marathon)
				//					userNote = allUserNotes?.find((x) => x.marid == marathon[0]?.id)

				if (marathon && +index + 1 != 7) {
					const data = {
						marathon: {
							id: marathon[0]?.id,
							marathon: marathon[0]?.marathon,
							type: marathon[0]?.type,
							training_goal: marathon[0]?.training_goal,
							week: marathon[0]?.week,
							day: marathon[0]?.day - (saturdayRaceMondayStart ? 0 : 1),
							distance: marathon[0]?.distance,
							race_day: marathon[0]?.race_day,
						},
						marathonPaces: buildMarathonPaces(marathon),
						workouts: buildWorkouts(marathon),
						// overrides: buildOverrides(marathon)
					}
					if (saturdayRaceMondayStart && data.marathon.day == 6) {
						//
					}
					// if (saturdayRaceSundayStart && data.marathon.day != 6) {
					else values.push({ ...data })
					// }
				} else {
					values.push({
						marathon: {
							id: 5000 + userInfo?.uid,
							marathon: userInfo?.type,
							type: userInfo?.weeks,
							training_goal: userInfo?.distance,
							week: selectedWeek.key,
							day:
								details.raceDay == 1
									? 2
									: details.raceDay == 0
										? 1
										: saturdayRaceMondayStart
											? 6
											: 7,
							distance: getRaceDistance(userInfo?.type as number),
							race_day: 1,
						},
						marathonPaces: [
							{
								mar_id: 5000 + userInfo?.uid,
								paceId: 9999 + userInfo?.uid,
								pace: 'Race Day',
								notes: `${getRaceDistancePace(userInfo)} ${userInfo?.km === 'Y' ? 'kms' : 'miles'
									}`,
								orderid: userInfo?.type,
							},
						],
						workouts: [],
					})
				}
			}

		//  		setLoadingValues(false)
		let newVal = {}
		for (const x of values) {
			if (x?.marathon?.day) {
				newVal = { ...newVal, [x.marathon.id]: x }
			}
		}

		setDaySelectorValues(newVal)
		setMarathonDailyValues(values)
		if (title) setTitle((state) => state)
		else
			setTitle(
				`${selectedRace?.title} ${selectedRace?.value != 4 ? selectedRace?.subTitle : ''
				}`
			)

		setWeekLoading(false)
	}

	/*OLD FEATURES, TO BE REFACTORED IN PHASE 3*/


	const handleEnableGarmin = (userGarminDetails: any) => {
		const momentJoined = moment(userGarminDetails?.last_modified.split('T')[0])
		const selectedMoment = moment(selectedDate)
		const isSameOrAfter = selectedMoment.isSameOrAfter(momentJoined);
		return isSameOrAfter;
	}

	const getGarminDetails = async () => {
		const response = await getReq('/v2/garmin/user')
		const data = response.data.result[0]
		setUserGarminDetails(data)
	}

	const getUserNotes = async (marathonDayValue: any, abort: AbortSignal) => {
		const userNotes = await GetUserNotes(
			userInfo?.uid,
			marathonDayValue?.marathon?.id,
			abort
		)
		if (
			userNotes?.data?.messages === 'success' && userNotes?.data?.result?.length > 0
		) {
			setUserNotes(userNotes?.data?.result)
		}
	}

	const getDistanceValue = () => {
		const val =
			userInfo?.km == 'Y'
				? convertDistance(ConvertM2Mt(dayData?.marathon?.distance) / 1000)
				: dayData?.marathon?.distance

		if (!isNaN(val)) return val
		return 0
	}

	const handelGetDailyActivities = async (dateFrom: string, dateTo: string) => {
		setCurrentDayActivities([])

		if (userGarminDetails && userGarminDetails.status == 1) {
			setLoadingValues(true)
			const currentActivity = await getReq(`/v2/garmin/activities/details?user_id=${userGarminDetails?.garmin_id}&activity_date_from=${dateFrom}&activity_date_to=${dateTo}`)
			setLoadingValues(false)
			const results = currentActivity?.data?.result as ActivityDetailsStorage[]
			setCurrentWeekActivities(results)
		}
	}

	const getConvertedPace = (val: any, original?: boolean) => {
		const convert = extractPace(
			val,
			dayData?.marathon?.distance,
			0,
			currentVdot?.vdot,
			userInfo?.km === 'Y' ? true : false,
			currentVdot?.targetm,
			userInfo?.aggressiveness,
			original ? [] : userCustomPaces,
			userInfo?.distance
		)
		const value = convert.setPace.split('/')

		const rawPace = convert.rawPace

		return { value, rawPace }
	}

	const getOverridePaceData = async (userId: number, abort: AbortSignal) => {
		await getOverridePace(userId, abort).then((res) =>
			setUserCustomPaces(res?.data?.result)
		)
	}

	const getTotalDistance = (details: any[], userNotes?: any) => {
		// const isRaceWeek = userInfo?.weeks == selectedWeek.key && userInfo?.type != 4
		// if (!isRaceWeek) {
		if (userInfo?.km === 'Y')
			return (
				(ConvertM2Mt(+computelWeekTotalDistance(details, userNotes)) / 1000)?.toFixed(1)?.toString()?.replace(/(\.0+|0+)$/, '') + ' Kms'
			)
		else if (userInfo?.km === 'N')
			return computelWeekTotalDistance(details, userNotes) + ' Miles'
		// }
		// else
		// 	if (userInfo?.km === 'Y')
		// 		return getRaceDistancePace(userInfo)?.toString() + " Kms"
		// 	else if (userInfo?.km === 'N')
		// 		return getRaceDistancePace(userInfo)?.toString() + " Miles"
	}

	const convertPaceToMpSec = (pace: string, distanceM: number, isKm: boolean) => {
		const value = getConvertedPace(pace)
		const mins = +value?.rawPace?.split(' ')[0].replace('m', '') * 60
		const secs = +value?.rawPace?.split(' ')[1].replace('s', '')

		const totalTimeInSec = mins + secs;

		return isKm ? 1000 / totalTimeInSec : 1609.344 / totalTimeInSec
	}
	const convertTextToIntervalTime = (notes: string, prevVal: any) => {
		const divisor = notes.includes("is equal") ? 1 : notes.includes("is half") ? 2 : notes.includes("is quarter") ? 4 : 1

		const convertedTime = convertPaceBase(
			prevVal?.notes as string,
			getConvertedPace(prevVal).rawPace,
			userInfo?.km == 'Y' ? true : false, divisor)

		return convertedTime && notes.toLowerCase().includes('recovery') ? "Recover for " + convertedTime : undefined
	}
	const hasRepetition = (paces: any) => {
		const repeat = paces.find((x: any) => x.pace === '')
		if (repeat)
			return true
		else
			return false
	}
	const getRecoveryTimeAndType = (notes: string, prevVal: any) => {
		const stringTime = convertTextToIntervalTime(notes, prevVal)?.toLowerCase()?.split('recover for')[1]?.trim()
		const min = Number(stringTime?.split(' ')[0].replace('m', ''))
		const sec = Number(stringTime?.split(' ')[1].replace('s', ''))

		const convertedTime = ((min * 60) + sec) || 0

		const type = notes.toLowerCase().includes('minute') || notes.toLowerCase().includes('minutes') ? 'minute' : notes.toLowerCase().includes('second') || notes.toLowerCase().includes('seconds') ? 'second' : undefined

		const validActivity = notes.toLowerCase().includes('walk') || notes.toLowerCase().includes('walk/jog') || notes.toLowerCase().includes('slow jog') || notes.toLowerCase().includes('walk / jog') || notes.toLowerCase().includes('recovery') || (notes.toLowerCase().includes('recovery') && (notes.toLowerCase().includes('is equal') || notes.toLowerCase().includes('is half') || notes.toLowerCase().includes('is quarter'))) ? true : false

		const timeValue = notes.match(/(\d+)/);

		const durationInSeconds = type === 'minute' ? (timeValue ? Number(timeValue[0]) * 60 : 0) : validActivity && convertedTime > 0 ? convertedTime : validActivity ? 90 : 0

		return { validActivity, durationInSeconds }
	}
	const noteRepeat = (notes: string) => {
		if (notes?.toLowerCase().includes('repeat') && notes?.toLowerCase().includes('mile')) {
			return 0
		}
		else {
			if (notes?.toLowerCase().includes('repeat')) {
				const repeatTimes = notes?.toLowerCase().includes('times') ? notes.split(' ')[1] : notes.split(' ')[1].replace('x', '') || 1
				if (repeatTimes === "repeat")
					return 1
				else if (Number(repeatTimes) > 0 && repeatTimes !== "repeat") {
					return repeatTimes
				}
				else return undefined
			}
		}

	}
	const convertDistanceToMeter = (isKm: boolean, distance: number) => {
		let newDistance = 0;
		if (isKm)
			newDistance = distance * 1000
		else if (!isKm)
			newDistance = distance * 1609.344
		return newDistance
	}
	const getIntensityName = (paces: any[], index: number) => {
		if (paces[index]?.notes.toLowerCase().includes('recovery'))
			return 'RECOVERY'
		else if (paces.length > 1 && index == 0)
			return 'WARMUP'
		else if (paces.length > 1 && index == paces.length - 1)
			return 'COOLDOWN'
		else
			return 'INTERVAL'
	}
	const groupPaces = (nextNote: string, currentNote: string, pace: string, index?: number) => {
		if ((nextNote === ".5 miles" || nextNote === ".5 mile" || nextNote?.toLowerCase()?.includes('walk') || nextNote === ".25 mile") && (currentNote === "2 miles" || currentNote === "1 mile" || currentNote === "2.5 miles" || currentNote.includes('.75')) && pace === "Threshold") {
			return true
		}
		else if (hasRepetition(dayData?.marathonPaces) && (nextNote === "1 mile" || nextNote?.toLowerCase()?.includes('recovery')) && (currentNote === "2 miles" || currentNote === "2 mile repeat" || currentNote === "1 mile") && (pace === "HalfMarathon" || pace === "Threshold" || pace === "Marathon") && (index && dayData?.marathonPaces?.[index + 2]?.notes?.toLowerCase()?.includes('repeat'))) {
			return true
		}
		else if ((nextNote?.toLowerCase()?.includes('recover') || nextNote?.toLowerCase()?.includes('recovery')) && (currentNote === "200m" || currentNote === "400m" || currentNote === "600m" || currentNote === "800m" || currentNote === "1000m" || currentNote === "1200m" || currentNote === "1600m") && (pace === "Interval" || pace === "Repetition" || pace === "Threshold")) {
			return true
		}
		else if ((nextNote?.toLowerCase()?.includes('walk') || nextNote?.toLowerCase()?.includes('recovery')) && currentNote === "5 minutes" && (pace === "HalfMarathon" || pace === "Easy")) {
			return true
		}

		else {
			return false
		}
	}

	const isDistanceValueRun = (currentNote: string) => {
		if (currentNote === "200m" || currentNote === "400m" || currentNote === "600m" || currentNote === "800m" || currentNote === "1000m" || currentNote === "1200m" || currentNote === "1600m") {
			return { isMeter: true, meters: currentNote.split('m')[0] }
		}
		else if (currentNote.includes('mile') || currentNote.includes('miles')) {
			return { isMeter: false, meters: 0 }
		}
	}

	const getDValue = (pace: any, distance?: any) => {
		if (pace?.notes) {
			if (distance) {
				return distance
			}
			else {
				const meterDistance = Number(distanceInterpret(pace?.notes, userInfo?.km == 'Y' ? true : false, pace?.pace, dayData?.marathon?.distance)?.split('m')?.[0])
				if (meterDistance >= 200) {
					return meterDistance * 0.0006214
				}
				else {
					const dI = Number(distanceInterpret(pace?.notes, userInfo?.km === "Y" ? true : false, pace?.pace, dayData?.marathon?.distance)?.split(' ')?.[0])
					return dI ? dI : 0
				}
			}

		}
		return 0
	}
	const getTargetValue = (type: number, pace: any, distance?: any) => {
		//1 low, 2 normal, 3 high
		const value = convertPaceToMpSec(pace, Number(isDistanceValueRun(pace?.notes?.trim() || '')?.isMeter ? Number(isDistanceValueRun(pace?.notes?.trim() || '')?.meters) : convertDistanceToMeter(userInfo?.km === "Y" ? true : false, getDValue(pace, distance)).toPrecision(2)), userInfo?.km == 'Y' ? true : false) + (type == 1 ? 0.08 : type == 2 ? 0 : -0.08)
		return value

	}
	const generateSteps = (marathonPaces: Array<any>, customDistance?: any) => {
		const steps: GarminWorkoutStep[] = []
		let subStepsCount = 0
		let repeatSteps: Step[] = []
		let currentStep = 0;
		const generateSubSteps = (repeat: number, currentOrder: number, marathonPaces: any, index: number, type: string, duration?: number): Step[] => {
			const pace = marathonPaces?.[index] ? marathonPaces[index] : {}
			if (type === "run") {
				if (pace?.notes !== "5 minutes")
					for (var i = 0; i < repeat; i++) {
						subStepsCount = subStepsCount + 1
						repeatSteps.push({
							intensity: getIntensityName(marathonPaces, index),
							type: "WorkoutStep",
							stepOrder: currentOrder++,
							description: "Run",
							durationType: "DISTANCE",
							durationValue: isDistanceValueRun(pace?.notes?.trim() || '')?.isMeter ? Number(isDistanceValueRun(pace?.notes?.trim() || '')?.meters) : Number(convertDistanceToMeter(userInfo?.km === "Y" ? true : false, getDValue(pace, customDistance))) || 0,
							targetType: "PACE",
							targetValue: getTargetValue(2, pace, customDistance),
							targetValueLow: getTargetValue(1, pace, customDistance),
							targetValueHigh: getTargetValue(3, pace, customDistance)
						})
					}
				else
					for (var i = 0; i < repeat; i++) {
						subStepsCount = subStepsCount + 1
						repeatSteps.push({
							intensity: getIntensityName(marathonPaces, index),
							type: "WorkoutStep",
							stepOrder: currentOrder++,
							description: "Run",
							durationType: "TIME",
							durationValue: 300,
							targetType: "PACE",
							targetValue: getTargetValue(2, pace, customDistance),
							targetValueLow: getTargetValue(1, pace, customDistance),
							targetValueHigh: getTargetValue(3, pace, customDistance)
						})
					}
			}
			else if (type === "recovery") {
				for (var i = 0; i < repeat; i++) {
					subStepsCount = subStepsCount + 1
					repeatSteps.push({
						intensity: "RECOVERY",
						type: "WorkoutStep",
						stepOrder: currentOrder++,
						description: "Walk/Jog",
						durationType: "TIME",
						durationValue: duration || 0,
						targetType: "OPEN"
					})
				}
			}

			return repeatSteps;
		}
		marathonPaces.forEach((pace, index) => {

			const recoveryActivity = getRecoveryTimeAndType(pace?.notes, marathonPaces[index - 1])

			const repeat: number = noteRepeat(marathonPaces[index + 1]?.notes) as number || noteRepeat(marathonPaces[index + 2]?.notes) as number || 0
			const isRepeat = (pace?.notes?.toLowerCase().includes('miles') || pace?.notes?.toLowerCase().includes('mile') || pace?.notes?.toLowerCase().includes('400m') || pace?.notes?.toLowerCase().includes('800m') || pace?.notes?.toLowerCase().includes('1000m') || pace?.notes?.toLowerCase().includes('1200m') || pace?.notes?.toLowerCase().includes('200m') || pace?.notes?.toLowerCase().includes('600m') || pace?.notes?.toLowerCase().includes('1600m')) && repeat > 0 || recoveryActivity.validActivity && repeat > 0 || pace?.notes?.toLowerCase().includes('5 minutes') && repeat > 0

			const isGrouped = groupPaces(marathonPaces[index + 1]?.notes.trim(), pace?.notes?.trim(), pace?.pace?.trim(), index)

			const isPreviousGroup = index > 0 ? groupPaces(marathonPaces[index]?.notes?.trim(), marathonPaces[index - 1]?.notes?.trim(), marathonPaces[index - 1]?.pace?.trim(), index) || recoveryActivity.validActivity && repeat > 0 : false
			if (pace?.pace && getConvertedPace(pace).value[1] && !isRepeat && !isPreviousGroup) {
				if (pace?.notes !== "5 minutes") {
					steps.push({
						intensity: getIntensityName(marathonPaces, index),
						type: "WorkoutStep",
						stepOrder: subStepsCount > 0 ? ((steps.length + 1) + subStepsCount) : steps.length + 1,
						description: "Run",
						durationType: "DISTANCE",
						durationValue: (pace?.pace?.toLowerCase().trim() === "10k" || pace?.pace?.toLowerCase().trim() === "marathon" || pace?.pace?.toLowerCase().trim() === "base" || pace?.pace?.toLowerCase().trim() === "easy") ? convertDistanceToMeter(userInfo?.km === "Y", Number(customDistance ? customDistance : (dayData && dayData.marathonPaces && dayData?.marathonPaces?.length > 1) ? getDValue(pace, customDistance) : userInfo?.km == "Y" ? (convertDistanceToMeter(false, dayData?.marathon?.distance?.toFixed(1)) / 1000).toFixed(1) : dayData?.marathon?.distance?.toFixed(1))) : Number(convertDistanceToMeter(userInfo?.km === "Y" ? true : false, getDValue(pace, customDistance))) || 0,
						targetType: "PACE",
						targetValue: getTargetValue(2, pace, customDistance),
						targetValueLow: getTargetValue(1, pace, customDistance),
						targetValueHigh: getTargetValue(3, pace, customDistance)
					})
				}
				else
					steps.push({
						intensity: getIntensityName(marathonPaces, index),
						type: "WorkoutStep",
						stepOrder: subStepsCount > 0 ? ((steps.length + 1) + subStepsCount) : steps.length + 1,
						description: "Run",
						durationType: "TIME",
						durationValue: 300,
						targetType: "PACE",
						targetValue: getTargetValue(2, pace, customDistance),
						targetValueLow: getTargetValue(1, pace, customDistance),
						targetValueHigh: getTargetValue(3, pace, customDistance)
					})
			}
			else if (pace?.pace && getConvertedPace(pace).value[1] && isRepeat || recoveryActivity.validActivity && isRepeat) {
				if (isGrouped) {
					steps.push({
						type: "WorkoutRepeatStep",
						stepOrder: subStepsCount > 0 ? ((steps.length + 1) + subStepsCount) : steps.length + 1,
						repeatType: "REPEAT_UNTIL_STEPS_CMPLT",
						repeatValue: repeat,
						steps: generateSubSteps(1, (subStepsCount > 0 ? ((steps.length + 2) + subStepsCount) : steps.length + 2), marathonPaces, index, "run")
					})
					currentStep = steps.length
				}
				else if (isPreviousGroup) {
					steps[currentStep - 1].steps = generateSubSteps(1, (subStepsCount > 0 ? ((steps?.length + 1) + subStepsCount) : steps?.length + 1), marathonPaces, index, recoveryActivity.validActivity ? "recovery" : "run", recoveryActivity.durationInSeconds)
					repeatSteps = []
				}

				else if (!isGrouped) {
					repeatSteps = []
					steps.push({
						type: "WorkoutRepeatStep",
						stepOrder: subStepsCount > 0 ? ((steps.length + 1) + subStepsCount) : steps.length + 1,
						repeatType: "REPEAT_UNTIL_STEPS_CMPLT",
						repeatValue: repeat,
						steps: generateSubSteps(1, (subStepsCount > 0 ? ((steps.length + 2) + subStepsCount) : steps.length + 2), marathonPaces, index, "run")
					})
				}
			}
		});

		return steps;
	}
	const createGarminWorkout = async () => {
		setLoadingValues(true)
		const getDate = weekDays[selectedWeek.key - 1]
		const selectedDate = moment(getDate).add(selectedDay, 'days').format('YYYY-MM-DD')
		const access_token = userGarminDetails?.access_token
		const token_secret = userGarminDetails?.token_secret
		const garmin_id = userGarminDetails?.garmin_id
		const uid = userInfo?.uid
		const mar_id = dayData?.marathon?.id
		let mp = dayData?.marathonPaces ? dayData.marathonPaces : []
		let customDistance;
		let newDayData;

		const recoverType = userInfo?.raceResults?.recoveryPlan?.recoveryType
		const hasCustomDistance = allUserNotes?.find((x: any) => x?.marid == dayData?.marathon?.id)?.distance ? true : false
		const userNoteDistance = allUserNotes?.find((x: any) => x?.marid == dayData?.marathon?.id)?.distance;
		if (!hasCustomDistance && recoverType && recoverType?.max_week_percentage) {
			const multiplier = JSON.parse(recoverType?.max_week_percentage)[dayData?.marathon?.week - 1]
			if (multiplier > 0 && mp.length > 0) {

				customDistance = dayData?.marathon?.distance * multiplier
				newDayData = {
					...dayData,
					marathon: {
						...dayData?.marathon,
						distance: customDistance
					}
				}

				mp = [{ ...mp[0], notes: `${customDistance} miles` }]
			}
		}

		if (userNoteDistance) {
			newDayData = {
				...dayData,
				marathon: {
					...dayData?.marathon,
					distance: userNoteDistance
				}
			}
		}

		const activity = getDailyActivityDisplay(newDayData ? newDayData :
			dayData,
			userInfo,
			mp,
			selectedRace?.value as number,
			dayData?.marathon?.race_day,
			[]
		)?.toUpperCase().split('|')

		const activityValue = activity?.[0].trim() + '' + (activity?.[1] || '')

		const pattern = /^\d+\sminutes$/;

		mp.forEach((x: any, i) => {
			if (pattern.test(x.notes)) {
				!x.notes.toLowerCase().includes('5 minutes') && (x.pace.toLowerCase().includes('easy') ? x.notes = `1 mile` : x.notes = '2 miles')
				x.pace.toLowerCase().includes('marathon') ? mp[i + 1].notes = 'recovery ' + mp[i + 1].notes : undefined
			}
		})
		try {
			const todayActivity: GarminWorkout = {
				workoutName: activityValue,
				description: '',
				workoutProvider: 'RunSmart',
				workoutSourceId: '',
				sport: 'RUNNING',
				steps: generateSteps(mp, userNoteDistance ? userNoteDistance : customDistance)
			}

			const zeroValue = todayActivity.steps && (!todayActivity.steps[0].durationValue || todayActivity.steps[0].durationValue == 0) ? true : false

			if (zeroValue) {
				setFinishedGarminSync(true)
				setHasGarminSyncError(true)
			}
			else {
				setFinishedGarminSync(true)
				setHasGarminSyncError(false)
				await postReq('/v2/garmin/user/workout', {
					access_token, token_secret, schedule: selectedDate, garmin_id, uid, mar_id, workout: todayActivity
				}).then((res) => {
					if (res.data.message === "success" && res.status == "ok")
						setHasGarminSyncError(false)
					else
						setHasGarminSyncError(true)

					setFinishedGarminSync(true)
				})
			}


		} catch (error) {
			setFinishedGarminSync(true)
			setHasGarminSyncError(true)
		}

		setLoadingValues(false)
	}


	const handleCheckIfRecoveryPlan = () => {
		if (raceResults && userInfo) {
			if (userInfo?.uid == raceResults?.maintenance_plan_id)
				return true
			else
				return false
		}
	}

	const removeTZ = (uiData: any) => {
		return uiData ? {
			...uiData,
			start: uiData.start?.slice(0, uiData.start?.indexOf('T'))?.replaceAll('-', '/'),
			race_date: uiData.race_date?.slice(0, uiData?.race_date?.indexOf('T'))?.replaceAll('-', '/'),
			signup_date: uiData.signup_date?.slice(0, uiData?.signup_date?.indexOf('T'))?.replaceAll('-', '/')
		} : ''
	}

	// useEffect(() => {
	// 	console.log(handleCheckIfRecoveryPlan())
	// }, [raceResults, userInfo])

	//	useEffect(() => {
	//		const cont = containerRef.current
	//		const contHeight = cont?.['clientHeight'] ? cont['clientHeight'] : 0
	//		const contPB = cont ? parseFloat(getComputedStyle(cont).paddingBottom) : 0
	//		const wHeight = window?.outerHeight ? window.outerHeight : 0
	//		const hDiff = contHeight - contPB - wHeight
	//		const newPad = hDiff <= 0 && (contHeight !== wHeight && contPB === 0)? 0 : 10
	//		newPad * 8 != contPB && setPadBot(newPad)
	//	})

	const abortController = useRef<any>({ current: '' })
	useEffect(() => {
		abortController?.['current']?.abort?.();
		abortController['current'] = new AbortController();
		if (userInfo && raceResults) {
			moment.tz("America/New_York")
			buildCalendarMonthBase(0, userInfo, abortController['current']).then((res) => {
				let result = res;

				if (userInfo?.raceResults) {
					const rr: RaceResult = userInfo?.raceResults
					const isRecovery = userInfo?.uid == rr?.maintenance_plan_id;
					const recoveryWeeks = rr?.recoveryPlan?.recoveryType?.recovery_length_in_weeks || 0
					result = rr && isRecovery && recoveryWeeks > 0 ? result.filter((x: any) => x?.monthData?.week <= recoveryWeeks) : result
				}

				let dateValCont = {}
				for (const x of result) {
					const dateVal = x?.monthData?.dateVal
					if (dateVal)
						dateValCont = { ...dateValCont, [`${dateVal}`]: x }
				}
				setCalendarData(dateValCont)
			})
		}

		return () => {
			abortController?.['current']?.abort?.();
		}
	}, [userInfo, userOverrides, raceResults])

	useEffect(() => {
		if (dayData && userInfo && selectedRace) {
			handleAdjustPaceByLevel(dayData, userInfo, selectedRace?.value as number, dayData?.marathon?.race_day)
		}
	}, [dayData, userInfo, selectedRace])

	useEffect(() => {
		if (selectedWeek.key && userGarminDetails) {
			handleEnableGarmin(userGarminDetails)
			const getDate = weekDays[selectedWeek.key - 1]
			const selectedDate = moment(getDate).add(selectedDay, 'days').format('YYYY-MM-DD')
			setSelectedDate(selectedDate)
			handelGetDailyActivities(weekDays[selectedWeek.key - 1], moment(weekDays[selectedWeek.key - 1]).add(6, 'days').format('YYYY-MM-DD'))
		}
	}, [selectedWeek.key, userGarminDetails])

	useEffect(() => {
		const abortController = new AbortController()
		if (!loadingValues && marathonDailyValues.length > 0) {
			const current = getCurrentVDOT(
				selectedWeek.key,
				selectedDay as number,
				currentVdot.vdot ? currentVdot.vdot : userInfo?.vdot,
				currentVdot.targetm ? currentVdot.targetm : userInfo?.targetm,
				userVdotAdjustments
			)
			setCurrentVdot(current)
			setRaceDetails({
				...raceDetails,
				totalDistance: getTotalDistance(
					marathonDailyValues,
					allUserNotes
				) as string,
			})

			let mval
			const overrideWeek = userOverrides?.filter(
				(x) => x.week == selectedWeek.key
			)

			if (overrideWeek && overrideWeek?.length > 0) {
				const currentOverride = overrideWeek.find((x) => x.day == selectedDay)
				if (currentOverride?.rest_day !== 'Y') {
					mval = marathonDailyValues?.find(
						(x: any) => x?.marathon?.id == currentOverride?.mid
					)

				} else if (currentOverride?.rest_day === 'Y') mval = undefined
			} else {
				mval = marathonDailyValues?.find(
					(x: any) => x?.marathon?.day == +(selectedDay as number) + 1
				)
			}
			const aDate = weekDays[selectedWeek?.key - 1]
			const sWeek = weekDays[0]?.replaceAll('-', '/')
			const date = aDate ? moment(aDate.replaceAll('-', '/')).add(selectedDay, 'd')?.format('YYYY/MM/DD') : moment(sWeek).add((selectedDay ? selectedDay : 0) - 7, 'd')?.format('YYYY/MM/DD')

			if (mval) {
				for (const x of mval.marathonPaces) {
					const toMod = x.notes.toLowerCase().includes('00m') && x.notes.toLowerCase().includes('repeat') ? true : false
					x.notes = toMod ? x.notes.split(' ')[0] : x.notes
				}
				getOverridePaceData(userInfo?.uid, abortController.signal)
				getUserNotes(mval, abortController.signal)
				getAllUserNotes(abortController.signal)
				setDayData({ ...mval, date } as any)
			} else
				setDayData({ marathonPaces: [], workouts: [], marathon: undefined, date })
		}

		return () => {
			abortController.abort()
		}
	}, [
		loadingValues,
		selectedDay,
		marathonDailyValues,
		userOverrides,
		userVdotAdjustments,
	])

	useEffect(() => {
		if ((selectedDay || selectedDay == 0) && currentWeekActivities?.length > 0) {
			const summaries: Summary[] = []
			setCurrentDayActivities([])

			const getDate = weekDays[selectedWeek.key - 1]?.replaceAll('-', '/')
			const selectedDate = moment(getDate).add(selectedDay, 'days').format('YYYY-MM-DD')

			const activities = currentWeekActivities?.filter(x => x.activity_date === selectedDate)

			if (activities.length > 0) {
				for (const activity of activities) {
					summaries.push(activity.activity_details.summary)
				}
				setCurrentDayActivities(summaries)
			}
			else
				setCurrentDayActivities([])
		}
	}, [selectedDay, currentWeekActivities, userGarminDetails, selectedWeek.key])

	/* END OLD IMPLEMENTATION, TO BE REFACTORED IN PHASE 3*/

	useEffect(() => {
		Object.keys(marathonDailyValues).length > 0 && setLoadingValues(false)
	}, [marathonDailyValues])

	useEffect(() => {
		const abortController = new AbortController()

		if (userInfo && selectedWeek) {
			const aDate = weekDays[selectedWeek?.key - 1]
			const sWeek = weekDays[0]?.replaceAll('-', '/')
			const date = aDate ? moment(aDate.replaceAll('-', '/')).add(selectedDay, 'd')?.format('YYYY/MM/DD') : moment(sWeek).add((selectedDay ? selectedDay : 0) - 7, 'd')?.format('YYYY/MM/DD')
			setDayData({ marathonPaces: [], workouts: [], marathon: undefined, date })
			setMarathonDailyValues([])
			getWeeklyActivities(abortController.signal)
		}
		return () => {
			abortController.abort()
		}
	}, [selectedWeek])

	useEffect(() => {
		const abortController = new AbortController()
		if (userInfo) {
			setLoadingValues(true)
			getGarminDetails()
			let thisDay = Number(date("w"));
			thisDay = userInfo.monday_start ? (thisDay > 0 ? thisDay - 1 : 6) : Number(date("w"));
			let newEndDate = userInfo?.type === 4 ? userInfo?.race_date : moment(userInfo?.race_date).add(1, 'd').format('YYYY/MM/DD')
			newEndDate = userInfo?.type !== 4 && moment().isAfter(moment(userInfo?.race_date).add(1, 'd')) ? moment() : newEndDate
			const weekDays = getWeekDays(new Date(), userInfo.start, newEndDate, userInfo.monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK);
			//			let thisWeek = getCurrentWeek(userInfo.start, userInfo.monday_start) + (weekDays.length > userInfo?.weeks && userInfo?.monday_start ? 0 : 1)
			//			thisWeek = thisDay == 6 && (weekDays.length == userInfo?.weeks) && !userInfo?.monday_start ? thisWeek - 1 : thisWeek

			// setCurrentLocation(userInfo.location)
			setRaceDay(date('w', strtotime(convertDateToUTC(userInfo.race_date))))
			getUserOverrides(abortController.signal)
			getUserVdotChanges(abortController.signal)
			setSelectedRace(
				RaceOptions.find(
					(x) => x.value == (userInfo.type as number)
				) as IRaceType
			)
			setWeekDays(weekDays)
			getAllUserNotes()
			getOverridePaceData(userInfo?.uid, abortController.signal)
			const firstW = weekDays?.[0]?.replaceAll('-', '/')
			const isFuturePlan = !userInfo?.is_active_plan && moment().isSameOrBefore(moment(userInfo.start))
			const isBeforeStart = (userInfo?.start && moment(userInfo.start)?.add(1, 'day')?.isBetween(moment(firstW)?.subtract(7, 'd'), moment(firstW))) || isFuturePlan ? true : false
			const isAfterOrRaceDay = moment(new Date()).isSameOrAfter(moment(userInfo?.race_date))
			thisDay = isBeforeStart && moment().isBefore(moment(userInfo.start)) ? 0 : (moment().isBefore(moment(userInfo.start)) ? 0 : thisDay)
			let activeWeekNumber = isBeforeStart ? 1 : weekDays?.length
			let activeWeek = weekDays.find((wd: string, wdi: number) => {
				const checkWeek = weekDays?.[wdi + 1] ? moment().isBetween(moment(wd.replaceAll('-', '/')).subtract(1, 'd').format('YYYY/MM/DD'), weekDays?.[wdi + 1]?.replaceAll('-', '/')) : false
				activeWeekNumber = checkWeek ? wdi + 1 : activeWeekNumber
				return checkWeek
			})

			activeWeek = isAfterOrRaceDay ? weekDays[weekDays.length - 1] : activeWeek ? activeWeek : isBeforeStart ? weekDays[0] : weekDays[weekDays.length - 1]

			setSelectedDay(isAfterOrRaceDay ? moment(userInfo?.race_date).day() : thisDay)

			const raceDate = moment(userInfo?.race_date)
			if (moment(raceDate) <= moment()) {
				const raceDayIndex = weekDays?.findIndex((w: any) => moment(userInfo?.race_date).isBetween(moment(w.replaceAll('-', '/')).subtract(1, 'days'), moment(w.replaceAll('-', '/')).add(7, 'd')))
				if (raceDayIndex > -1) {
					setSelectedWeek({ week: weekDays[raceDayIndex], key: raceDayIndex + 1 })
					setCurrentWeek({ week: weekDays[raceDayIndex], key: raceDayIndex + 1 })
				} else {
					setSelectedWeek({ week: weekDays[userInfo?.weeks - 1], key: userInfo?.weeks })
					setCurrentWeek({ week: weekDays[userInfo?.weeks - 1], key: userInfo?.weeks })
				}
				setSelectedDay(moment(userInfo?.race_date).day())
			}
			else {
				setSelectedWeek({
					...selectedWeek,
					key: activeWeekNumber,
					week: activeWeek,
				})

				setCurrentWeek({
					...currentWeek,
					key: activeWeekNumber,
					week: activeWeek,
				})
			}

			getRaceResult(abortController.signal)
			getUserRestComments(abortController.signal)
		}

		return () => {
			abortController.abort()
		}
	}, [userInfo?.start])

	useEffect(() => {
		if (userInfo && userInfo.type === 4 && raceResults && raceResults.id) {
			setTrainingTitle('Recovery Plan')
		}
		else if (userInfo && userInfo.type === 4 && !raceResults) {
			setTrainingTitle('Maintenance Plan')
		}
		else if (userInfo && userInfo.type !== 4) {
			setTrainingTitle('Training Plan')
		}
	}, [userInfo, raceResults])

	useEffect(() => {
		const abortController = new AbortController()
		if (planId && currentUser?.account_id) {
			GetUserInfo(currentUser?.account_id as number, abortController.signal).then(
				(res) => {
					setReload(false)
					const d = res?.data?.result.find((x: any) => x.uid == planId)
					setUserInfo(removeTZ(d))
				}
			)
		}
		else
			// if (hasLoaded && currentUser && !currentUser?.vdot_id) {
			// 	navigate('/training-plan/build/step-1', { replace: true })
			// } else

			if (hasLoaded && currentUser && !userInfo) {
				GetUserInfo(currentUser?.account_id as number, abortController.signal).then(
					(res) => {
						let active: any;
						const result = res?.data?.result;
						const info = res?.data?.result?.filter((x: any) => x.status == 1 && x.is_active_plan == true)
						setReload(false)
						if (info && info.length > 0)
							active = info[info?.length - 1]

						if (!active && result && result.length == 1) {
							active = result[0]
						}
						else if (!active) {
							navigate('/training-plan-journey/view', { replace: true })
						}

						setUserInfo(removeTZ(active))
					}
				)
			} else if (reload && currentUser?.account_id) {
				GetUserInfo(currentUser?.account_id as number, abortController.signal).then(
					(res) => {
						let active: any;
						const result = res?.data?.result;
						const info = res?.data?.result?.filter((x: any) => x.status == 1 && x.is_active_plan == true)
						setReload(false)
						if (info && info.length > 0)
							active = info[info?.length - 1]

						if (!active && result && result.length == 1) {
							active = result[0]
						}
						else if (!active) {
							navigate('/training-plan-journey/view', { replace: true })
						}

						setUserInfo(removeTZ(active))
					}
				)
			}
		return () => {
			abortController.abort()
		}
	}, [currentUser, hasLoaded, reload, planId])

	useEffect(() => {
		!currentVideoId ? setIsPlaying(false) : undefined
	}, [currentVideoId])

	useEffect(() => {
		if (raceResults && raceResults?.id && userInfo && weekDays.length > 0) {
			const isMaintenance = handleCheckIfRecoveryPlan()
			if (isMaintenance) {
				const recoverType = raceResults?.recoveryPlan?.recoveryType;
				const weekTruncateCount = userInfo?.weeks - (recoverType?.recovery_length_in_weeks);
				const recoveryLength = recoverType?.recovery_length_in_weeks ? recoverType.recovery_length_in_weeks + 1 : 0
				const newWD = weekDays.slice(0, recoveryLength)
				setWeekDays(newWD)
				const td = moment(userInfo?.race_date).subtract(weekTruncateCount, 'weeks')
				const lWeek = newWD?.length && newWD[newWD.length - 1].replace('-', '/')
				const newED = lWeek && moment(td).isBefore(moment(lWeek)) ? lWeek : td
				userInfo.race_date = userInfo?.updated ? userInfo.race_date : moment(newED).toISOString()
				userInfo.raceResults = raceResults
				userInfo['updated'] = true
				const weeks = raceResults?.recoveryPlan?.recoveryType?.recovery_length_in_weeks
				const raceDate = moment(userInfo?.start).add(weeks, 'weeks')
				if (moment(raceDate) <= moment()) {
					setSelectedWeek({ week: weekDays[weeks], key: weeks + 1 })
					setCurrentWeek({ week: weekDays[weeks], key: weeks + 1 })
					setSelectedDay(0)
				}
			}
		}
	}, [raceResults])

	useEffect(() => {
		if (currentWorkoutVideo) getIsFavorited(currentWorkoutVideo.id as unknown as number).then(res => setIsFavorite(res))
	}, [currentWorkoutVideo, favorites])

	useEffect(() => {
		if (!showProgramView)
			setCurrentVideoId(undefined)
	}, [showProgramView])

	useEffect(() => {
		if (requestKey) {
			const dataToSend = {
				requestType: 'cast-show-bottom-menu',
				payload: false,
				timestamp: new Date().getTime(),
			}

			sendDataToReactNative(dataToSend);
		}


		return () => {

			const dataToSend = {
				requestType: 'cast-show-bottom-menu',
				payload: true,
				timestamp: new Date().getTime(),
			}

			sendDataToReactNative(dataToSend);

			setUserOverrides([])
			setMarathonDailyValues([])
		}
	}, [])

	useEffect(() => {
		const userAccountData = accountProfile?.data?.result[0];
		if (userAccountData?.user_id && userInfo?.uid) {
			if (userAccountData?.measurement_system) {
				userInfo.km = userAccountData?.measurement_system === "imperial" ? "N" : "Y"
			}
			setCurrentLocation(userAccountData?.location)
		}
	}, [accountProfile, userInfo])

	const handleCloseCalendar = () => {
		const weekHeight = isMobile ? 75 : 90
		document.body.style.overflowY = 'auto'
		document.body.style.position = 'static'
		const calendarCont = document.getElementById('renderWeekCalendar-cont')
		const backdropCont = document.getElementById('calendar-backdrop') as HTMLElement
		const backdropContChild = document.getElementById('calendar-backdrop-child') as HTMLElement

		setCalendarOpen(false)
		setTimeout(() => {
			if (calendarCont?.style) {
				calendarCont.style.height = `${weekHeight}px`
				calendarCont.style.overflowY = 'hidden'
			}

			if (backdropCont?.style)
				backdropCont.style.height = '0'
			if (backdropContChild?.style)
				backdropContChild.style.opacity = '0'
		}, 250)
	}

	const handleBack = () => {
		handleCloseCalendar()
		navigate(`${profile && profile === "1" ? '/profile' : '/'}`, { replace: true })
	}

	const handleCalendarClick = () => {
		handleCloseCalendar()
		setShowViewCalendar(true)
	}

	const handleReorderClick = () => {
		handleCloseCalendar()
		setEditWeek(true)
	}

	const handleOptionClick = () => {
		handleCloseCalendar()
		setShowOptions(true)
	}
	const renderHeader = () => {
		const marathonType = userInfo?.type === 4 && userInfo?.raceResults ? 7 : userInfo?.type
		const eventName = raceSelection?.find((rs: any) => rs?.raceValue == marathonType)?.event_name || ''
		return (
			<Box sx={{ px: '10px', pt: isMobile ? 1 : 2, pb: '5px', width: '100%', height: '45px' }}>
				<Grid container>
					<Box sx={{ display: 'flex', width: isMobile ? '100px' : '200px', justifyContent: 'flex-start', alignItems: 'center' }}>
						<Grid container className='pointer' onClick={handleBack} sx={{ width: 'auto' }} justifyContent='flex-start' alignItems='center'>
							<ArrowBack />
						</Grid>
					</Box>
					<Box sx={{ width: `calc(100% - ${isMobile ? 200 : 400}px)`, display: 'flex', alignItems: 'center' }}>
						{eventName && <H1 size={2} className='ellipsis-1' sx={{ width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'center' }}>{eventName}</H1>}
					</Box>
					<Box sx={{ display: 'flex', width: isMobile ? '100px' : '200px', justifyContent: 'flex-end', alignItems: 'center' }}>
						{!isMobile && <Grid container xs={4} justifyContent="flex-end">
							<CalendarMonthIcon className='pointer' sx={{ fontSize: "2em", stroke: 'white', strokeWidth: '0.6px' }} onClick={handleCalendarClick} />
						</Grid >}
						<Grid container xs={4} justifyContent="flex-end" >
							<Box className='pointer' onClick={handleReorderClick} sx={{ width: 'fit-content', overflow: 'hidden' }}>
								<svg x="0px" y="0px" className="reorder" viewBox="0 0 112.4 124.2">
									<path className="st0" d="M50.3,60.2h-22c-1.6,0-2.9-1.3-2.9-2.9v-22c0-1.6,1.3-2.9,2.9-2.9h22c1.6,0,2.9,1.3,2.9,2.9v22 C53.2,58.9,51.9,60.2,50.3,60.2z" />
									<path className="st0" d="M80.7,91h-22c-1.6,0-2.9-1.3-2.9-2.9v-22c0-1.6,1.3-2.9,2.9-2.9h22c1.6,0,2.9,1.3,2.9,2.9v22 C83.6,89.7,82.3,91,80.7,91z" />
									<g><path className="st1" d="M61.1,37.3h11c6.3,0,11.4,5.1,11.4,11.4v5" /><polyline className="st2" points="66.4,32.7 61,37.3 66.4,42 	" /></g>
									<g><path className="st1" d="M47.9,86.3h-11c-6.3,0-11.4-5.1-11.4-11.4v-5" /><polyline className="st2" points="42.6,91 48,86.3 42.7,81.7 	" /></g>
								</svg>
							</Box>
						</Grid >
						<Grid container xs={4} justifyContent="flex-end">
							<SettingsIcon className='pointer' sx={{ fontSize: "2em" }} onClick={handleOptionClick} />
						</Grid >
					</Box >
				</Grid>
			</Box>)
	}

	const renderCalendar = () => {
		return <TrainingCalendarModal
			showViewCalendar={showViewCalendar}
			handleCloseViewCalendar={setShowViewCalendar}
			handleClose={setShowViewCalendar}
			userInfo={userInfo}
			getDailyActivityDisplay={getDailyActivityDisplay}
			selectedRace={selectedRace}
			currentWeek={currentWeek}
			allUserNotes={allUserNotes}
			selectedWeek={selectedWeek}
			setSelectedWeek={setSelectedWeek}
			selectedDay={selectedDay}
			setSelectedDay={setSelectedDay}
			getColorCode={getColorCode}
			loading={loadingValues || calendarLoading}
			setCalendarLoading={setCalendarLoading}
			weekDays={weekDays}
			raceResults={raceResults}
			handleCheckIfRecoveryPlan={handleCheckIfRecoveryPlan}
		/>
	}

	const renderOptions = () => {
		return <Options
			showOptions={showOptions}
			setShowOptions={setShowOptions}
			userInfo={userInfo}
			selectedRace={selectedRace}
			getDailyActivityDisplay={getDailyActivityDisplay}
			setReload={setReload}
			getUserVdotChanges={getUserVdotChanges}
			selectedWeek={selectedWeek}
			selectedDay={selectedDay}
			changeLoc={changeLoc}
			setChangeLoc={setChangeLoc}
			raceResults={raceResults}
			accountProfile={accountProfile?.data.result?.[0] || undefined}
			mutate={mutate}
			accountLoading={accountLoading}
		/>
	}

	const renderWeeks = () => {
		return (
			<WeekSelector
				calendarData={calendarData}
				selectedWeek={selectedWeek}
				setSelectedWeek={setSelectedWeek}
				weekDays={weekDays}
				marathonDailyValues={daySelectorValues}
				userInfo={userInfo}
				getDailyActivityDisplay={getDailyActivityDisplay}
				selectedRace={selectedRace}
				selectedDay={selectedDay}
				setSelectedDay={setSelectedDay}
				loadingValues={loadingValues || calendarBaseLoading || calendarLoading}
				getUserOverrides={getUserOverrides}
				getUserRestComments={getUserRestComments}
				dayData={dayData}
				allUserNotes={allUserNotes}
				editWeek={editWeek}
				setEditWeek={setEditWeek}
				getColorCode={getColorCode}
				setIsForward={setIsForward}
				weekLoading={weekLoading}
				raceResults={raceResults}
				calendarOpen={calendarOpen}
				setCalendarOpen={setCalendarOpen}
			/>
		)
	}

	const renderDailyActivity = () => {
		return (
			<DailyActivity
				userInfo={userInfo}
				selectedDay={selectedDay}
				userNotes={userNotes}
				dayData={dayData}
				getDistanceValue={getDistanceValue}
				selectedRace={selectedRace}
				allUserNotes={allUserNotes}
				loadingValues={loadingValues}
				getColorCode={getColorCode}
				getWorkoutVideoDetails={getWorkoutVideoDetails}
				handleOpenVideo={handleOpenVideo}
				distanceInterpret={distanceInterpret}
				convertPaceBase={convertPaceBase}
				getConvertedPace={getConvertedPace}
				userCustomPaces={userCustomPaces}
				getOverridePaceData={getOverridePaceData}
				getAllUserNotes={getAllUserNotes}
				getUserNotes={getUserNotes}
				setLoadingValues={setLoadingValues}
				getDailyActivityDisplay={getDailyActivityDisplay}
				currentDayActivities={currentDayActivities}
				userGarminDetails={userGarminDetails}
				weekDays={weekDays}
				createGarminWorkout={createGarminWorkout}
				groupPaces={groupPaces}
				selectedWeek={selectedWeek}
				isForward={isForward}
				setChangeLoc={setChangeLoc}
				finishedGarminSync={finishedGarminSync}
				hasGarminSyncError={hasGarminSyncError}
				setFinishedGarminSync={setFinishedGarminSync}
				getRaceResult={getRaceResult}
				raceResults={raceResults}
				accountInfo={accountInfo}
				setSelectedDay={setSelectedDay}
				setSelectedWeek={setSelectedWeek}
			/>)
	}

	const renderVideoPlayer = () => {
		return (
			<RSDrawer
				bottom
				noClose
				open={showProgramView}
				onClose={() => {
					setIsPlaying(false)
					setShowProgramView(false)
				}}
			>
				<Box sx={{ p: isMobileHook ? 0 : 2 }}>
					<ProgramMobileView
						onLike={onLike}
						programType={programType}
						title={videoTitle}
						currentVideo={currentWorkoutVideo}
						closeView={() => {
							setIsPlaying(false)
							setShowProgramView(false)
						}}
						handleMarkAsFavorite={handleMarkAsFavorite}
						handleOnPlay={handleOnPlay}
						isPlaying={isPlaying}
						isFavorite={isFavorite}
						isLoadingMark={isLoadingMark}
						currentWorkoutVideoProgressInfo={currentWorkoutVideoProgressInfo}
						isFromTP={true}
					/>
				</Box>
			</RSDrawer>
		)
	}

	const renderRecoveryVideoPlayer = () => {
		return (
			<RSDrawer
				bottom
				noClose
				open={showAssessmentVideo}
				onClose={() => {
					setIsPlaying(false)
					setShowAssessmentVideo(false)
				}}
			>
				<Box sx={{ p: isMobileHook ? 0 : 2 }}>
					<ProgramMobileView
						onLike={onLike}
						programType={'Assessment'}
						title={videoTitle}
						currentVideo={currentRecoveryVideo as Video}
						closeView={() => {
							setIsPlaying(false)
							setShowAssessmentVideo(false)
						}}
						handleMarkAsFavorite={handleMarkAsFavorite}
						handleOnPlay={handleOnPlay}
						isPlaying={isPlaying}
						isFavorite={isFavorite}
						isLoadingMark={isLoadingMark}
						currentWorkoutVideoProgressInfo={currentWorkoutVideoProgressInfo}
						isFromTP={true}
					/>
				</Box>
			</RSDrawer>
		)
	}

	const renderExercisePlayer = () => {
		return (
			<RSDrawer
				bottom
				noClose
				open={showExerciseVideo}
				onClose={() => setShowExerciseVideo(false)}
			>
				<Box sx={{ p: isMobileHook ? 0 : 2 }}>
					<ExercisePage closePlayer={() => {
						setShowExerciseVideo(false)
						setIsPlaying(false)
						setCurrentVideoId(undefined)
					}} isFromTP={true} userInfo={accountInfo} videoIdProps={String(currentVideoId)} />
				</Box>
			</RSDrawer>
		)
	}

	const swipeHeight = isMobileHook ? 170 : 180

	const handleBackDropClick = (e: any) => {
		e.stopPropagation()
		setCalendarOpen(false)
	}

	return (
		<>
			<RSNavCont plain hideHeader={isMobileHook} hideFooter={isMobileHook} loaderProps={{ active: loadingValues || (!isMobileHook && calendarLoading), isInside: showOptions ? false : true }}>
				<GenericModal
					type='update-app'
					show={showUpdateModal}
					title="Update RunSmart"
					text={'A new version of the app is now available for download.'}
					setShow={setShowUpdateModal}
					keyboard={false}
					backdrop="static"
					button1Action={handleUpdateApp}
					button1Text={'Update'}
				/>
				<Box ref={containerRef} className='training-plan-cont' sx={{ margin: 'auto', border: 'none', boxShadow: 'none', overflow: 'hidden', width: '100%', height: '100%' }}>
					{renderVideoPlayer()}
					{renderExercisePlayer()}
					{renderRecoveryVideoPlayer()}
					<Box sx={{ visibility: (showProgramView || showExerciseVideo) ? 'hidden' : 'visible', position: 'relative' }}>
						{renderCalendar()}
						{renderOptions()}
						<RSHeaderCont secondary={!isMobileHook} noShadow sx={{ height: `${swipeHeight}px` }} barSX={{ px: 0, zIndex: '1100 !important' }} contSX={{ alignItems: 'flex-start' }}>
							<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
								{renderHeader()}
								{renderWeeks()}
							</Box>
						</RSHeaderCont>
						<Backdrop id='calendar-backdrop' open={true} sx={{ zIndex: 998, height: 0, backgroundColor: 'transparent !important' }}>
							<Box onClick={handleBackDropClick} id='calendar-backdrop-child' sx={{ height: '100%', width: '100%', backgroundColor: 'black' }} />
						</Backdrop>
						<Box sx={{ position: 'relative' }}>
							{renderDailyActivity()}
						</Box>
					</Box>
				</Box>
				<VideoPlayer />
			</RSNavCont>
		</>)
}

export default WithAuth(YourTrainingPlanClone)
