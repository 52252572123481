/* eslint-disable @typescript-eslint/no-unused-vars */

import WithAuth from '../../../components/WithAuth'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import MainCard from '../../../components/cards/MainCard'
import GenericAlert from '../../../components/misc/GenericAlert'
import {RSButton1} from "../../../components/buttons"
import RSNavCont from '../../../components/layout/RSNavCont'
import useAlert from '../../../hooks/useAlert'
import { CANCEL_STORAGE_KEY } from '../../../modules/values'
import useLocalStorage from '../../../hooks/useLocalStorage'
import {
	getSubscriptionInfo,
	cancelSubscription,
	getAllAccountInfo,
} from '../../../modules/accountActions'
import { navReq, postReq } from '../../../modules/apiConsume'
import { FC, useState, useEffect, useContext } from 'react'
import { Spinner } from 'react-bootstrap'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
// import useWebSocket from 'react-use-websocket'
// import { WS_URL } from '../../../modules/cliEnvValues'
import useStoreWSMessages from '../../../hooks/useStoreWSMessages'
import { WebSocketContext } from '../../../WebsocketProvider'
import useRNBridge from '../../../hooks/useRNBridge'

/**
 * Step4 Component
 */

const Step4: FC = () => {
	// State
	const [planId, setPlanId] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	// Hooks
	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
		setAlertVariant,
	} = useAlert()
	const { storedValue } = useLocalStorage({ key: CANCEL_STORAGE_KEY })

	const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
	const { sendDataToReactNative } = useRNBridge()

	const { mobileType } = useDeviceDetect()

	// On Submit
	const onSubmit = async () => {
		setIsLoading(true)
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		const responses = await getAllAccountInfo({ account: false })
		const billingResponse = responses.billing
		const planCode = responses.subscription.data.active.plan_code.replace('-', '_')
		const purchaseToken = responses.subscription.data.active.purchase_token
		const billingDetails = billingResponse?.data

		if (localStorageRequestKey && billingDetails && billingDetails.paymentType === "in_app_purchase") {
			if (mobileType === "Android") {
				await postReq('/v2/app-purchase/cancel', { purchaseToken: purchaseToken, productId: planCode }).then(() => {
					setAlertVariant('success')
					setAlertMessage('Subscription cancelled')
					onAlertOpen()
					setTimeout(() => {
						setIsLoading(false)
						navReq('/#/cancel/step-5')
					}, 2000)
				}).catch(() => {
					setIsLoading(false)
					setAlertVariant('danger')
					setAlertMessage('Could not cancel subscription')
					onAlertOpen()
				})
			}
			else if (mobileType === "Ios") {
				setIsLoading(false)
				sendDataToReactNative({
					requestType: 'webapp-open-apple-subscriptions',
					requestKey: localStorageRequestKey,
					payload: undefined,
					timestamp: new Date().getTime()
				})

				navReq('/#/')
			}
		}

		else {
			if (planId) {
				const result = await cancelSubscription(planId, storedValue)
				if (result?.status == 'ok') {
					setAlertVariant('success')
					setAlertMessage('Subscription cancelled')
					onAlertOpen()
					setIsLoading(false)
					setTimeout(() => {
						setIsLoading(false)
						navReq('/#/cancel/step-5')
					}, 2000)
				} else {
					setIsLoading(false)
					setAlertVariant('danger')
					setAlertMessage('Could not cancel subscription')
					onAlertOpen()
				}
			}
		}
	}

	// Get Plan ID
	const getPlanId = async () => {
		const { isActive, subscription } = await getSubscriptionInfo()
		if (isActive && subscription?.id) setPlanId(subscription.id)
	}

	// Mount Effect
	useEffect(() => {
		getPlanId()
	}, [])

	// Render
	return (
		<RSNavCont contSX={{ px: 1 }}>
			<GenericAlert
				show={showAlert}
				title={alertTitle}
				message={alertMessage}
				variant={alertVariant}
				onClose={onAlertClose}
			/>

			{/* Card */}
			<Row className="mb-5 d-flex justify-content-center cancel-plan-card account-container-small mt-4">
				<Col xs={12}>
					{/* <MainCard cardType="split"> */}
					{/* Title */}
					<Row className="text-center mt-4">
						<Col>
							<h3 className='cancel--title'>Final Step</h3>
							<h4 className='cancel--title'>Your subscription is not cancelled yet!</h4>
						</Col>
					</Row>
					<Row className="text-center mb-3 small-text">
						<Col>
							Use the button below to complete your cancellation
						</Col>
					</Row>
					<Row className="text-center mt-4 mb-4">
						<Col>
							<RSButton1
								sx={{ width: 'auto', textTransform: 'uppercase !important' }}
								onClick={onSubmit}
								disabled={planId || !isLoading ? false : true}
							>
								{isLoading ? (<Spinner size='sm' animation={'border'} />) : <div className='mobile-small'>
									{localStorageRequestKey && mobileType === "Ios" ? 'Go Here To Cancel' : 'Complete Cancellation'}
								</div>
								}
							</RSButton1>
						</Col>
					</Row>
					{/* </MainCard> */}
				</Col>
			</Row>
		</RSNavCont>
	)
}

export default WithAuth(Step4)
