import { FC } from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#9FCD2B',
			light: '#fff',
			dark: '#fff',
			contrastText: '#010101',
		},
	},
});

export type RSButtonProps = {
	danger?: boolean,
	tertiary?: boolean,
	secondary?: boolean,
	disabled?: boolean,
	children?: any,
	sx?: object,
	id?: string,
	className?: string,
	onClick?: Function
	customized?: boolean,
	card?: boolean,
	dark?: boolean
	icon?: any,
	checked?: boolean,
	justText?: boolean,
	style2?: boolean
	tooltip?: string,
	addBorder?: boolean
}


const RSButton: FC<RSButtonProps> = ({ secondary, children, sx = {}, className, onClick, disabled, customized, card, dark, icon, checked, id = '', tertiary, justText, style2, tooltip, danger, addBorder }) => {

	const defaultClass = card && secondary ? 'default-bs' : ''

	const style = {
		width: '100%',
		height: card || tertiary ? 'fit-content' : justText ? '10px' : '50px',
		p: card && !style2 ? '10px 0' : '0',
		color: `${danger && !style2 ? '#FFFFFF' : disabled && !style2 ? '#555555' : (dark ? '#FFFFFF' : (tertiary ? '#9fcd2b' : '#010101'))} !important`,
		border: (card && !style2 && !addBorder) || dark || tertiary ? '0' : (justText || danger ? 'none' : `1px solid ${disabled ? 'transparent' : (card && style2 && secondary ? '#FF9A00' : (style2 ? '#4a4a4a' : '#9fcd2b'))} !important`),
		backgroundColor: `${danger && !disabled && !style2 ? '#D63F3F' : (disabled || (danger && disabled)) ? '#cbcbcb' : (justText ? 'none' : (card && style2 && secondary ? '#FCC26A' : (tertiary ? 'none' : (secondary || card ? '#ffffff' : dark ? '#303030' : '#9fcd2b'))))} !important`,
		textTransform: 'none',
		borderRadius: dark ? '7px' : '5px !important',
		boxShadow: card && !style2 ? (secondary || dark ? 'none' : '4px -4px 0px #9fcd2b, -4px -4px 0px #9fcd2b, 4px 4px 0px #9fcd2b, -4px 4px 0px #9fcd2b') : 'none',
		fontFamily: justText ? `Poppins-Light${customized || card ? '' : ' !important'}` : `Poppins-Medium${customized || card ? '' : ' !important'}`,
		fontSize: justText ? '14px' : '16px',
		lineHeight: '1em',
		letterSpacing: '0',
		paddingRight: justText ? '20px' : '0',
		'& *': {
			fontFamily: justText ? `Poppins-Light${customized || card ? '' : ' !important'}` : `Poppins-Medium${customized || card ? '' : ' !important'}`,
			letterSpacing: '0',
			fontSize: justText ? '14px' : '16px',
		},
		...sx
	}

	const handleClick = (e: any) => {
		onClick?.(e)
	}
	const renderChecked = () => {
		return checked ? <Box sx={{ zIndex: 1, height: '100%', width: '100%', alignItems: 'start', display: 'flex', justifyContent: 'flex-end', position: 'absolute' }}>
			<Box sx={{ height: '30px', width: '30px', backgroundColor: '#9fcd2b', borderRadius: '0 0 0 15px' }}>
				<i className="fa-sharp fa-solid fa-circle-check" aria-hidden="true" style={{ fontSize: '20px', padding: '4px 0 0 4px', color: '#ffffff' }}></i>
			</Box>
		</Box> : <></>
	}

	return <>
		<ThemeProvider theme={theme}>
			<Button
				title={tooltip}
				className={`${defaultClass} ${className ? className : ''}`}
				onClick={handleClick}
				sx={style}
				disabled={disabled ? disabled : false}
				id={id}
			>
				{renderChecked()}{icon && icon}{children}
			</Button>
		</ThemeProvider>
	</>
}

export default RSButton;
