import { FC } from 'react'

import Grid from '@mui/material/Grid';
import ArrowForward from '@mui/icons-material/ArrowForwardIos'

import { RSButton2 } from './index';
import { SH3, P2 } from '../text/RSTypography'

export type RSChurrosButtonProps = {
    symbol?: string | undefined,
    title: string,
    subtitle?: any,
    distance?: any,
    children?: any
    onClick?: Function,
    disabled?: boolean,
    className?: string,
    sx?: object,
    symbolSx?: object,
    dipColor?: string,
    symbolElement?: any,
    isLoading?: boolean,
    arrow?: boolean,
    isActive?: boolean
}


const RSChurrosButton: FC<RSChurrosButtonProps> = ({ symbol, children, title, subtitle, distance, onClick, className = '', dipColor, sx = {}, symbolSx={}, disabled, symbolElement, isLoading, arrow, isActive = false }) => {


    const handleClick = (e: any) => {
        onClick?.(e)
    }

    return <RSButton2 borderless secondary={!isActive} disabled={disabled} className={`${className} ${isLoading ? 'loading-days toRight' : ''}`} sx={{ maxWidth: '450px', height: '80px', p: 0, overflow: 'hidden', justifyContent: 'center', alignItems: 'center', ...sx }}
        onClick={handleClick}>

        <Grid className='card-slanted' xs={3.5} sx={{ display: 'flex', height: '100%', background: disabled ? 'rgba(85,85,85,0.6)' : (dipColor || "black"), justifyContent: 'center', alignItems: 'center' }} >
            {symbolElement ? symbolElement :
            	(symbol && (symbol.includes('fa') ? <i className={symbol} style={{ color: "white", fontSize: "35px", marginLeft: "-14px", ...symbolSx }} /> : <img src={symbol} style={{ scale: '1.1', marginRight: '14px', ...symbolSx }} />))
						}
        </Grid>

        <Grid container xs={8.5} sx={{ flexDirection: 'column', pl: '12px', pr: arrow ? '40px' : '12px'}}>
            <SH3 title>{title}</SH3>
            <P2 subtitle>{subtitle}</P2>
					 {distance && <P2 sx={{ width: '100%', textAlign: 'left'}}>{distance}</P2>}
            {children}

        </Grid>
        {arrow && <ArrowForward fontSize="medium" sx={{ position: 'absolute', right: 0, stroke: 'white', strokeWidth: '1.3px', mr: '13px' }} />}
    </RSButton2>
}

export default RSChurrosButton;
