import { FC } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import RSInput from './RSInput';

export type RSInputAdornment2Props = {
	value: string,
	setValue: any,
	label: string,
	min?: number,
	max?: number,
	wholeNumber?: boolean,
	column?: boolean,
	disabled?: boolean,
	placeholder?: string,
	sx?: object,
	labelSX?: object
}



const RSInputAdornment2: FC<RSInputAdornment2Props> = ({ value, setValue, label, disabled = false, wholeNumber = false, min, max, column, placeholder = '', sx = {}, labelSX = {} }) => {
	return <Grid container sx={{ flexDirection: column ? 'column' : 'row' }} alignItems={column ? 'center' : 'flex-end'}>
		<RSInput className='gray-input-border' sx={{ height: '40px', textAlign: column ? 'center' : 'left', margin: 0, ...sx }} min={min} max={max} wholeNumber={wholeNumber} value={value} setValue={setValue} disabled={disabled} placeholder={placeholder} />
		<Box sx={{ pl: column ? 0 : '5px', fontFamily: 'Poppins-Medium', ...labelSX }}>{label}</Box>
	</Grid>
}

export default RSInputAdornment2;
