import { getReq, postReq } from "./apiConsume";

export const GenerateRefLink = async (userId: number) =>
    await getReq(`/v2/share/generate-referral-link?user_id=${userId}`)

export const GetShareTransactions = async () =>
    await getReq(`/v2/share/transactions`)

export const GetShareTransactionsByUserId = async (userId: number) =>
    await getReq(`/v2/share/transactions/user/${userId}`)

export const CreateShareTransaction = async (user_id: number, referral_code: string, trial_subscription_id: string, type: string, points: number) =>
    await postReq(`/v2/share/transaction`, { user_id, referral_code, trial_subscription_id, type, points })

export const IncrementReferralLinkHits = async (referralCode: string) =>
    await postReq(`/v2/share/increment-referral-link-hits`, { referral_code: referralCode })

export const GetShareDestination = async () =>
    await getReq(`/v2/share/current-destination-link`)


export const ChangeShareDestination = async (new_destination: string) =>
    await postReq(`/v2/share/update-all-destination-link`, { new_destination })


export const GetTransactionLogsByTransactionId = async (txId: number) =>
    await getReq(`/v2/share/transaction/${txId}`)

export const GetTransactionsAllLogs = async () =>
    await getReq(`/v2/share/transactions/logs`)

export const RedeemCredits = async (user_id: number) =>
    await postReq(`/v2/share/redeem`, { user_id })

export const GetAllReferralLinks = async (limit: number, page: number, sort_by: string, user_id?: number) =>
    await getReq(`/v2/share/referral-links?limit=${limit}&page=${page}&sort_by=${sort_by}${user_id ? `&user_id=${user_id}` : ''}`)

export const GetShareTransactionsByUser = async (userId: number) =>
    await getReq(`/v2/share/all-transactions/user/${userId}`)