import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RSTheme from '../styles/RSTheme'
import RSPopUpModal from '../layout/RSPopUpModal'
import { RSButton1 } from '../buttons'
import { H1, P1 } from '../text/RSTypography'
import useRSTabs from '../../hooks/useRSTabs'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import { SHARE_REWARD_AMOUNT } from '../../modules/cliEnvValues';

export type RSRSAnnouncementProps = {
	showAnnouncementUpdates: boolean,
	setShowAnnouncementUpdates: any
}


const RSAnnouncement: FC<RSRSAnnouncementProps> = ({ showAnnouncementUpdates, setShowAnnouncementUpdates }) => {
	const announcementNum = 1
	const isMobile = useIsMobileScreen()
	const navigate = useNavigate()
	const image = <img src="./img-new/invite/runsmart_share.jpg" />

	const announcementNumStored = localStorage.getItem('announcementNum')
	const announcementNumStoredInt = announcementNumStored ? Number(announcementNumStored) : 0

	const handleClose = () => {
		localStorage.setItem('announcementNum', announcementNum.toString())
		setShowAnnouncementUpdates(false)
	}

	const handleClick = () => {
		handleClose()
		navigate('/profile', { replace: true })
	}

	const data = [{
		title: 'Share RunSmart, Earn Store Cred.',
		description: `Share your link via text, email, or post on social. Every friend who joins, you earn $${SHARE_REWARD_AMOUNT} to the RunSmart store.`,
		buttons: [<><RSButton1 onClick={handleClick} >
			Take me there
		</RSButton1></>, <><RSButton1 onClick={() => handleChangeActive(tab + 1)} tertiary sx={{ height: '50px' }}>
			Skip
		</RSButton1></>]
	}, {
		title: 'Sharing Down the Road',
		description: 'Head to your profile to learn more about inviting other runners and earning credit to the RunSmart shop.',
		buttons: [<><RSButton1 onClick={handleClose} >
			Got it
		</RSButton1></>]
	}]


	const dataBody = (d: any) => {
		return <>
			<Grid container xs={12} sx={{ height: '170px' }}>
				<Grid container xs={12} sx={{ height: '60px', mb: '10px' }} justifyContent='center' alignItems='center'>
					<H1 size={5} sx={{ textAlign: 'center' }}>{d.title}</H1>
				</Grid>
				<Grid container xs={12} sx={{ height: '100px', pt: 1, px: 3 }} justifyContent='center'>
					<P1 size={1} sx={{ textAlign: 'center' }}>{d.description}</P1>
				</Grid>
			</Grid>
			{d?.buttons && <Grid container xs={12} sx={{ height: '116px', flexDirection: 'column' }} justifyContent='flex-start' alignItems='center'>
				{d.buttons?.map((b: any, bi: number) => <Box key={`b-${bi}`} sx={{ mb: 1, width: '200px' }} >
					{b}
				</Box>)}
			</Grid>}
		</>
	}


	const stepData = [{
		body: dataBody(data[0]),
	}, {
		body: dataBody(data[1])
	}]

	const { tabsUI, handleChangeActive, tab } = useRSTabs({ data: stepData, animateDesktop: true, sx: { height: 'auto' } })

	return <RSTheme>
		<RSPopUpModal popUpHeight='100%' popUpWidth='100%' open={showAnnouncementUpdates && (announcementNum > announcementNumStoredInt)} onClose={handleClose} sx={{ maxWidth: '100%' }} bodySx={{ backgroundColor: isMobile ? '#f6f6f6' : 'transparent' }}>
			<Grid container xs={12} sx={{ height: '100%' }} justifyContent='center' alignItems='center'>
				<Box className='announcement-body' sx={{ height: 'auto', width: '100%', maxWidth: '500px', p: 3, backgroundColor: '#f6f6f6', overflow: 'hidden' }}>
					<Grid container xs={12} sx={{ flexDirection: 'column', pb: '50px' }} alignItems='center' justifyContent='center'>
						<Box sx={{ height: '250px', width: '250px' }}>
							{image}
						</Box>
						<Grid container sx={{ height: '35px', width: '100px', backgroundColor: '#cd2b6e', borderRadius: '50px', mt: '-10px' }} justifyContent='center' alignItems='center'>
							<Box sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#fff' }}>
								NEW
							</Box>
						</Grid>
					</Grid>
					{tabsUI}
				</Box>
			</Grid>
		</RSPopUpModal>
	</RSTheme>
}

export default RSAnnouncement;
