
import { FC, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import RSDrawer from '../../components/layout/RSDrawer'
import { RSButton1 , RSButton2 } from '../../components/buttons'
import { SH1 } from '../../components/text/RSTypography'

import RSIcon from '../../components/icons/RSIcon'

export type SurfaceTypesProps = {
	open: boolean,
	setOpen: any,
	miles?: number,
	activeSurface?: string,
	prevSurfaceType?: string,
	setActiveSurface?: any,
	setSurfaceModifier?: any,
	setPrevSurfaceModifier?: any,
	setSurfaceUnit?: any,
	handleUpdateSurfaceType: any,
	setActiveSurfaceIcon: any,
	isKm: boolean
}

const SurfaceTypes: FC<SurfaceTypesProps> = ({ open, setOpen, activeSurface, prevSurfaceType, setActiveSurface, setPrevSurfaceModifier, setSurfaceModifier, setSurfaceUnit, handleUpdateSurfaceType, setActiveSurfaceIcon, isKm }) => {

	const [saveChanges, setSaveChanges] = useState(false)
	const [pickedSurface, setPickedSurface] = useState<any>('')

	const surfaceData = [{
		title: 'road',
		icon: <RSIcon type='road'/>,
		description: `If applied, your pace reflects original plan.`,
		modifier: 1,
		unit: 'TIME'
	}, {
		title: 'trail',
		icon: <RSIcon type='tree'/>,
		description: `If applied, your pace slows by 5%. Be careful of your footing.`,
		modifier: 1.05,
		unit: 'TIME'
	}, {
		title: 'hilly trail',
		icon: <RSIcon type='mountain'/>,
		description: `If applied, your pace slows by 15%`,
		modifier: 1.15,
		unit: 'TIME'
	}, {
		title: 'slippery',
		icon: <RSIcon type='snowflake'/>,
		description: `If applied, your pace slows by 20%. Be careful as injury risk is highest on this surface.`,
		modifier: 1.2,
		unit: 'TIME'
	}, {
		title: 'beach',
		icon: <RSIcon type='umbrella-beach'/>,
		description: `If applied, your pace slows by 5%. Stay on packed sand near the water if possible.`,
		modifier: 1.05,
		unit: 'TIME'
	}, {
		title: 'tread',
		icon: <RSIcon type='treadmill' height={25} width={35} />,
		description: `If applied, your pace is converted to ${isKm ? 'kilometers' : 'miles' } per hour.`,
		modifier: 1,
		unit: 'MPH'
	}]


	const handleSurfaceTypePress = (sData: any) => {
		setActiveSurface(sData.title)
		setPickedSurface(sData.title)
		setSurfaceModifier(sData.modifier)
		setSurfaceUnit(sData.unit)
	}


	const handleSave = () => {

		const prevModifier = prevSurfaceType && surfaceData.find(item => item.title === prevSurfaceType)?.modifier
		// prevModifier && console.log('>>> setting prev modifier', prevModifier)
		prevModifier && setPrevSurfaceModifier(prevModifier)
		setActiveSurfaceIcon(surfaceData.find(item => item.title === activeSurface)?.icon)
		setSaveChanges(true)
		setOpen(false)
	}

	useEffect(() => {
		saveChanges && handleUpdateSurfaceType()
		saveChanges && setActiveSurfaceIcon(surfaceData.find(item => item.title === activeSurface)?.icon || surfaceData[0].icon)
		setSaveChanges(false)
	}, [saveChanges])


	useEffect(() => {
		const currentSurface = prevSurfaceType
		setActiveSurfaceIcon(surfaceData.find(item => item.title === prevSurfaceType)?.icon || surfaceData[0].icon)
		setPickedSurface(currentSurface)
	}, [prevSurfaceType])


	return <RSDrawer
		bottom
		fitContent
		popUpWidth='400px'
		open={open}

		onClose={() => {
			setPickedSurface(prevSurfaceType)
			setOpen(false)
		}}
	>
		<Grid container xs={12} sx={{ p: 3 }}>
			<SH1 size={1} sx={{width: '100%', mb: 1}}>Change surface type</SH1>
			<Grid container xs={12} sx={{ py: 1, px: 2 }} justifyContent='center'>
				{surfaceData.map((sData: any, iS: number) => {
					// const isActive = activeSurface ? activeSurface === sData.title : prevSurfaceType === sData.title
					// const isPrev = prevSurfaceType === sData.title
					const isSelected = (pickedSurface === sData.title)
					return <RSButton2 tertiary={!isSelected} key={`surface-icons-${iS}`} onClick={() => handleSurfaceTypePress(sData)} sx={{ height: '65px', width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', m: '5px'}}>
						<Box sx={{ height: '20px' }}>{sData.icon}</Box>
						<Box sx={{ fontSize: '12px !important', fontFamily: 'Poppins', textTransform: 'capitalize', textWrap: 'nowrap' }}>
							{sData.title}
						</Box>
					</RSButton2>
				})}
			</Grid>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '14px' }}>Changes</Grid>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-Light', fontSize: '14px', lineHeight: '1.2em', minHeight: '35px' }}>
				{/* {type === activeSurface ? 'No changes to plan.' : surfaceData.find((s: any) => s.title === activeSurface)?.description} */}
				{surfaceData.find((s: any) => s.title === activeSurface)?.description}
			</Grid>
			<Grid xs={12} sx={{ pt: 1 }} container justifyContent="center" alignItems="flex-end">
				<RSButton1 sx={{ fontSize: '18px', height: '55px', maxWidth: '200px', mt: 1 }}
					onClick={handleSave}
				>Apply</RSButton1>
			</Grid>
		</Grid>
	</RSDrawer>
}

export default SurfaceTypes;
