import { FC, useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { RSButton2 } from '../../components/buttons'
import { SH2, SH3, P1, P2 } from '../../components/text/RSTypography'

export type StrengthYogaProps = {
	addDetails: any
	allStageDetails: any,
	handleIsStepComplete?: any
	setUpdatedAddonDetails?: any
}

const StrengthYoga: FC<StrengthYogaProps> = ({ addDetails, allStageDetails, handleIsStepComplete, setUpdatedAddonDetails }) => {
	const stageNumber = '3'
	const { crossTraining: ct } = allStageDetails?.[stageNumber] || {}
	const [crossTraining, setCrossTraining] = useState<Array<string>>(ct || [])

	//const initialMount = useRef(true)

	const activities = [{
		activity: 'Strength',
		length: '30 minutes',
		desc: 'Physical Therapist-designed workouts that focus on both performance and injury prevention'
	}, {
		activity: 'Yoga',
		length: '30 minutes',
		desc: 'Runner-specific practices that improve strength and mobility'
	}]

	useEffect(() => {
//		if(initialMount.current) {
//			initialMount.current = false
//			return
//		}

		if (allStageDetails?.[stageNumber]?.crossTraining != crossTraining) {
			// console.log('[updated crossTraining]')
			setUpdatedAddonDetails(true)
		}

		addDetails(stageNumber, { crossTraining: crossTraining })
		handleIsStepComplete(true, stageNumber)
	}, [crossTraining])

    return <Box sx={{px: 3}}>
			<SH2 size={3} sx={{ pb: '10px', width: '100%' }}>
					What cross-training would you like to add?
			</SH2>
			<P1 sx={{  width: '100%' }}>
				{`We'll pair workouts to the best days of your plan to maximize performance and minimize injuries.`}
			</P1>
			<Grid container xs={12} sx={{ pt: 1 }} justifyContent='space-between'>
				{activities.map((act: any, ai: number)=>{
					const { activity, length, desc } = act
					const isActive = crossTraining.includes(activity)
					return <Grid item container xs={12} key={'activity-'+ai} sx={{minHeight: '140px', width: '100px', mt: 2}} justifyContent='center' alignItems='center'>
						<RSButton2 secondary={!isActive} sx={{height: '100%', flexDirection: 'column', p: 2}}
							onClick={() => setCrossTraining(isActive ? crossTraining.filter((ct: string)=> activity!==ct) : [...crossTraining, activity])}>
							<Grid container xs={12}>
								<SH3 size={2} sx={{ textAlign: 'left', width: '100%'}}>{activity}</SH3>
								<P2 sx={{ pb: '5px', textAlign: 'left'}}>Average Length: {length}</P2>
								<P2 sx={{ textAlign: 'left'}}>{desc}</P2>
							</Grid>
						</RSButton2>
					</Grid>
				})}
			</Grid>
    </Box>
}

export default StrengthYoga;
