/* eslint-disable @typescript-eslint/no-unused-vars */

import { ReplayRounded, SendRounded } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, InputLabel, MenuItem, Select, Button } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { OpenAIPrompt } from '../../api/v2/open-ai/OpenAIPrompt.types'
import RSButton from '../../components/buttons/RSButton'
import RSInputAdornment from '../../components/input/RSInputAdornment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment'

interface Props {
    data?: OpenAIPrompt
    isLoading: boolean
    submit: (data: OpenAIPrompt, type: string) => Promise<boolean>
    setSelectedPrompt: (prompt: OpenAIPrompt | undefined) => void
    lastVersion?: number
}

const PromptVersionForm: FC<Props> = ({ data, isLoading, submit, setSelectedPrompt, lastVersion }) => {
    const raceTypeSelection = ['full', 'half', '10k', '8k', '5k', '10 mile', 'maintenance']
    const weekEnds = ['Saturday', 'Sunday']
    const [longRunDay, setLongRunDay] = useState('Saturday')
    const [raceType, setRaceType] = useState(data?.race_type || '')
    // const [version, setVersion] = useState(String(data?.version) || '');
    const [step, setStep] = useState(data?.step || '');
    const [systemPrompt, setSystemPrompt] = useState('')
    const [notes, setNotes] = useState('')
    const [params, setParams] = useState({
        "raceType": "Full",
        "gender": "Male",
        "dob": "2006-02-19T05:00:00.000Z",
        "preferredLongRun": longRunDay,
        "availableRunDays": [
            "Monday",
            "Wednesday",
            "Friday",
            "Thursday"
        ],
        "currentStep": 1,
        "planWeeks": 18,
        "runDaysPerWeek": 5,
        "age": 18,
        "trainingLevel": 3,
        "yoga": true,
        "strength": true,
        "stretch": true,
        "unit": "miles",
        "currentWeeklyMileage": 20,
        "lastLongestRun": 10,
        "maxRunPerWeek": 40,
        "race_day": "Wednesday",
        "allDays": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        "maintenanceType": "build",
        "longRunDayPrev": moment().day(longRunDay).subtract(1, 'days').format('dddd'),
        "longRunDayNext": moment().day(longRunDay).add(1, 'days').format('dddd'),
    })

    const handleChange = (event: any) => {
        setRaceType(event.target.value)
    }

    const generatePromptsFromText = (prompt: string, params: any) => {
        return prompt?.replace(/\${(.*?)}/g, (_, expression) => {
            try {
                return new Function('params', `with (params) { return ${expression} }`)(params);
            } catch (e) {
                // console.error(e);
                return ''
            }
        });
    };

    const handleSubmit = async () => {
        const payload: OpenAIPrompt = {
            ...data,
            race_type: raceType,
            version: data && lastVersion ? (+lastVersion.toFixed(1) + 0.1) : 1,
            step: step,
            plan_prompts: systemPrompt,
            notes: notes,
            additional_config: '',
            status: 0
        }

        await submit(payload, data ? 'update' : 'create').then((result) => {
            if (result) handleResetForm()
        })
    }

    const handleResetForm = () => {
        setRaceType('')
        // setVersion('')
        setStep('')
        setSystemPrompt('')
        setNotes('')
        setSelectedPrompt(undefined)
    }

    useEffect(() => {
        if (data) {
            setRaceType(data?.race_type)
            // setVersion(String(data?.version))
            setStep(data?.step as string)
            setSystemPrompt(data?.plan_prompts)
            setNotes(data?.notes as string)
        }
        else
            handleResetForm()
    }, [data])

    useEffect(() => {
        setParams({
            ...params,
            preferredLongRun: longRunDay
        })
    }, [longRunDay])

    return (
        <div className='half-md-card w-100 card mx-auto p-3 mt-1'>
            <h5><b>{`${data ? 'Update' : 'Create New'} Prompt`}</b></h5>
            <Grid container>
                {/* <Grid spacing={1} container xs={12} sx={{ maxWidth: '210px', pt: 1 }}>
                    <Grid xs={12}>
                        <h5>Select Long Run Day</h5>
                    </Grid>
                    {weekEnds.map((day: string, index: number) => {
                        const isActive = longRunDay === day
                        return <Box key={index + day + '-lr'} sx={{ p: '0px 1px', height: '50px', width: '100px' }}>
                            <Button onClick={() => setLongRunDay(day)} sx={{ backgroundColor: `${isActive ? '#bfd38c' : '#f2f2f8'} !important`, position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', border: `1px solid ${isActive ? '#9FCD2B' : '#cdcdcd'}`, minWidth: '100%', color: '#010101' }}
                            >
                                <Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light', fontSize: '14px', color: '#010101' }}>{day}</Box>
                            </Button>
                        </Box>
                    })}
                </Grid> */}
                {/* to change to dropdown selection */}
                <InputLabel className='mt-2' id="demo-multiple-name-label">Select Race Type</InputLabel>
                <Select disabled={data ? true : false} className='w-100' placeholder='Select Program' value={raceType} onChange={(e) => handleChange(e)} >
                    {raceTypeSelection.map((rt, index) => (<MenuItem key={index} value={rt}>{rt}</MenuItem>))}
                </Select>

                {/* <RSInputAdornment disabled={data ? true : false} value={version} setValue={setVersion} label={'Version'} arial={''} /> */}
                <RSInputAdornment disabled={data ? true : false} value={step} setValue={setStep} label={'Step'} arial={''} />

                <Form.Control
                    className='mt-2'
                    value={systemPrompt}
                    type="text"
                    as="textarea"
                    rows={20}
                    placeholder="System Prompt"
                    onChange={(e) => setSystemPrompt(e.target.value)}
                    disabled={false}
                />

                <Accordion style={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Prompt Preview / Params - Click to Expand
                    </AccordionSummary>
                    <AccordionDetails>
                        <Form.Control
                            className='mt-2'
                            value={generatePromptsFromText(systemPrompt, params)}
                            type="text"
                            as="textarea"
                            rows={20}
                            placeholder="System Prompt Preview"
                            disabled={false}
                        />
                        <hr />
                        <p>Params</p>
                        <Form.Control
                            className='mt-2'
                            value={JSON.stringify(params, null, 2)}
                            type="text"
                            as="textarea"
                            rows={20}
                            placeholder="Prompt Preview Params"
                            onChange={(e) => {
                                try {
                                    setParams(JSON.parse(e.target.value))
                                } catch (error) {
                                    console.log(error)
                                }
                            }
                            }
                            disabled={false}
                        />
                    </AccordionDetails>
                </Accordion>

                <Form.Control
                    className='mt-2'
                    value={notes}
                    type="text"
                    as="textarea"
                    rows={3}
                    placeholder="Notes"
                    onChange={(e) => setNotes(e.target.value)}
                    disabled={false}
                />

                <Grid spacing={1} container>
                    <Grid item xs={4}>
                        <RSButton disabled={isLoading} className='mt-2' onClick={handleResetForm}><ReplayRounded /></RSButton>
                    </Grid>
                    <Grid item xs={8}>
                        <RSButton disabled={isLoading || (!data && (!raceType || !step || !systemPrompt)) || (data && data.plan_prompts === systemPrompt)} className='mt-2' onClick={handleSubmit}><SendRounded />  {data ? 'Update' : 'Save'} </RSButton>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )
}

export default PromptVersionForm