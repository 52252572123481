import { useState, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import { AuthPageProps } from '../../modules/types'
import RSCardCont from '../../components/cards/RSCardCont'
import ScrollRow from '../../components/layout/ScrollRow'


const HistoryHome: FC<AuthPageProps> = ({ userInfo }) => {
	const navigate = useNavigate()

	const { data: history, isLoading } = useSWR(userInfo?.account_id ? `/watch-history?user_id=${userInfo?.account_id}` : undefined, { revalidateOnFocus: false, revalidateOnReconnect: false })

	const [isScrolling, setIsScrolling] = useState(false)

	const renderHistory = () => {
		const list = history?.data?.result || [{}, {}, {}]
		return <ScrollRow setIsScrolling={setIsScrolling}>
			{list?.map((plan: any, planIndex: number) => {
				const duration = plan.video_length ? Math.round(plan.video_length / 60) : 0
				return <Box key={planIndex + '-plan-favorites'} sx={{ pr: '5px' }}>
					<RSCardCont isLoading={isLoading} disabled={isScrolling} sx={{ height: '120px', width: '200px', borderRadius: '15px', p: 0 }}
						onClick={() => plan?.program_id && navigate(`explore?p_id=${plan.program_id}&videoId=${plan.video_id}${plan.program_has_intro ? (plan.intro_status ? '&tab=1' : '&tab=2') : '&tab=1'}`, { replace: true })}
					>
						<img src={plan.video_thumbnail} style={{ scale: '1.1', margin: 'auto' }} />
						<Box sx={{ position: 'absolute', p: '5px 10px', bottom: 0, left: 0, background: 'conic-gradient(#ffffff6b, #ffffffdb, white, #ffffff, #ffffff6b)', borderRadius: '0px 15px' }}>
							<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '12px !important', lineHeight: '1.2em', width: 'fit-content' }}>{plan.video_title}</Box>
							<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '12px !important', lineHeight: '1.2em', width: 'fit-content' }}>{duration > 0 ? `${duration} Minute${duration > 1 ? 's' : ''}` : ''}</Box>
						</Box>
					</RSCardCont>
				</Box>
			})}
		</ScrollRow>
	}

	return history?.data?.result?.length ? <Box id='history-home' sx={{ py: 3, pl: 1 }}>
		<Grid container xs={12} sx={{ fontFamily: 'Poppins-Bold', fontSize: '20px', pl: 2 }}>
			History
		</Grid>
		<Grid container xs={12} sx={{ pt: 1 }}>
			{renderHistory()}
		</Grid>
	</Box> : <></>
}

export default HistoryHome;
