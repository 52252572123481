/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, Grid } from "@mui/material";
import RSButton from "../../components/buttons/RSButton";
import { CreateShareTransaction } from "../../modules/shareActions";
import { Form } from "react-bootstrap";
import { useState } from "react";
import RSDrawer from "../../components/layout/RSDrawer";

const ReferrerPanel = (referrerProps: any) => {

    const { setInitiateRefresh, referralCode } = referrerProps

    const [manualPoints, setManualPoints] = useState(0)
    const [displaySuccessPopup, setDisplaySuccessPopup] = useState(false)

    const handlePointsDeduction = () => {
        console.log('Deduct', manualPoints)
        createNewTransaction(-manualPoints)
    }

    const handlePointsAddition = () => {
        console.log('Add', manualPoints)
        createNewTransaction(manualPoints)
    }

    const createNewTransaction = (inputPoints: number) => {

        CreateShareTransaction(1, referralCode, '', 'manual', inputPoints).then((res) => {

            console.log('>>> CreateShareTransaction', res)

            setDisplaySuccessPopup(true)
            setInitiateRefresh(true)

        }).catch((err) => {
            console.log('err', err)
        })

    }

    return <>

        <Box>
            {/* <Grid>User ID:</Grid>
            <Grid>{userDetails?.user_id}</Grid>
            <Grid>Username:</Grid>
            <Grid>{userDetails?.username}</Grid>
            <Grid>Email:</Grid>
            <Grid>{userDetails?.email}</Grid>
            <Grid>Name:</Grid>
            <Grid>{userDetails?.first_name}</Grid>

            <Grid>Referrer Code:</Grid>
            <Grid>{referrerDetails?.referral?.referral_code || ''}</Grid>

            <Grid>Remaining Points Balance:</Grid>
            <Grid>{referrerDetails?.points?.active || '0'}</Grid>
            <Grid>Used points:</Grid>
            <Grid>{referrerDetails?.points?.voided || '0'}</Grid>
            <Grid>Refunded Points:</Grid>
            <Grid>{referrerDetails?.points?.refunded || '0'}</Grid>
            <Grid>Voided points:</Grid>
            <Grid>{referrerDetails?.points?.voided || '0'}</Grid>
            <Grid>Gifted points:</Grid>
            <Grid>{referrerDetails?.points?.voided || '0'}</Grid>
            <Grid>Deducted points:</Grid>
            <Grid>{referrerDetails?.points?.voided || '0'}</Grid> */}

            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '2rem', mx: '2rem' }}>
                <Form.Control
                    style={{ margin: '0 0.2rem' }}
                    type="text"
                    placeholder="Input points here"
                    onChange={(e) => setManualPoints(e.target.value as unknown as number)}
                />
                <RSButton sx={{ mx: '0.2rem' }} onClick={handlePointsAddition}>Gift</RSButton>
                <RSButton sx={{ mx: '0.2rem' }} onClick={handlePointsDeduction}>Deduct</RSButton>
            </Box>
        </Box>

        <RSDrawer bottom open={displaySuccessPopup} onClose={() => setDisplaySuccessPopup(false)}>
            <div style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <div style={{ marginTop: '2rem', marginBottom: '1rem', fontSize: '1.2rem', width: 'auto', fontWeight: 'bold', textAlign: 'center' }}>User points/credits changed.</div>
            </div>
        </RSDrawer>
    </>
}

export default ReferrerPanel;