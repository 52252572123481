import { FC } from 'react'

import Grid from '@mui/material/Grid'
import { H3, SH3, P2, A } from '../../components/text/RSTypography'
import { SHARE_REWARD_AMOUNT } from '../../modules/cliEnvValues'
type EarningCreditProps = {}

const EarningCredit: FC<EarningCreditProps> = () => {
	return <Grid container xs={12}>
		<Grid container xs={12} sx={{ pt: 3 }}>
			<SH3 size={3}>Earn Cred, Get Cred</SH3>
			<P2 size={1} sx={{ pt: 1, lineHeight: '1.3em' }}>Share RunSmart with your running buds. If they become an active member, you earn <s>$10.00</s> {`$${SHARE_REWARD_AMOUNT.toFixed(2)}`} to RunSmart shop. ({`$${SHARE_REWARD_AMOUNT}`} offer expires 11/30/2024)</P2>
		</Grid>
		<H3 size={2} sx={{ pt: 3 }}>HOW IT WORKS</H3>
		<Grid container xs={12} sx={{ pt: 2 }}>
			<Grid container xs={6}>
				<H3 size={1}>SEND OR SHARE</H3>
				<P2 sx={{ pt: 1, lineHeight: '1.3em' }}>
					Helping your running buds train smarter is easy. Share your unique link through social, text, or email.
				</P2>
			</Grid>
			<Grid container xs={6} sx={{ pl: 2 }} justifyContent='flex-start' alignItems='flex-end'>
				<img style={{ width: '100%', height: 'auto', maxWidth: '140px' }} src="./img-new/invite/send_invite.jpg" />
			</Grid>
		</Grid>
		<Grid container xs={12} sx={{ pt: 2 }}>
			<Grid container xs={6} sx={{ pr: 2 }} justifyContent='flex-end' alignItems='flex-end'>
				<img style={{ width: '100%', height: 'auto', maxWidth: '150px' }} src="./img-new/invite/earn_credit.jpg" />
			</Grid>
			<Grid container xs={6}>
				<H3 size={1}>EARN STREET CRED</H3>
				<P2 sx={{ pt: 1, lineHeight: '1.3em' }}>
					When a runner joins RunSmart, you earn <s>$10.00</s> {`$${SHARE_REWARD_AMOUNT.toFixed(2)}`} USD to the RunSmart shop.
				</P2>
			</Grid>
		</Grid>
		<Grid container xs={12} sx={{ pt: 2 }}>
			<Grid container xs={6}>
				<H3 size={1}>SWAG OUT</H3>
				<P2 sx={{ pt: 1, lineHeight: '1.3em' }}>
					From couch to 5K to marathon, shop RunSmart Merch using your credits and look great in every phase of training.
				</P2>
			</Grid>
			<Grid container xs={6} sx={{ pl: 2 }} justifyContent='flex-start' alignItems='center'>
				<img style={{ width: '100%', height: 'auto', maxWidth: '150px' }} src="./img-new/invite/shop_runsmart.jpg" />
			</Grid>
		</Grid>

		<Grid container xs={12} sx={{ pt: 5 }} justifyContent='center' alignItems='center'>
			<A link='https://runsmartonline.com/terms-of-use/referral-program/' newWindow>
				Terms and conditions
			</A>
		</Grid>
	</Grid>
}
export default EarningCredit;
