import { FC } from 'react'

import Grid from '@mui/material/Grid'

import MyCredit from './MyCredit'
import EarningCredit from './EarningCredit'
import { RSTabsDataProps } from '../../modules/types'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import useRSTabs from '../../hooks/useRSTabs'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

type ShareCreditProps = {
	setOpen: any
}

const ShareCredit: FC<ShareCreditProps> = ({setOpen}) => {
	const isMobile = useIsMobileScreen();

	const tabList: RSTabsDataProps = [{
		title: 'MY CRED',
		body: <MyCredit/>
	}, {
		title: 'EARNING CRED',
		body: <EarningCredit/>
	}]

	const { tabsUI } = useRSTabs({
		data: tabList,
		btnContSX: { width: '100%', justifyContent: 'space-evenly' },
	})

	return <Grid container justifyContent='center'>
		<RSHeaderCont drawer secondary={!isMobile} isStatic={!isMobile} noShadow={!isMobile}  backClick={()=>setOpen(false)} title="RunSmart Cred" />
		<Grid xs={12} sx={{ px: 3, pt: 2, pb: 4 }}>
			{tabsUI}
		</Grid>
	</Grid>
}
export default ShareCredit;
