import {FC} from 'react'
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerToolbar  } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

import RSTheme from '../styles/RSTheme'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

export type RSDateInputProps = {
	date: string,
	setDate?: any,
	maxDate?: any,
	minDate?: any,
	label?: string,
	outlineSX?: object
	day?: boolean
	month?: boolean,
	icon?: boolean,
	disabled?: boolean,
	disableDates?: any,
	isWeekSelector?: boolean,
	isDayRangeSelector?: boolean,
	calendarLabel?: string,
	end?: boolean,
	otherDate?: string
}

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
  isDaySelected: boolean;
  isStart: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<CustomPickerDayProps>(({ theme, isSelected, isDaySelected, isStart }) => ({
  borderRadius: 0,
  ...(isSelected &&  {
    background: isDaySelected ? `linear-gradient(90deg, ${isStart ? 'white' : '#9fcd2b66'} 50%, ${isStart ? '#9fcd2b66' : 'white'} 50%)` :  theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      background: isDaySelected ? `linear-gradient(90deg, ${isStart ? 'white' : '#9fcd2b66'} 50%, ${isStart ? '#9fcd2b66' : 'white'} 50%)` :  theme.palette.primary.light,
    },
  }),
})) as React.ComponentType<CustomPickerDayProps>;





const RSDateInput: FC<RSDateInputProps> = ({date = '', setDate, maxDate, minDate, label = '', outlineSX = {}, day, month, icon, disabled = false, disableDates, isWeekSelector = false, isDayRangeSelector = false, calendarLabel, end = false, otherDate}) => {
	const isMobile = useIsMobileScreen();
	const initialDisplay = day ? 'day' : month ? 'month' :'year'

	//const selectedWeekDayIndex = end ? 6 : 0


  const getDateWeek = (d: any) => {
  	return moment(new Date(d)).format('YYYY/MM/DD')
  }
  const getStartEnd = (d: any, isEnd?: boolean) => {
  	let dayIndex = moment(getDateWeek(d)).isoWeekday()
  	dayIndex = dayIndex === 7 ? 0 : dayIndex
  	return isDayRangeSelector ? moment(d) : isEnd ?  moment(getDateWeek(d)).add(6 - dayIndex, 'days') : moment(getDateWeek(d)).subtract(dayIndex, 'days')
  }

  const isInSameWeek = (dayA: Dayjs, dayB: any) => {
    if (dayB == null) {
      return false;
    }

    return dayA.isSame(dayB, 'week');
  };

  const isBetweenStartAndEnd = (dayA: any, dayB: any, currDate: Dayjs) => {
		return moment(dayjs(currDate).toDate()).isBetween(moment(dayA).subtract(1, 'd'), moment(dayB).add(1, 'd'))
	}

	const Picker = isWeekSelector ? MobileDatePicker : DatePicker

  const Day = (props?: any) => {
    const { day, selectedDay, ...other } = props
		const selectedWeek = getStartEnd(selectedDay, end)
		const selectedStartWeek = getStartEnd(end ? otherDate : selectedDay)
		const selectedEndWeek = getStartEnd(end ? selectedDay : otherDate, true)
		const isDaySelected = moment(selectedStartWeek).format('YYYY/MM/DD') === getDateWeek(day) || moment(selectedEndWeek).format('YYYY/MM/DD') === getDateWeek(day)
		const isInWeek = otherDate && day && selectedStartWeek && selectedEndWeek ?
		 	isBetweenStartAndEnd(selectedStartWeek, selectedEndWeek, day) : isInSameWeek(day, selectedWeek)
		return (
  		<CustomPickersDay
  			{...other}
  			day={day}
  			sx={{ px: 2.5}}
  			disableMargin
  			selected={false}
  			isSelected={isInWeek}
  			isDaySelected={isDaySelected}
  			isStart={moment(selectedStartWeek).format('YYYY/MM/DD') === getDateWeek(day)}
  		>
  			{isDaySelected && isInWeek && <Box sx={{position: 'absolute', backgroundColor: '#9fcd2b', width: 'calc(100% - 4px)', height: '100%', borderRadius: '50%'}}/>}
  			<Box sx={{zIndex: 1, fontFamily: 'inherit', fontSize: 'inherit'}}>{day.format('D')}</Box>
  		</CustomPickersDay>
    );
  }

  const DateToolbar = (props: any) => {
  	const { value } = props
  	return <DatePickerToolbar {...props} value={getStartEnd(value, end)}/>;
  }

	const handleOnChange = (newValue: any) => {
		const nd =  moment(new Date(newValue)).format('YYYY/MM/DD')
		setDate?.(nd)
	}

	const Icon = () => <Box className='pointer' sx={{position: isWeekSelector ? 'absolute' : 'static', fontSize: '24px', right: 10, top: '50%', mt: '2px !important', height: 'calc(100% - 8px)', transform: isWeekSelector ? 'translateY(-50%)' : 'unset'}}>
		<i className={`fa-duotone fa-solid fa-calendar-day duo-tone${disabled ? '-disabled' : ''}`}/>
	</Box>

	return (
		<Box sx={{width: '100%', position: 'relative'}} className='testes'>
			<RSTheme>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DemoItem>
						<Picker
							sx={{
								'& .MuiOutlinedInput-root': {
									height: '45px',

								},
								'& .MuiInputBase-input': {
									mt: '4px',
									zIndex: 1,
									fontSize: '14px',
									fontFamily: 'Poppins'
								},
								'& .MuiOutlinedInput-notchedOutline': {
									backgroundColor: 'white',
									...icon ? {border: `1px solid #9fcd2b${disabled ? '' : ' !important'}`} : {},
									...outlineSX
								},
								'& .MuiIconButton-root': {
									height: label ? '45px' : '42px',
									py: 0,
									zIndex: isMobile ? 0 : 1,
								},
								width: '100%',
							}}
							slotProps={{
								textField: {
									error: false
								},
								...isWeekSelector && { day: () =>
									({
										selectedDay: date
									}) as any
								},
							}}
							localeText={{ toolbarTitle: calendarLabel || 'Selected Date' }}
							disabled={disabled}
							label={label}
							openTo={initialDisplay}
							maxDate={maxDate ? dayjs(moment(new Date(maxDate)).format('YYYY-MM-DD')) : null}
							minDate={minDate ? dayjs(moment(new Date(minDate)).format('YYYY-MM-DD')) : null}
							views={['year','month','day']}
							value={dayjs(moment(new Date(date)).format('YYYY/MM/DD'))}
							onChange={(newValue: any) => handleOnChange(newValue)}
							shouldDisableDate={(d: any) => disableDates?.(d)}
							slots={{
								day: isWeekSelector ? Day : undefined,
								toolbar: isWeekSelector ? DateToolbar : undefined,
								openPickerIcon: Icon,
							}}
							showDaysOutsideCurrentMonth={isWeekSelector}
							onAccept={() => isWeekSelector && setDate?.(getStartEnd(date, end).format('YYYY/MM/DD'))}
						/>
						{icon && Icon()}
					</DemoItem>
				</LocalizationProvider>
			</RSTheme>
		</Box>
	)
}

export default RSDateInput;
