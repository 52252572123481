import { FC } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {RSButton2} from '../buttons'

export type RSJourneyCardProps = {
	className?: string,
	children?: any,
	onClick?: Function,
	isPast?: boolean,
	isCurrentActive?: boolean,
	sx?: object
}


const RSJourneyCard: FC<RSJourneyCardProps> = ({ className = '', sx = {}, children, onClick, isPast, isCurrentActive}) => {
	const title = isPast ? 'Completed' : (isCurrentActive ? 'Active' : 'Upcoming')
	return <Grid container xs={12} onClick={()=>onClick?.()} className={`pointer ${isCurrentActive ? 'orange-border border-1 border-solid' : ''} default-bs ${className}`} sx={{borderRadius: '5px',overflow: 'hidden', position: 'relative', ...sx}}>
		<RSButton2 secondary={!isCurrentActive} borderless sx={{p: 0, flexDirection: 'column', overflow: 'hidden', boxShadow: 'none !important', border: 'none !important', borderRadius: '0px !important'}}>
			<Grid container sx={{textAlign: 'center', position: 'relative', fontFamily: `Poppins-${isCurrentActive ? 'SemiBold' : 'Light'}`, fontStyle: isCurrentActive ? 'normal' : 'italic', fontSize: '14px', height: '26px', color: isCurrentActive ? '#010101' : '#666'}} justifyContent='center' alignItems='center'>
				{title}
			</Grid>
		</RSButton2>
		<Box className='transition-all' sx={{height: 'auto', width: '100%', backgroundColor: '#fff', px: 2, py: '5px'}}>
			{children}
		</Box>
	</Grid>
}

export default RSJourneyCard;
