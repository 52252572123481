/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowLeft, ArrowRight, ReplayRounded, SendRounded } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import moment from 'moment'
import { FC, useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap'
import useSWR from 'swr'
import Loader from '../../components/animation/Loader'
import RSButton from '../../components/buttons/RSButton'
import RSDateInput from '../../components/input/RSDateInput'
import RSInputAdornment from '../../components/input/RSInputAdornment'
import { postReq } from '../../modules/apiConsume'
import { AuthPageProps } from '../../modules/types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JSONTable from './JSONTable'
import useManualCalculations from './useManualCalculations'
import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from '../training-plan/build/DaysOptions'
import useAIPlanPrompts, { AIUserDetails } from '../../modules/useAIPlanPrompts'
import useQueryParams from '../../hooks/useQueryParams'
import CryptoJS from 'crypto-js';
import useRSRunAIsuggestions from '../../hooks/useRSRunAIsuggestions';

const BOILERPLATE_PROMPT = 'Build a training plan for a half marathon (13.1 miles) with all the steps and rules mentioned. '

interface IMessage {
    role: string
    content: string
    input_token?: number
    output_token?: number
    total_tokens?: number
}

const CRYPTO_KEY = "MWT3BlbkFJG2PIu";

const ChatCompletionSingle: FC<AuthPageProps> = ({ userInfo }) => {

    const { data: accountProfile, isLoading: accountProfileLoading } = useSWR(userInfo?.account_id ? `/account-profile?account_id=${userInfo?.account_id}` : null, { refreshInterval: 0 })

    const { dob, measurement_system, running_activity, gender } = accountProfile?.data?.result[0] || {}

    const { versioning } = useQueryParams()

    const { getSuggestedPlanStartDistanceSANDBOX } = useRSRunAIsuggestions()

    const gptModels = ['gpt-3.5-turbo-0125', 'gpt-4o', 'gpt-4o-mini', 'gpt-4o-2024-05-13', 'gpt-4o-2024-08-06']
    const defaultUserDetails: AIUserDetails = {
        planWeeks: 0,
        dob: dob && dob || '1986/01/01',
        gender: gender || 'Male',
        currentWeeklyMileage: 20,
        unit: 'miles',
        lastLongestRun: 6,
        maxRunPerWeek: 30,
        race_day: 'Sunday'
    }

    // const stage2DefaultUserDetails: Stage2Details = {
    //     preferredLongRun: 'Saturday',
    //     availableRunDays: ['Sunday', 'Tuesday', 'Thursday', 'Saturday'],
    //     runDaysPerWeek: 4
    // }

    const raceTypeSelection = ['Full', 'Half', '10k', '8k', '5k', '10 mile', 'Maintenance']
    const maintenanceType = ['Maintain', 'Build']
    const weekEnds = ['Saturday', 'Sunday']
    const runDaysPerWeekSelection = [3, 4, 5, 6, 7]

    const { applyRecoveryWeeks, applyTaperWeeks, getWithHighestWeeklyMileage, getLongRuns, syncLongRunDay, reArrangeMileage, hasOneOrTwoValueRuns, getHighestLongRun, convertWeeklyMileageData, convertDailyMileageData, convertRunTypesData, appendCrossTrainingData, morphAddRunTypes, adjustLowMileageDays, patchWarmupDay, cleanupRaceWeek, setLastWeekToRacePace } = useManualCalculations()
    // const { defaultSytemPrompt, buildStage2InputPrompts, buildUserDetails } = ChatCompletionPromptsSingle()
    const usedPromptSteps: string | any[] = [BOILERPLATE_PROMPT]
    const [userDetails, setUserDetails] = useState<AIUserDetails>(defaultUserDetails)
    const [results, setResults] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)
    const [messages, setMessages] = useState<IMessage[]>([])
    const [isSinglePrompt, setIsSinglePrompt] = useState(false)
    const [systemPromptsSteps, setSystemPromptsSteps] = useState([''])
    const [runningActivity, setRunningActivity] = useState('10');
    const [longestRecentRun, setLongestRecentRun] = useState('6');
    const [raceType, setRaceType] = useState('Full')
    const [maxDistance, setMaxDistance] = useState('20');
    const [planWeeks, setPlanWeeks] = useState('16');
    const [dateValue, setDateValue] = useState<any>(defaultUserDetails?.dob || undefined);
    const isMetric = measurement_system === 'metric' ? true : false
    const metricLabel = isMetric ? 'KM' : 'MI'
    const metricArial = isMetric ? 'kilometers' : 'miles'
    const [longRunDay, setLongRunDay] = useState('Saturday')
    const [dailyRunTable, setDailyRunTable] = useState(false)
    const [selectedGPTModel, setSelectedGPTModel] = useState(gptModels[3])
    const [gptTemp, setGPTTemp] = useState('0')
    const [initLongRuns, setInitLongRuns] = useState([])
    const [resultsPerStep, setResultsPerStep] = useState<any[]>([])
    const [taperRecoveryWeeks, setTaperRecoveryWeeks] = useState<any[]>([])
    const [initWeeklyMileage, setInitWeeklyMileage] = useState<any[]>([])
    const [skipStep3, setSkipStep3] = useState(false)
    const [runDaysPerWeek, setRunDaysPerWeek] = useState(3);
    const [history, setHistory] = useState<IMessage[]>([]);
    const [selectedMaintenanceType, setSelectedMaintenanceType] = useState<string>("Build")

    const { defaultSytemPrompt, buildStage2InputPrompts, buildUserDetails, prompts } = useAIPlanPrompts(1, raceType.toLowerCase())
    const [formText, setFormText] = useState(buildUserDetails(userDetails))
    const [systemPrompt, setSystemPrompt] = useState(defaultSytemPrompt)
    const [raceDay, setRaceDay] = useState('Sunday')

    let daysOfWeek = longRunDay === 'Sunday' ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK
    daysOfWeek = daysOfWeek.filter((day) => day !== longRunDay)
    const [availableDays, setAvailableDays] = useState<Array<string>>([])
    const retryAttempts = 3;

    const [stage2InputPrompts, setStage2InputPrompts] = useState(buildStage2InputPrompts({ preferredLongRun: longRunDay, availableRunDays: availableDays, runDaysPerWeek: runDaysPerWeek, raceType, currentStep: currentStep, stepResults: resultsPerStep, dob: dateValue, gender: userDetails?.gender || 'male', initLongRuns: initLongRuns, planWeeks: Number(planWeeks), level: 3, crossTraining: ['Yoga', 'Strength'], race_day: raceDay }))

    const [inputPrompstValues, setInputPrompstValues] = useState<string[]>([])

    const [suggestedStartLongRun, setSuggestedStartLongRun] = useState(0)

    const [sendPrompts, setSendPrompts] = useState(false)

    const updateResultsPerStep = (step: number, data: any) => {
        if (resultsPerStep.length === 0)
            setResultsPerStep([{ step: step + 1, data: data }])
        else {
            const curStep = resultsPerStep.find(x => x.step === (step + 1))
            if (curStep) {
                const filtered = resultsPerStep.filter(x => x.step !== curStep.step)
                setResultsPerStep([...filtered, { step: curStep.step, data: data }])
            }
            else
                setResultsPerStep([...resultsPerStep, { step: step + 1, data: data }])
        }
    }

    const setTaperAndRecoveryWeeks = (data: any) => {
        const taperWeeks = data.filter((x: any) => x.Taper)
        const recoveryWeeks = data.filter((x: any) => x.Recovery)
        setTaperRecoveryWeeks([...taperWeeks, ...recoveryWeeks])
    }

    const getTotalTokens = (data: IMessage[]) => {
        let total = 0;
        data.forEach((msg) => {
            if (msg.total_tokens)
                total += msg.total_tokens
        })

        const number = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return number.format(total).split('.')[0].replace('$', '') + ' tokens'
    }


    const handleAIprompting = async () => {

        setIsLoading(true)

        const isMaintenance = raceType.toLowerCase() === 'maintenance';
        const msgs = currentStep !== 1 ? [{ role: 'user', content: formText }] : messages
        const encryptedMsgs = CryptoJS.AES.encrypt(JSON.stringify(msgs), String(CRYPTO_KEY)).toString();
        if (msgs.length > 0) {
            msgs?.forEach((msg) => {
                history.push(msg)
            })
        }

        let curResults: any = []
        if (currentStep === 1) {
            for (let attempt = 1; attempt <= retryAttempts; attempt++) {
                console.log('Fetching results attempt: ', attempt)
                const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: selectedGPTModel, gptTemp: gptTemp })

                console.log('first response', response)

                if (response && response.status === "error") {
                    console.log('ERROR')
                    setIsLoading(false)
                    if (attempt == retryAttempts) alert(response.error.error.error.message)
                    continue
                }
                const responseData = convertWeeklyMileageData(response?.data?.output?.plan)
                const responseValid = validateResponse(responseData)
                const usage = response?.data?.usage

                console.log('1111')

                history.push({ role: 'output', content: JSON.stringify(response?.data?.output?.plan), input_token: usage?.prompt_tokens, output_token: usage?.completion_tokens, total_tokens: usage?.total_tokens })


                console.log('2222')

                // disabled for now Nov,1,2024
                // if (!responseValid) continue
                setIsLoading(false)


                console.log('3333')

                curResults = getLongRuns(applyRecoveryWeeks(applyTaperWeeks(getWithHighestWeeklyMileage(responseData), raceType), raceType) || [], raceType, Number(maxDistance || 0), Number(longestRecentRun || 0), getHighestLongRun(responseData), false) || []

                const longruns: any = []
                const weeklyMileage: any = []

                console.log('suggested start long run', userDetails?.suggestedPlanStartDistance)

                console.log('sent prompt', msgs)

                curResults.forEach((e: any, idx: number) => {
                    idx === 0 ? longruns.push({ w: e.Week, lr: userDetails?.suggestedPlanStartDistance || e.Long_run }) : longruns.push({ w: e.Week, lr: e.Long_run })
                    weeklyMileage.push({ w: e.Week, wm: e.Weekly_mileage })
                });

                setInitWeeklyMileage(weeklyMileage)
                setInitLongRuns(longruns)

                curResults[0].Long_run = userDetails?.suggestedPlanStartDistance || curResults[0].Long_run
                break
            }
        }
        // else if (currentStep === 2) {

        //     setDailyRunTable(true)
        //     const dailyRuns = getDailyRuns(results, { long_run_day: longRunDay, run_days: availableDays })
        //     console.log(dailyRuns)
        //     curResults = { plan: assignRunType(assignLongRunDay(dailyRuns, longRunDay), longRunDay) }

        // }
        else {
            const response = await postReq('/v2/openai/chat-completion', { messages: encryptedMsgs, gptModel: selectedGPTModel, gptTemp: gptTemp })
            if (response && response.status === "error") {
                setIsLoading(false)
                alert(response.error.error.error.message)
                return
            }

            curResults = response?.data?.output || []
            const usage = response?.data?.usage || []

            history.push({ role: 'output', content: JSON.stringify(response?.data?.output?.plan), input_token: usage?.prompt_tokens, output_token: usage?.completion_tokens, total_tokens: usage?.total_tokens })

            if (currentStep === 2) {
                // if (raceType.toLowerCase() !== "full") {
                curResults = { plan: convertDailyMileageData(curResults.plan) }
                // if (!isMaintenance) {
                curResults = { plan: reArrangeMileage(curResults.plan, raceType) }
                curResults = { plan: adjustLowMileageDays(curResults.plan) }
                // }
                // const skipStep3 = hasOneOrTwoValueRuns(curResults?.plan) ? false : true
                const skipStep3 = true
                setSkipStep3(skipStep3)
                // if (skipStep3) {
                curResults = { plan: syncLongRunDay(curResults.plan, longRunDay, initLongRuns, taperRecoveryWeeks, userDetails?.suggestedPlanStartDistance || 0) }
                if (raceType.toLowerCase() === "5k" || raceType.toLowerCase() === "10k" || raceType.toLowerCase() === "8k") {
                    const raceDayName = raceDay.toLowerCase();
                    curResults = { plan: patchWarmupDay(curResults.plan, raceDayName) }
                    // curResults = { plan: cleanupRaceWeek(curResults.plan, raceDay) }
                }
                // console.log('skipping step, no 1 or 2 mile runs')
                // }
                // else if (!skipStep3) console.log('Not Skipping Step')
                // }
            }
            // else if (currentStep === 3 && !skipStep3) {
            //     // console.log('step 3 and not skipping')
            //     // if (raceType.toLowerCase() !== "full")
            //     curResults = { plan: convertDailyMileageData(curResults.plan) }
            //     curResults = { plan: syncLongRunDay(curResults.plan, longRunDay, initLongRuns, taperRecoveryWeeks) }
            // }
            else if (currentStep === 3) {
                try {
                    // console.log(curResults)
                    // if (raceType.toLowerCase() === "5k" || raceType.toLowerCase() === "10k" || raceType.toLowerCase() === "8k") {
                    if (!isMaintenance)
                        curResults = { plan: setLastWeekToRacePace(curResults.plan) }
                    // }
                    curResults = { plan: morphAddRunTypes(resultsPerStep.find((x: any) => x?.step === currentStep)?.data, curResults.plan) }
                }
                catch (e) {
                    setIsLoading(false)
                    console.log(e)
                }
                // curResults = { plan: convertRunTypesData(curResults.plan) }
            }
            else if (currentStep === 4) {
                // curResults = { plan: convertCrossTrainingData(curResults.plan) }
                curResults = { plan: appendCrossTrainingData(resultsPerStep.find((x: any) => x?.step === currentStep)?.data, curResults.plan) }
            }
        }

        updateResultsPerStep(currentStep, currentStep === 1 ? curResults : curResults.plan)
        currentStep === 1 && setTaperAndRecoveryWeeks(currentStep === 1 ? curResults : curResults.plan)
        setResults(currentStep === 1 ? curResults : curResults.plan)
        handleNextStep()
        // setMessages([...msgs, { role: 'assistant', content: JSON.stringify(currentStep === 1 ? { plan: curResults } : curResults) }])
        setMessages([{ role: 'user', content: formText }])
        setIsLoading(false)

    }

    const handleSubmit = () => {
        setIsLoading(true)

        console.log('>>> userDetails', userDetails)

        getSuggestedPlanStartDistanceSANDBOX(userDetails).then((response: any) => {
            console.log('>>> getSuggestedPlanStartDistance', response)

            if (response && response > 0) {
                console.log('[setting running activity]')
                // setRunningActivity(response)

                setSuggestedStartLongRun(response)

                userDetails.suggestedPlanStartDistance = response

                // setSendPrompts(true)

                setIsLoading(false)
            }
        })


    }

    const handleChange = (event: any) => {
        setRaceType(event.target.value)
    }
    const handleNextStep = () => {
        if (currentStep < 0)
            setCurrentStep(1)
        else
            setCurrentStep(currentStep + 1)
    }

    const handleBackStep = () => {
        if (currentStep === 1) return
        setCurrentStep(currentStep - 1)
    }

    const handleReset = () => {
        setSuggestedStartLongRun(0)
        setResults([])
        setInitLongRuns([])
        setInitWeeklyMileage([])
        setSkipStep3(false)
        setMessages([{ role: 'system', content: systemPrompt }, { role: 'user', content: formText }])
        setHistory([])
        setCurrentStep(1)
        setDailyRunTable(false)
    }


    const validateResponse = (tableData: any) => {

        let valid = true
        tableData?.forEach((week: any) => {
            if (week.Weekly_mileage > maxDistance) {
                valid = false
                return
            }
        })
        return valid
    }

    useEffect(() => {
        if (currentStep == 1)
            setFormText(inputPrompstValues[0])
        // else if (currentStep == 2)
        //     setFormText(`Assign long run day to the plan, selected day is ${longRunDay}`)
        // else if (currentStep > inputPrompstValues.length)
        //     setFormText(`Training Plan: \n${resultsPerStep.length ? JSON.stringify(resultsPerStep.find((x: any) => x?.step === currentStep)?.data) : undefined}\n`)
        else
            setFormText(inputPrompstValues[currentStep - 1])

    }, [currentStep, resultsPerStep, inputPrompstValues])






    useEffect(() => {
        if (currentStep == 1)
            setMessages(() => [{ role: 'system', content: systemPrompt }, { role: 'user', content: formText }])
        else
            setMessages(() => [{ role: 'user', content: formText }])
    }, [formText])

    useEffect(() => {
        setUserDetails({
            ...userDetails,
            dob: dateValue,
            lastLongestRun: Number(suggestedStartLongRun || longestRecentRun || 0),
            suggestedPlanStartDistance: suggestedStartLongRun,
            maxRunPerWeek: Number(maxDistance || 0),
            currentWeeklyMileage: Number(runningActivity || 0),
            raceType: raceType,
            planWeeks: Number(planWeeks || 0),
            race_day: raceDay,
            maintenanceType: selectedMaintenanceType.toLowerCase()
        })
    }, [dateValue, runningActivity, longestRecentRun, maxDistance, raceType, planWeeks, raceDay, selectedMaintenanceType, suggestedStartLongRun])

    useEffect(() => {

        setFormText(buildUserDetails(userDetails))
        const inputPrompstValues = [buildUserDetails(userDetails), ...stage2InputPrompts]
        setInputPrompstValues(inputPrompstValues)
    }, [userDetails, availableDays, longRunDay, raceType, planWeeks, runningActivity, longestRecentRun, maxDistance, dateValue, selectedGPTModel, runDaysPerWeek, stage2InputPrompts, runDaysPerWeek, raceDay, selectedMaintenanceType, suggestedStartLongRun])

    useEffect(() => {
        setStage2InputPrompts([...buildStage2InputPrompts({ preferredLongRun: longRunDay, availableRunDays: availableDays, runDaysPerWeek: runDaysPerWeek, raceType, currentStep: currentStep, stepResults: resultsPerStep, dob: dateValue, gender: userDetails?.gender || 'male', initLongRuns: initLongRuns, planWeeks: Number(planWeeks), level: 3, crossTraining: ['Yoga', 'Strength'], race_day: raceDay })])
    }, [resultsPerStep, currentStep, availableDays, runDaysPerWeek, longRunDay, raceDay])


    useEffect(() => {
        setFormText(buildUserDetails(userDetails))
    }, [prompts])


    useEffect(() => {
        if (sendPrompts) {
            console.log('!!! suggesting plan start distance', suggestedStartLongRun)
            handleAIprompting()
            setSendPrompts(false)
        }
    }, [sendPrompts])

    return (
        <Grid container spacing={1} xs={12}>
            <Grid item xs={12}>
                {/* <Select className='w-100' placeholder='Select Program' value={selectedPrompt} onChange={(e) => handleChange(e)} >
                    {selection.map((s, index) => (<MenuItem key={index} value={s?.id}>{s.name}</MenuItem>))}
                </Select> */}
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        System Prompt - Single
                    </AccordionSummary>
                    <AccordionDetails>
                        <Form.Control
                            value={systemPrompt}
                            type="text"
                            as="textarea"
                            rows={11}
                            placeholder="System Prompt"
                            onChange={(e) => setSystemPrompt(e.target.value)}
                            // onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
                            // onBlur={() => handleUpdateSale("subtext")}
                            disabled={isLoading}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1} xs={12}>
                    <Grid item xs={3}>
                        <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                            <Form.Label className='mt-3'>
                                User Details
                            </Form.Label>
                            <Box>
                                <Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Bold' }}>Date of birth</Box>
                                <Grid container xs={12} sx={{ maxWidth: '120px' }}>
                                    <RSDateInput date={dateValue} setDate={setDateValue} maxDate={moment()} day />
                                </Grid>
                                <Grid container>
                                    {/* to change to dropdown selection */}
                                    <InputLabel className='mt-2' id="demo-multiple-name-label">Select Race Type</InputLabel>
                                    <Select className='w-100' placeholder='Select Program' value={raceType} onChange={(e) => handleChange(e)} >
                                        {raceTypeSelection.map((rt, index) => (<MenuItem key={index} value={rt}>{rt}</MenuItem>))}
                                    </Select>
                                </Grid>
                                {raceType.toLowerCase() === 'maintenance' && (<>
                                    <Grid container xs={12} sx={{ pt: 2, fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                        {`Select Maintenance Type`}
                                    </Grid>
                                    <Grid container xs={12} sx={{ pt: 1 }}>
                                        {maintenanceType?.map((type: string, index: number) => {
                                            const isActive = type === selectedMaintenanceType
                                            return <Grid container key={index} sx={{ p: '0px 1px', height: '50px', maxWidth: '100px !important' }} justifyContent='center' alignItems='center'>
                                                <Button onClick={() => isActive ? setSelectedMaintenanceType(type) : setSelectedMaintenanceType(type)}
                                                    sx={{ backgroundColor: `${isActive ? '#bfd38c' : '#f2f2f8'} !important`, position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', border: `1px solid ${isActive ? '#9FCD2B' : '#cdcdcd'}`, minWidth: '100%', color: '#010101', px: 0 }}
                                                >
                                                    <Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light', fontSize: '14px', color: '#010101' }}>{type}</Box>
                                                </Button>
                                            </Grid>
                                        })}
                                    </Grid>
                                </>)}
                                <Grid container xs={12} sx={{ pt: 2, fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                    {`Select Race Day`}
                                </Grid>
                                <Grid container xs={12} sx={{ pt: 1 }}>
                                    {DAYS_OF_WEEK?.map((day: string, index: number) => {
                                        const slicedDay = day.slice(0, 2)
                                        const isActive = raceDay === day
                                        return <Grid container key={index + day} xs={12 / daysOfWeek.length} sx={{ p: '0px 1px', height: '50px', maxWidth: '45px !important' }} justifyContent='center' alignItems='center'>
                                            <Button onClick={() => isActive ? setRaceDay(day) : setRaceDay(day)}
                                                sx={{ backgroundColor: `${isActive ? '#bfd38c' : '#f2f2f8'} !important`, position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', border: `1px solid ${isActive ? '#9FCD2B' : '#cdcdcd'}`, minWidth: '100%', color: '#010101', px: 0 }}
                                            >
                                                <Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light', fontSize: '14px', color: '#010101' }}>{slicedDay}</Box>
                                            </Button>
                                        </Grid>
                                    })}
                                </Grid>
                                <Grid container xs={12} sx={{ pt: '15px', fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                    {`Plan Length (weeks)`}
                                </Grid>
                                <Grid container sx={{ maxWidth: '120px' }}>
                                    <RSInputAdornment value={planWeeks} setValue={setPlanWeeks} label={''} arial={metricArial} />
                                </Grid>

                                <Grid container>
                                    <Grid container xs={12} sx={{ pt: '15px', fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                        {`Avg. ${metricArial} in four weeks?`}
                                    </Grid>
                                    <Grid container sx={{ maxWidth: '120px' }}>
                                        <RSInputAdornment value={runningActivity} setValue={setRunningActivity} label={metricLabel} arial={metricArial} />
                                    </Grid>
                                    <Grid container xs={12} sx={{ pt: '15px', fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                        What is your longest run?
                                    </Grid>
                                    <Grid container sx={{ maxWidth: '120px' }}>
                                        <RSInputAdornment value={longestRecentRun} setValue={setLongestRecentRun} label={metricLabel} arial={metricArial} />
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} sx={{ pt: '10px', fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                    {`Maximum distance in one week?`}
                                </Grid>
                                <Grid container sx={{ maxWidth: '120px' }}>
                                    <RSInputAdornment value={maxDistance} setValue={setMaxDistance} label={metricLabel} arial={metricArial} />
                                </Grid>
                                <Grid container xs={12} sx={{ pt: 2, fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                    What do you prefer for your long run?
                                </Grid>
                                <Grid container xs={12} sx={{ maxWidth: '210px', pt: 1 }}>
                                    {DAYS_OF_WEEK.map((day: string, index: number) => {
                                        const isActive = longRunDay === day
                                        return <Box key={index + day + '-lr'} sx={{ p: '0px 1px', height: '50px', width: '45px' }}>
                                            <Button onClick={() => setLongRunDay(day)} sx={{ backgroundColor: `${isActive ? '#bfd38c' : '#f2f2f8'} !important`, position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', border: `1px solid ${isActive ? '#9FCD2B' : '#cdcdcd'}`, minWidth: '100%', color: '#010101' }}
                                            >
                                                <Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light', fontSize: '14px', color: '#010101' }}>{day.slice(0, 2)}</Box>
                                            </Button>
                                        </Box>
                                    })}
                                </Grid>

                                <Grid container xs={12} sx={{ pt: 2, fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                    {`Select all days you're able to run`}
                                </Grid>
                                <Grid container xs={12} sx={{ pt: 1 }}>
                                    {daysOfWeek?.map((day: string, index: number) => {
                                        const slicedDay = day.slice(0, 3)
                                        const isActive = availableDays?.includes(day)
                                        return <Grid container key={index + day} xs={12 / daysOfWeek.length} sx={{ p: '0px 1px', height: '50px', maxWidth: '60px !important' }} justifyContent='center' alignItems='center'>
                                            <Button onClick={() => isActive ? setAvailableDays(availableDays.filter((ad: string) => ad !== day)) : setAvailableDays([...availableDays, day])}
                                                sx={{ backgroundColor: `${isActive ? '#bfd38c' : '#f2f2f8'} !important`, position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', border: `1px solid ${isActive ? '#9FCD2B' : '#cdcdcd'}`, minWidth: '100%', color: '#010101', px: 0 }}
                                            >
                                                <Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light', fontSize: '14px', color: '#010101' }}>{slicedDay}</Box>
                                            </Button>
                                        </Grid>
                                    })}
                                </Grid>

                                <Grid container xs={12} sx={{ pt: 2, fontFamily: 'Poppins-Medium', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
                                    {`Select Run Days Per Week`}
                                </Grid>
                                <Grid container xs={12} sx={{ pt: 1 }}>
                                    {runDaysPerWeekSelection.map((days, index: number) => {
                                        const isActive = runDaysPerWeek === days
                                        return <Box key={index + '-lr'} sx={{ p: '0px 1px', height: '50px', width: '50px', maxWidth: '60px !important' }}>
                                            <Button onClick={() => setRunDaysPerWeek(days)} sx={{ backgroundColor: `${isActive ? '#bfd38c' : '#f2f2f8'} !important`, position: 'relative', height: '100%', display: 'flex', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', border: `1px solid ${isActive ? '#9FCD2B' : '#cdcdcd'}`, minWidth: '100%', color: '#010101' }}
                                            >
                                                <Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Light', fontSize: '14px', color: '#010101' }}>{days}</Box>
                                            </Button>
                                        </Box>
                                    })}
                                </Grid>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                            <Grid container spacing={1}>
                                <Grid xs={8} md={8} item>
                                    <InputLabel className='mt-2' id="demo-multiple-name-label">Select GPT Model</InputLabel>
                                    <Select className='w-100' placeholder='Select Program' value={selectedGPTModel} onChange={(e) => setSelectedGPTModel(e.target.value)} >
                                        {gptModels.map((gpt, index) => (<MenuItem key={index} value={gpt}>{gpt}</MenuItem>))}
                                    </Select>
                                </Grid>
                                <Grid xs={4} md={4} item>
                                    <InputLabel className='mt-2' id="demo-multiple-name-label">Temperature</InputLabel>
                                    <Grid container sx={{ maxWidth: '120px' }}>
                                        <RSInputAdornment value={gptTemp} setValue={setGPTTemp} label={''} arial='' />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={12} md={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            Token Usage:<span style={{ marginLeft: '5px' }}><b>{getTotalTokens(history.filter(x => x.role === "output"))}</b></span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {history.length > 0 ? (
                                                <Table responsive striped bordered hover size="sm">
                                                    <thead>
                                                        <tr>
                                                            <th>Role</th>
                                                            <th>Output</th>
                                                            <th>Input Tokens</th>
                                                            <th>Output Tokens</th>
                                                            <th>Total Tokens</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {history.filter(x => x.role === "output").map((msg, i) => (
                                                            <tr key={i}>
                                                                <td>{msg.role}</td>
                                                                <td style={{ maxWidth: '300px' }} >{msg.content}</td>
                                                                <td>{msg.input_token}</td>
                                                                <td>{msg.output_token}</td>
                                                                <td>{msg.total_tokens}</td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td colSpan={3}></td>
                                                            <td><b>Total</b></td>
                                                            <td><b>{getTotalTokens(history.filter(x => x.role === "output"))}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            ) : undefined}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                            <Form.Label className='mt-3'>
                                {/* {currentStep < 0 ? 'User Input' : `Step ${currentStep} of ${inputPrompstValues.length}`} */}
                                User Input
                            </Form.Label>

                            <Form.Control
                                value={formText}
                                type="text"
                                as="textarea"
                                rows={18}
                                placeholder=""
                                onChange={(e) => setFormText(e.target.value)}
                                // onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
                                // onBlur={() => handleUpdateSale("subtext")}
                                disabled={isLoading}
                            />
                            <Grid container spacing={1}>
                                <Grid xs={3} item><RSButton disabled={isLoading} className='mt-2' onClick={handleReset}> <ReplayRounded />Reset </RSButton></Grid>
                                <Grid xs={3} item><RSButton disabled={isLoading} className='mt-2' onClick={handleBackStep}><ArrowLeft /> Back </RSButton></Grid>
                                <Grid xs={3} item><RSButton disabled={isLoading || !formText} className='mt-2' onClick={handleNextStep}> Next <ArrowRight /></RSButton></Grid>
                                {suggestedStartLongRun < 1 ? <Grid xs={3} item><RSButton disabled={isLoading || !availableDays.length} className='mt-2' onClick={handleSubmit}><SendRounded />  {'Suggested Long Run'} </RSButton></Grid>
                                    : <Grid xs={3} item><RSButton disabled={isLoading || !availableDays.length} className='mt-2' onClick={handleAIprompting}><SendRounded />  {!isSinglePrompt && currentStep > 0 ? 'Submit' : 'Submit'} </RSButton></Grid>}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <div className='half-md-card w-100 card mx-auto p-3 mt-2'>
                    {initLongRuns.length > 0 ? (
                        <>
                            <h5>Long Runs</h5>
                            <Table responsive striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        {initLongRuns.map((x: any, i: number) => (
                                            <th key={i}>w {x.w}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {initLongRuns.map((week: any, i: number) => (
                                            <td key={i}>{week.lr}</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    ) : undefined}

                    {initWeeklyMileage.length > 0 ? (
                        <>
                            <h5>Weekly Mileage</h5>
                            <Table responsive striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        {initWeeklyMileage.map((x: any, i: number) => (
                                            <th key={i}>w {x.w}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {initWeeklyMileage.map((week: any, i: number) => (
                                            <td key={i}>{week.wm}</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    ) : undefined}
                    {/* <div><pre>{JSON.stringify(results, null, 2)}</pre></div> */}
                    <Table responsive striped bordered hover size="sm">
                        <thead>
                            <tr>
                                {!dailyRunTable ? results?.length > 0 && Object.keys(results[0]).map((x: any, i: number) => (
                                    <th key={i}>{x.replaceAll('_', ' ')}</th>
                                ))
                                    : <>
                                        <th>Week</th>
                                        <th>Weekly Mileage</th>
                                        {results?.length > 0 && results[0].days.map((day: any, dI: number) => <>
                                            <th>{day.name}</th>
                                            {/* <th>Type</th> */}
                                        </>)}
                                    </>
                                }

                                {/* <th>Week</th>
                                <th>Long Run (mi)</th>
                                <th>Weekly Mileage</th>
                                <th>Taper</th>
                                <th>Recovery</th>
                                {results?.length > 0 && results[0]?.daily_runs?.map((x: any, i: number) => (
                                    <th key={i}>{x?.day}</th>
                                ))} */}
                            </tr>
                        </thead>
                        <tbody>
                            {!dailyRunTable ? results?.map((data: any, i: number) => (
                                <tr key={i}>
                                    {Object.values(data).map((x: any, i: number) => (
                                        <td key={i}>{String(x) == "false" ? '' : typeof x === "object" ? (
                                            <JSONTable noHeader={true} data={x} />
                                        ) : String(x)}</td>
                                    ))}
                                </tr>

                            ))
                                : results?.map((week: any, wI: number) =>
                                    <tr key={wI}>

                                        <td>{week.week}</td>
                                        <td>{week.weekly_mileage}</td>

                                        {week.days.map((day: any, dI: number) => <>
                                            <td>{day.long_run} {day.long_run != 0 && day.run_type ? '-' : ''} {day.long_run != 0 && day.run_type}</td>

                                        </>)}
                                    </tr>
                                )
                            }


                            {/* {results?.map((data: any, i: number) => (
                                <tr key={i}>
                                    <td>{data.week}</td>
                                    <td>{data.long_run}</td>
                                    <td style={{ minWidth: '160px' }}>{data.Weekly_mileage}</td>
                                    <td style={{ minWidth: '160px' }}>{data.is_taper ? 'YES' : ''}</td>
                                    <td style={{ minWidth: '160px' }}>{data.is_recovery ? 'YES' : ''}</td>
                                    {data?.daily_runs?.map((x: any, i: number) => (
                                        <td key={i}>{x?.run}</td>
                                    ))}
                                </tr>
                            ))} */}
                        </tbody>
                    </Table>
                </div>
                <Loader active={isLoading} />
            </Grid>
        </Grid>
    )
}

export default ChatCompletionSingle
