/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { toUpper } from 'lodash'
import moment from 'moment'

import { GetRaceEvents } from '../modules/raceActions'
import { SearchFiltersProps } from '../modules/types'
import { DEFAULT_RACE_TYPES } from '../modules/values'
import { FEATURED_RACES, isFeaturedActivated } from '../modules/featuredRaces'

export type TalliedRaceEventsProps = {
	name: any,
	date: any,
	datePerRaceType?: any,
	dateEnd?: any,
	place: string,
	logo: string,
	type: any,
	desc?: string,
	homepage?: string
	source?: string,
	isFeatured?: boolean,
	color?: string
}


const useSearchRaces = (filters: SearchFiltersProps | undefined, isKm: boolean | undefined) => {

	const [races, setRaces] = useState<Array<TalliedRaceEventsProps> | undefined>()
	const [isLoading, setIsLoading] = useState(false)
	const [page, setPage] = useState(1)
	const [isPaginatedLoading, setIsPaginatedLoading] = useState(false)
	const [isLastResultEmpty, setIsLastResultEmpty] = useState(false)


	const isTypeIncluded = (type: string) => {
		const { race_type: r_t } = filters || {}
		const race_type = r_t?.length ? r_t : DEFAULT_RACE_TYPES
		if (race_type)
			return type ? race_type?.findIndex((rt: any) => toUpper(type).includes(toUpper(rt))) > -1 : false
		else
			return true
	}

	const filterValidRaces = (races: TalliedRaceEventsProps[]) => {
		const defaultDate = filters?.start_date ? moment(filters.start_date?.replaceAll('-', '/')).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')
		return races.filter((rd) => !rd.name.toLowerCase().includes('test')).filter((rd) => !rd.name.toLowerCase().includes('demo')).filter((rd) => moment(defaultDate).isSameOrBefore(rd.date)).sort((a, b) => moment(a.date).isAfter(b.date) && !a.isFeatured ? 1 : -1)
	}

	const filterFeaturedRaces = () => {
		const defaultSDate = filters?.start_date ? moment(filters.start_date?.replaceAll('-', '/')).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')
		const defaultEDate = filters?.end_date ? moment(filters.end_date?.replaceAll('-', '/')).format('YYYY/MM/DD') : ''
		return isFeaturedActivated ? FEATURED_RACES.filter((fr: any) => moment(fr.date).isSameOrAfter(defaultSDate) && (defaultEDate ? moment(fr.date).isSameOrBefore(defaultEDate) : true)
			&& (filters?.race_type?.length ? filters.race_type.find((rt: any) => fr.type.includes(rt.toUpperCase())) : true)) : []
	}

	const elevateSuggestedEvents = (races: any[] | undefined) => {

		const suggestedRaces: any[] = []
		races?.map((re, reI) => {
			if (re.name.toLowerCase().includes(filters?.event_name?.toLowerCase())) {
				const suggestedEvent = races.splice(reI, 1)[0];
				suggestedRaces.push(suggestedEvent)
			}
		})
		races && suggestedRaces.length > 0 && races.unshift(...suggestedRaces)
		setRaces(races)

	}

	const formatDateTime = (dateTimeString: string) => {

		const formattedDateTime = new Date(dateTimeString).toLocaleString('en-US', {
			// weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			// hour: 'numeric',
			// minute: 'numeric',
			// second: 'numeric',
			// hour12: true,
		});

		return formattedDateTime
	}

	const marathonType = (type: string) => {

		type = toUpper(type)

		if (type.includes('RELAY')) return 'RELAY'
		else if (type.includes('OBSTACLE')) return 'OBS'
		else if (type.includes('0.5K')) return 'CUSTOM'
		else if (type.includes('5K') || (type.includes('3.1') && !type.includes('13'))) return '5K'
		else if (type.includes('8K') || type.toLowerCase().includes('5 miles')) return '8K'
		else if (type.includes('10K') || (type.includes('6.2') && !type.includes('26'))) return '10K'
		else if (type.includes('HALF') || type.includes('13.1')) return 'HALF'
		else if (type.includes('42K') || type.includes('26.2')) return 'FULL'
		else if (type.includes('10 mile') || type.includes('16')) return '10 mile'

		return 'CUSTOM'
	}

	const getTypes = (arr: Array<any> | undefined) => {
		const raceEventDistanceTypes: any[] = []
		let shouldPush = false
		arr?.map((re: any) => {
			const val = re?.name || re?.attribute?.attributeValue
			const distance = re?.distance
			const eventType = re?.event_type

			if (val || distance) {
				const nType = marathonType(val)
				if (!raceEventDistanceTypes.includes(nType) && nType !== ('CUSTOM' || 'RELAY')) {
					if (isTypeIncluded(nType)) {
						shouldPush = true
						raceEventDistanceTypes.push(nType)
					}
				} else if (distance) {
					const dType = marathonType(distance)
					const eType = marathonType(eventType)
					if (!raceEventDistanceTypes.includes(dType) && dType !== 'CUSTOM' && (nType !== 'RELAY' || 'OBS') && eType !== 'OBS' && isTypeIncluded(dType)) {
						shouldPush = true
						raceEventDistanceTypes.push(dType)
					}
				}
			}
		})
		return { raceEventDistanceTypes, shouldPush }
	}

	const filterOutDuplicate = (raceList: Array<TalliedRaceEventsProps>) => {
		const latestEvent = raceList[raceList.length - 1]
		const prevEvent = raceList[raceList.length - 2]

		if (latestEvent?.date === prevEvent?.date && latestEvent?.place === prevEvent?.place && latestEvent?.logo === prevEvent?.logo) {
			raceList.pop()
		}
	}

	const getName = (name: string) => {
		const nameCheck = filters?.event_name ? name.toLowerCase().includes(filters.event_name.toLowerCase()) : true
		return { nameCheck }
	}

	const getRaces = async (page_display: number) => {
		let raceList: Array<TalliedRaceEventsProps> = [...filterFeaturedRaces()]
		setIsLoading(page_display == 1 && true)
		setIsPaginatedLoading(page_display && page_display > 1 && true || false)
		const newFilters = {
			...filters,
			...{ race_type: filters?.race_type?.length ? filters.race_type : DEFAULT_RACE_TYPES },
			...{ start_date: filters?.start_date || moment().format('YYYY-MM-DD') },
			isKm: isKm ? true : false
		}

		if (!isLoading) {
			setIsLoading(true)
			await GetRaceEvents(newFilters || {}, page_display).then((res) => {
				const runSignUpData = res?.data?.[0]?.races
				const activeAccessData = res?.data?.[1]?.results

				// console.log("RS: ", res?.data?.[0]?.races)
				// console.log("AA: ", res?.data?.[1]?.results)

				//Run Sign up loop
				runSignUpData?.map((rd: any) => {

					const { raceEventDistanceTypes, shouldPush } = getTypes(rd.race?.events)
					const { nameCheck } = getName(rd.race?.name)
					shouldPush && raceEventDistanceTypes.length && nameCheck && raceList.push({
						name: rd.race?.name,
						date: formatDateTime(rd.race?.next_date || rd.race.events[0]?.start_time),
						datePerRaceType: getStartDatePerType(rd.race?.events),
						dateEnd: rd.race?.next_end_date || rd.race.events[0]?.end_time,
						place: rd.race?.address.city + ', ' + rd.race?.address?.state + ', ' + rd.race?.address?.country_code,
						// place: rd.race?.address.city + ', ' + rd.race?.address?.street,
						logo: rd.race?.logo_url,
						type: raceEventDistanceTypes,
						desc: rd.race?.description,
						homepage: rd.race?.url,
						source: 'RunSignUp'
					})

				})

				//Active Access up loop
				activeAccessData?.map((rd: any) => {

					const { raceEventDistanceTypes, shouldPush } = getTypes(rd.assetAttributes)
					const { nameCheck } = getName(rd.assetName)

					let raceCheck = true
					if (rd.assetDescriptions[0]?.description.toLowerCase().includes('obstacle')) raceCheck = false
					shouldPush && raceEventDistanceTypes.length && nameCheck && raceCheck && raceList.push({
						name: rd.assetName,
						date: formatDateTime(rd.activityStartDate),
						dateEnd: rd.activityEndDate,
						place: rd.place?.cityName + ', ' + rd.place?.stateProvinceCode + ', ' + rd.place?.countryCode,
						// place: rd.place.cityName + ', ' + rd.place.placeName,
						logo: rd.logoUrlAdr,
						type: raceEventDistanceTypes,
						desc: rd.assetDescriptions[0].description,
						homepage: rd.homePageUrlAdr,
						source: 'Active.com'
					})
				})

				setIsLoading(false)
				// console.log('raceList', raceList)
			}).catch((err) => {
				console.log(err)
				setIsLoading(false)
			})
		}
		raceList = raceList?.filter((rl: any, rlI: number) => {
			return !raceList.find((rf: any, rfI: number) => rlI > rfI && rl?.name === rf?.name && rl?.date === rf?.date && rl?.place === rf?.place && rl?.logo === rf?.logo && rlI !== rfI)
		})
		// console.log('raceList', raceList)
		raceList.length === 0 ? setIsLastResultEmpty(true) : setIsLastResultEmpty(false)
		races && races.length && page_display && page_display > 1 ? setRaces(filterValidRaces([...races, ...raceList])) : setRaces(filterValidRaces(raceList))

		// setIsLoading(false)
		setIsPaginatedLoading(false)
	}

	const getStartDatePerType = (events: any) => {

		const startDates: any[] = []
		events?.map((event: any) => {
			const type = marathonType(event.distance)
			if (type !== 'CUSTOM' && type !== 'RELAY' && type !== 'OBS') {
				const startDate = formatDateTime(event?.start_time)
				if (!startDates.find((sd: any) => sd.type === type)) startDates.push({ type, date: startDate })
			}
		})
		return startDates || []
	}

	useEffect(() => {
		if (page > 1)
			getRaces(page)
	}, [page])

	useEffect(() => {
		if (filters && Object.keys(filters)?.length) {
			setPage(1)
			getRaces(1)
		}
		else
			setRaces(undefined)
	}, [filters])

	return { isLoading, races, isLastResultEmpty, isPaginatedLoading, page, setPage }
}

export default useSearchRaces