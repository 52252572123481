import {FC} from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import RSDateInput from './RSDateInput'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

export type RSDateRangeProps = {
	startDate: string,
	startLabel?: string,
	setStartDate?: any,
	startMaxDate?: any,
	startMinDate?: any,
	startDay?: boolean
	startMonth?: boolean,
	startDisableDates?: any,
	endDate: string,
	endLabel?: string,
	setEndDate?: any,
	endMaxDate?: any,
	endMinDate?: any,
	endDay?: boolean
	endMonth?: boolean,
	startOutlineSX?: object,
	endOutlineSX?: object,
	endDisableDates?: any,
	isWeekSelector?: boolean,
	isDayRangeSelector?: boolean,
	startCalendarLabel?: string,
	endCalendarLabel?: string
}

const RSDateRange: FC<RSDateRangeProps> = ({startDate = '', endDate = '', startLabel = 'Start Date', endLabel = 'End Date', setStartDate, setEndDate, startMinDate, startMaxDate, endMinDate, endMaxDate, startDay, startMonth, endDay, endMonth, startOutlineSX, endOutlineSX, startDisableDates, endDisableDates, isWeekSelector, isDayRangeSelector, startCalendarLabel = '', endCalendarLabel = ''}) => {
	const isMobile = useIsMobileScreen();
	return (
		<Grid container xs={12} alignItems='center'>
			<Box sx={{width: isMobile ? '140px' : '150px', pt: '10px'}}>
				{startLabel && <Box sx={{fontSize: '14px', fontFamily: 'Poppins-Medium', lineHeight: '1.2em', height: '12px', color: setStartDate ? '#010101' : 'gray'}}>{startLabel}</Box>}
				<RSDateInput icon isWeekSelector={isWeekSelector} isDayRangeSelector={isDayRangeSelector} disableDates={startDisableDates}  date={startDate} setDate={setStartDate} minDate={startMinDate} maxDate={startMaxDate} disabled={setStartDate ? false : true} day={startDay} month={startMonth} outlineSX={startOutlineSX} calendarLabel={startCalendarLabel} otherDate={endDate}/>
			</Box>
			<Box sx={{height: '2px', width: '10px', backgroundColor: 'gray', mx: 2, mt: '28px'}}/>
			<Box sx={{width: isMobile ? '140px' : '150px', pt: '10px'}}>
				{endLabel && <Box sx={{fontSize: '14px', fontFamily: 'Poppins-Medium', lineHeight: '1.2em', height: '12px', color: setEndDate ? '#010101' : 'gray'}}>{endLabel}</Box>}
				<RSDateInput end icon isWeekSelector={isWeekSelector} isDayRangeSelector={isDayRangeSelector} disableDates={endDisableDates} date={endDate} setDate={setEndDate} minDate={endMinDate} maxDate={endMaxDate} disabled={setEndDate ? false : true} day={endDay} month={endMonth} outlineSX={endOutlineSX} calendarLabel={endCalendarLabel} otherDate={startDate}/>
			</Box>
		</Grid>
	)
}

export default RSDateRange;
