/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useEffect } from 'react';
import Lottie from 'lottie-react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import RunningLoader from '../../components/animation/runsmart_loader.json'
import RSInputAdornment2 from '../../components/input/RSInputAdornment2';
import RSDrawer from '../../components/layout/RSDrawer';
import { RSButton1 } from '../../components/buttons';
import { SH2, P1 } from '../../components/text/RSTypography'
import { AccountProfile } from '../../api/v2/account/Account.types'
import useRSRunAIsuggestions from '../../hooks/useRSRunAIsuggestions';

export type WeeklyRunningScheduleProps = {
	accountProfile: AccountProfile,
	addDetails?: any
	allStageDetails?: any,
	handleIsStepComplete?: any,
	updatedBaseDetails?: any,
	setUpdatedBaseDetails?: any,
	setMainLoading: any,
	handleNext?: () => void
}

const WeeklyRunningSchedule: FC<WeeklyRunningScheduleProps> = ({ accountProfile, addDetails, allStageDetails, handleIsStepComplete, updatedBaseDetails, setUpdatedBaseDetails, setMainLoading, handleNext }) => {
	const stageNumber = '2'
	const defaultStopReason = 'It is not recommended to continue with the plan.'
	const { maxRunPerWeek, averageRunPerWeek, maxRunPerWeekRange, averageRunPerWeekRange } = allStageDetails?.[stageNumber] || {}
	const { measurement_system } = accountProfile
	const [maxDistance, setMaxDistance] = useState<any>('');
	const [finalMaxDistanceRange, setFinalMaxDistanceRange] = useState<any>(maxRunPerWeekRange || '');
	const [averageDistanceRange, setAverageDistanceRange] = useState<any>(averageRunPerWeekRange || '');
	const [averageDistance, setAverageDistance] = useState<any>(averageRunPerWeek)
	const [finalMaxDistance, setFinalMaxDistance] = useState<any>(maxRunPerWeek)
	const [enterManually, setEnterManually] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [stopTraining, setStopTraining] = useState<boolean>(true)
	const [stopReason, setStopReason] = useState<string>('')
	const [peakMaxAllowed, setPeakMaxAllowed] = useState<number>(60)
	const [isManualInput, setIsManualInput] = useState(false)
	const isMetric = measurement_system === 'metric' ? true : false
	const metricArial = isMetric ? 'Kilometers' : 'Miles'

	const { getSuggestedWeeklyMileage, checkPlanEligibility } = useRSRunAIsuggestions()
	const isMaintenancePlan = allStageDetails['1-0']?.raceType?.toLowerCase() === 'maintenance' ? true : false
	const maintenanceType = allStageDetails["0"]?.level == 1 ? 'maintain' : 'build'
	const aD = isManualInput ? averageDistance : averageDistanceRange || 0
	const fD = isManualInput ? finalMaxDistance : finalMaxDistanceRange || 0

	const isSuggested = `${finalMaxDistanceRange} ${averageDistanceRange}`.includes('-')

	const getWeeklyMileageSuggestion = async () => {
		setIsLoading(true)
		setIsManualInput(false)
		let weeklyMileage = undefined
		if (isMaintenancePlan && maintenanceType === "maintain") {
			const mileageLow = Number(allStageDetails['1-0']?.currentWeeklyMileage) - 2
			const mileageHigh = Number(allStageDetails['1-0']?.currentWeeklyMileage) + 2
			weeklyMileage = { average: `${mileageLow} - ${mileageHigh}`, peak: `${mileageLow} - ${mileageHigh}` }
		}
		else
			weeklyMileage = await getSuggestedWeeklyMileage(allStageDetails)

		const [peakMin, peakMax] = weeklyMileage.peak && ((weeklyMileage.peak.toString().split('-').map(Number)) || weeklyMileage.peak)
		const longestRunDistancePreferred = Number((peakMin + peakMax) / 2 || 22)

		const [aveMin, aveMax] = weeklyMileage.average && ((weeklyMileage.average.toString().split('-').map(Number)) || weeklyMileage.average)
		const averageRunDistancePreferred = Number((aveMin + aveMax) / 2 || 16)

		setPeakMaxAllowed(peakMax ? peakMax : peakMin ? peakMin : !isNaN(weeklyMileage.peak) ? Number(weeklyMileage.peak) : 60)


		if (allStageDetails['2']?.maxRunPerWeek && !updatedBaseDetails) {
			// console.log('>>> existing max range', allStageDetails['2']?.maxRunPerWeek)
			console.log('maxRunPerWeekRange', allStageDetails['2']?.maxRunPerWeekRange)
			setFinalMaxDistance(allStageDetails['2']?.maxRunPerWeek || longestRunDistancePreferred)
			setFinalMaxDistanceRange(allStageDetails['2']?.maxRunPerWeekRange || weeklyMileage?.peak || longestRunDistancePreferred)
		}
		else {
			setFinalMaxDistance(longestRunDistancePreferred)
			setFinalMaxDistanceRange(weeklyMileage?.peak || longestRunDistancePreferred)
		}
		if (allStageDetails['2']?.averageRunPerWeek && !updatedBaseDetails) {
			setAverageDistance(allStageDetails['2']?.averageRunPerWeek || averageRunDistancePreferred)
			setAverageDistanceRange(allStageDetails['2']?.averageRunPerWeekRange || weeklyMileage?.average || averageRunDistancePreferred)
		}
		else {
			setAverageDistance(averageRunDistancePreferred)
			setAverageDistanceRange(weeklyMileage?.average || averageRunDistancePreferred)
		}


		// setFinalMaxDistance(longestRunDistancePreferred)
		// setFinalMaxDistanceRange(weeklyMileage?.peak || longestRunDistancePreferred)
		// setAverageDistance(averageRunDistancePreferred)
		// setAverageDistanceRange(weeklyMileage?.average || averageRunDistancePreferred)
		// setAverageDistance(18)
		// setFinalMaxDistance(25)
		// setAverageDistanceRange(18)
		// setFinalMaxDistanceRange(25)

		if (isMaintenancePlan && maintenanceType === "maintain" && handleNext) {
			setTimeout(() => {
				setIsLoading(false)
				handleNext()
			}, 3000)
		}
		else
			setIsLoading(false)

	}

	const resumeTrainingPlan = async () => {

		setIsLoading(true)
		const shouldContinue = await checkPlanEligibility(allStageDetails)

		if (shouldContinue?.ready.toLowerCase().includes('no')) {
			setStopReason(shouldContinue?.reason || defaultStopReason)
			setStopTraining(true)
			setIsLoading(false)
		}
		else {
			setStopTraining(false)
			getWeeklyMileageSuggestion()
		}

	}

	useEffect(() => {
		// (finalMaxDistanceRange || averageDistanceRange) ? setIsLoading(false) : getWeeklyMileageSuggestion()
		resumeTrainingPlan()
	}, [])

	const renderWeeklyPlotting = () => {

		if (finalMaxDistanceRange == '' || averageDistanceRange == '') return <></>

		return <Box sx={{ px: 3 }}>
			<SH2 size={3} sx={{ pb: '10px', width: '100%' }}>
				{`Here's what we recommend`}
			</SH2>
			<P1 sx={{  width: '100%' }}>
				Based on your recent running history and preferences, we suggest the following guidance for your plan.
			</P1>
			<Grid container xs={12} sx={{ pt: 3, fontFamily: 'Poppins-Bold', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
				{`Average weekly mileage`}
			</Grid>
			<Grid container xs={12} sx={{ pt: '10px', fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1.2em' }} alignItems='flex-end'>
				{aD} {metricArial}
			</Grid>
			<Grid container xs={12} sx={{ pt: 3, fontFamily: 'Poppins-Bold', fontSize: '14px', lineHeight: '1em' }} alignItems='flex-end'>
				{`Peak weekly mileage`}
			</Grid>
			<Grid container xs={12} sx={{ pt: '10px' }} alignItems='flex-end'>
				<Box sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1.2em' }}>
					{fD} {metricArial}
				</Box>
				<Box className='pointer blue-text' onClick={() => (isSuggested && !isManualInput) ? setEnterManually(true) : getWeeklyMileageSuggestion()} sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1.2em', textDecoration: 'underline', pl: '10px' }}>
					{(isSuggested && !isManualInput) ? <>
						{`Adjust`}
						{/* <i className="fa-light fa-pen-to-square" /> */}
					</> : 'Use suggested'}
				</Box>
			</Grid>
			<RSDrawer
				bottom
				noClose
				fitContent
				popUpWidth='400px'
				open={enterManually}
				onClose={() => setEnterManually(false)}
			>
				<Box sx={{ p: 3 }}>
					<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Bold' }}>Enter Peak Training Manually</Box>
					<Grid container xs={12} sx={{ pt: '10px', fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1.2em' }} alignItems='flex-end'>
						{`What is the maximum number of miles you are comfortable running at your training peak?`}
					</Grid>
					<Grid container sx={{ pt: 2 }}>
						<RSInputAdornment2 sx={{ maxWidth: '80px' }} value={maxDistance < peakMaxAllowed ? maxDistance : peakMaxAllowed} setValue={setMaxDistance} label={metricArial} />
					</Grid>
					<Grid container xs={12} sx={{ pt: 3 }}>
						<RSButton1 disabled={!maxDistance || maxDistance === finalMaxDistanceRange} sx={{ maxWidth: '375px' }}
							onClick={() => {
								const cappedMax = maxDistance > peakMaxAllowed ? peakMaxAllowed : maxDistance
								const ave: any = Number(cappedMax * 0.7) || 0

								setIsManualInput(true)

								setFinalMaxDistance(Number(cappedMax))
								setAverageDistance(Math.round(ave))

								// setFinalMaxDistanceRange(Number(maxDistance))
								// setAverageDistanceRange(Math.round(ave))

								setMaxDistance('')
								setEnterManually(false)
							}}
						>
							Update
						</RSButton1>
					</Grid>
				</Box>
			</RSDrawer>
		</Box>
	}

	const renderDeclineTraining = () => {

		return <Box sx={{ px: 3 }}>
			<Grid container xs={12} sx={{ pb: '10px', fontFamily: 'Poppins-Medium', fontSize: '20px', lineHeight: '1em' }} alignItems='flex-end'>
				{`Hmmm...\nYou might not be ready to train for this race yet.`}
			</Grid>
			<Grid container xs={12} sx={{ pt: 3, fontFamily: 'Poppins-Bold', fontSize: '15px', lineHeight: '1em' }} alignItems='flex-end'>
				{`We recommend to adjust your plan to continue or try a different race instead.`}
			</Grid>
			<Grid container xs={12} sx={{ pt: 2, fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1.3em' }} alignItems='flex-end'>
				{stopReason}
			</Grid>
		</Box>
	}

	useEffect(() => {
		setMainLoading(isLoading)
		return () => setMainLoading(false)
	}, [isLoading])


	useEffect(() => {

		const maxLimitWeeklyRun = !isNaN(Number(finalMaxDistanceRange)) && finalMaxDistance < Number(finalMaxDistanceRange) ? Number(finalMaxDistanceRange) : finalMaxDistance

		addDetails(stageNumber, {
			maxRunPerWeek: maxLimitWeeklyRun ? maxLimitWeeklyRun : finalMaxDistance || 0,
			averageRunPerWeek: averageDistance || 0,
			maxRunPerWeekRange: finalMaxDistanceRange,
			averageRunPerWeekRange: averageDistanceRange || 0
		})

		handleIsStepComplete(!stopTraining && finalMaxDistanceRange ? true : false, stageNumber)

	}, [finalMaxDistanceRange, finalMaxDistance, averageDistanceRange, averageDistance, stopTraining])


	useEffect(() => {

		if (maxDistance == '') return
		setUpdatedBaseDetails(true)
		// console.log('updated some details')

	}, [maxDistance])


	useEffect(() => {
		if (finalMaxDistance == '') return
		if (finalMaxDistance > peakMaxAllowed) {
			setFinalMaxDistance(peakMaxAllowed)
		}
	}, [finalMaxDistance])


	return isLoading || (isMaintenancePlan && maintenanceType === "maintain") ? <Grid container xs={12} sx={{ height: '100%', flexDirection: 'column', px: 3 }} justifyContent='center' alignItems='center'>
		<Box sx={{ height: '80px', width: '80px' }}>
			<Lottie animationData={RunningLoader} />
		</Box>
		<Box sx={{ fontFamily: 'Poppins', fontSize: '14px' }}>
			{isMaintenancePlan && maintenanceType === "maintain" ?
				"Checking details and updating your plan."
				: "Loading plan recommendations..."}
		</Box>
	</Grid>
		: !stopTraining || stopReason == '' ? renderWeeklyPlotting() : renderDeclineTraining()
}

export default WeeklyRunningSchedule;
