import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import classNames from 'classnames'
import { FC, useState, useEffect, ReactElement } from 'react'
import moment from 'moment'
import {RSButton1} from "./index"

// Props
export type AuthSubmitButtonsProps = {
	primaryText: string | ReactElement
	secondaryText?: string
	primaryAction: () => any
	secondaryAction?: () => any
	extraText?: string
	extraAction?: () => any
	primaryDisabled?: boolean
	secondaryDisabled?: boolean,
	isAccount?: boolean,
	isSecondary?: boolean,
	icon?: ReactElement
	isPay?: boolean,
	addClassName?: string,
	extraOnly?: boolean,
	newButton?: boolean
	isRenew?: boolean
	nextBillingDate?: Date,
	sx?: object
}

// Primary Button Component
export const PrimaryButton: FC<AuthSubmitButtonsProps> = ({
	primaryText,
	primaryAction,
	primaryDisabled,
	isAccount,
	isSecondary,
	icon,
	isPay,
	addClassName,
	sx = {}
}) => {
	// State
	const [isDisabled, setIsDisabled] = useState(primaryDisabled)

	// On Action
	const onAction = async () => {
		if (primaryAction) {
			setIsDisabled(true)
			await primaryAction()
			setIsDisabled(false)
		}
	}

	// Disabled Change Effect
	useEffect(() => {
		setIsDisabled(primaryDisabled)
	}, [primaryDisabled])

	const renderButton = () => {
		return <RSButton1
			disabled={isDisabled}
			secondary={isSecondary ? true : false}
			className={classNames("fixed-md-btn", addClassName, isAccount && primaryText !== "Save" ? 'account-primary-button' : isAccount && primaryText === "Save" ? 'cancel-plan-btn' : isSecondary ? 'account-secondary-button' : isPay ? 'rounded-pill--pay' : 'rounded-pill', isDisabled && isAccount || isDisabled && isSecondary ? '' : '')}
			onClick={onAction}
			sx={{ px: 2, ...sx }}
		>
			{icon ? (
				<span className={`button-icon ${isPay ? 'pay' : ''}`}>
					{primaryText === "Subscribe with" || primaryText === "Pay with" || primaryText === "Renew with" || primaryText === "Update with" || primaryText === "Continue with" || primaryText === "Start Trial with" ? (
						<>
							<span className='text-val'>{primaryText} {icon} Pay</span>
						</>
					) : <span className="button-icon">{icon}
						{primaryText}
					</span>}
				</span>
			) : primaryText}

		</RSButton1>
	}

	// Render
	return Object.keys(sx)?.length ? renderButton() : (<span className={classNames("mt-2 d-inline-block", icon ? 'mb-0' : 'mb-4')}>
		{renderButton()}
	</span>
	)
}

// Secondary Button Component
const SecondaryButton: FC<
	Partial<AuthSubmitButtonsProps> & { isMobile?: boolean }
> = ({ secondaryText, secondaryAction, secondaryDisabled, isMobile, isAccount, addClassName }) => {
	const hasSecondary = secondaryText && secondaryAction ? true : false
	const className = classNames(
		'mb-4',
		isAccount ? '' : undefined,
		isMobile
			? 'd-inline-block'
			: 'd-none d-md-inline-block account-secondary-margin'
	)
	return (
		<>
			{hasSecondary && (
				<span className={className}>
					<RSButton1
						tertiary
						className={classNames("s-btn", "fixed-md-btn", "rounded-pill", addClassName, secondaryText === "Cancel Plan" || secondaryText === "Cancel" ? 'cancel-plan-btn' : '')}
						onClick={secondaryAction}
						disabled={secondaryDisabled}
					>
						{secondaryText}
					</RSButton1>
				</span>
			)}
		</>
	)
}

// Extra Button Component
const ExtraButton: FC<Partial<AuthSubmitButtonsProps>> = ({
	extraText,
	extraAction,
}) => {
	const hasExtra = extraText && extraAction ? true : false
	const className = classNames('mb-4', 'd-block')
	return (
		<>
			{hasExtra && (
				<span className={className}>
					<Button
						variant="white"
						className="extra-button"
						onClick={extraAction}
					>
						{extraText}
					</Button>
				</span>
			)}
		</>
	)
}

/**
 * AuthSubmitButtons Component
 */

const AuthSubmitButtons: FC<AuthSubmitButtonsProps> = ({
	primaryText,
	primaryAction,
	primaryDisabled,
	secondaryText,
	secondaryAction,
	secondaryDisabled,
	extraText,
	extraAction,
	isAccount,
	addClassName,
	extraOnly,
	newButton,
	isRenew,
	nextBillingDate
}) => {
	// Render
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Row className='text-center' xs={12} >
				{/* {
					extraText && extraAction && (<Col xs={3} style={{ textAlign: 'right' }}><h6 className='form-label extra-label'>Passoword</h6></Col>)
				} */}
				<Col xs={12}><ExtraButton extraText={extraText} extraAction={extraAction} /></Col>
				{/* <SecondaryButton
					isMobile
					secondaryText={secondaryText}
					secondaryAction={secondaryAction}
					secondaryDisabled={secondaryDisabled}
				/> */}
			</Row>
			<Row className="d-flex justify-content-center text-center" style={{ maxWidth: '450px', width: '100%' }}>
				{!extraOnly ? (
					<>
						{primaryText === "Save Password" || primaryText === "Confirm Change" || primaryText === "Confirm Renew" ? (
							<>
								<Row xs={12}>
									<PrimaryButton
										primaryText={primaryText}
										primaryAction={primaryAction}
										primaryDisabled={primaryDisabled}
										isAccount={isAccount}
										addClassName={addClassName}
									/>
									<SecondaryButton
										secondaryText={secondaryText}
										secondaryAction={secondaryAction}
										secondaryDisabled={secondaryDisabled}
										addClassName={addClassName}
									/>
								</Row>
								{primaryText === "Confirm Change" || primaryText === "Confirm Renew" ? (
									<SecondaryButton
										isAccount
										isMobile
										secondaryText={secondaryText}
										secondaryAction={secondaryAction}
										secondaryDisabled={secondaryDisabled}
										addClassName={addClassName}
									/>) : undefined}
							</>
						)
							: <>
								{/* <SecondaryButton
									secondaryText={secondaryText}
									secondaryAction={secondaryAction}
									secondaryDisabled={secondaryDisabled}
									addClassName={addClassName}
								/> */}
								{newButton ? (
									<div className='checkout'>
										<RSButton1
											onClick={primaryAction}
											disabled={primaryDisabled}
											sx={{ my: 4 }}>
											{primaryText}
										</RSButton1>
									</div>
								) : (
									<Row xs={12}>
										<PrimaryButton
											primaryText={primaryText}
											primaryAction={primaryAction}
											primaryDisabled={primaryDisabled}
											isAccount={isAccount}
											isSecondary={true}
											addClassName={addClassName}
										/>
									</Row>
								)}

								{!isRenew && newButton ? (
									<div>
										<h5 className='mt-3' style={{ color: '#F01', textAlign: 'center' }}>You will not be charged until {moment(nextBillingDate).format('MMMM D, YYYY')}
										</h5>
									</div>
								) : undefined}

								{/* Cancel Subscription */}
								<SecondaryButton
									isAccount
									isMobile
									secondaryText={secondaryText}
									secondaryAction={secondaryAction}
									secondaryDisabled={secondaryDisabled}
									addClassName={addClassName}
								/>
							</>
						}
					</>
				) : undefined}

			</Row>
		</div>
	)
}

export default AuthSubmitButtons
