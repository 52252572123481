import {FC, useState, useEffect} from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import RSInput from '../../components/input/RSInput'
import {RSButton1} from '../../components/buttons';
import { SH1 } from '../../components/text/RSTypography'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type NutritionProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
}

const Nutrition: FC<NutritionProps> = ({data, handleUpdateProfile}) => {
	const {dietary_preferences} = data
	const [dp, setDP] = useState<any>(dietary_preferences || '');

	const handleSave = async () => {
		handleUpdateProfile({ dietary_preferences: dp })
	}

	useEffect(()=>{
		setDP(dietary_preferences)
	},[dietary_preferences])

	return (
		<Box>
			<SH1 size={1} sx={{width: '100%', mb: 1}}>Do you have any specific dietary preferences?</SH1>
			<Grid container xs={12}>
				<RSInput value={dp} setValue={setDP} title={'gluten-free, vegan, etc'}/>
			</Grid>
			<RSButton1 onClick={handleSave} disabled={dietary_preferences == dp} sx={{mt: 3}}>Save</RSButton1>
		</Box>
	)
}

export default Nutrition;
