/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import { date, strtotime } from 'locutus/php/datetime';
import moment from 'moment'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import DArrowForward from '@mui/icons-material/KeyboardDoubleArrowRight';

import MeasurementSystem from '../../pages/profile/MeasurementSystem'
import Location from '../../pages/profile/Location'
import Loader from '../../components/animation/Loader'
import { RSButton1 } from '../../components/buttons'
import RSDrawer from '../../components/layout/RSDrawer'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import { SH1 } from '../../components/text/RSTypography'
import { AdjustVdotParams, ICreateTrainingPlanParams, RaceResult, UserVdotAdjustRecord } from '../../api/v2/types';
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
import useRNBridge from '../../hooks/useRNBridge'
import useStore from '../../store/useStore';
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore';
import { UpdateTrainingPlanLevel, SaveVdotAdjustments, UpdateEventName, DeactivatePlan, CreateChangeLog } from '../../modules/trainingPlanActions';
import { ChangePlanLevel, DeactivateGPTPlan } from '../../modules/aiTrainingPlanActions';
import affiliateToken from '../../modules/defaultAffiliateToken.json';
import { UpdateAccountProfile } from '../../modules/accountActions'
import levelSelection from '../../pages/training-plan/build/LevelSeletion.json'

const Options = (props: any) => {
	const navigate = useNavigate()

	const { changeLoc, setChangeLoc, showOptions, setShowOptions, userInfo, selectedRace, setReload, selectedDay, selectedWeek, getUserVdotChanges, raceResults, gptPlanId, accountProfile, mutate, accountLoading } = props

	const [changeLevel, setChangeLevel] = useState(false)
	const [openLvlConfirm, setOpenLvlConfirm] = useState(0)

	const [changeUnits, setChangeUnits] = useState(false)
	const [changeRG, setChangeRG] = useState(false)
	const [rgList, setRgList] = useState<Array<any>>([])
	const [rgSelected, setRgSelected] = useState(0)
	const [rgTime, setRgTime] = useState<any>({ hr: '', min: '', sec: '' })

	const [targetTime, setTargetTime] = useState("")

	const [deleteConfirmation, setDeleteConfirmation] = useState<any>(false)
	const [loading, setLoading] = useState<any>(false)

	const event_name = userInfo?.event_name || ''
	const [changeEventName, setChangeEventName] = useState<any>(false)
	const [eventName, setEventName] = useState<any>(event_name || '')
	const [eventNameDrawer, setEventNameDrawer] = useState<any>(event_name || '')
	const { measurement_system, location } = accountProfile || {}
	const isMetric = measurement_system === 'metric' ? true : false
	const metricArial = measurement_system ? (isMetric ? 'Kilometers' : 'Miles') : ''

	const { ConvertFullTo5k, ConvertFullToHalf, ConvertM2H2, ConvertFullto10k, ConvertH2M } = useTrainingPlanUtils()
	const { currentLocation, setCurrentLocation, userVdotAdjustments } = useStore((state: ITrainingPlanStore) => state)


	const rr: RaceResult = raceResults
	const recWeeks = rr?.recoveryPlan?.recoveryType?.recovery_length_in_weeks || 0

	const tokenSearchQuery = userInfo?.race_origin?.toLowerCase() === 'runsignup' ? `?aflt_token=${affiliateToken.RunSignup.toString()}` : ''

	const { sendDataToReactNative } = useRNBridge()
	const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false

	const handleGetNativeLocation = () => {
		const dataToSend = {
			requestType: 'geolocation-enable',
			payload: true,
			timestamp: new Date().getTime(),
		}
		sendDataToReactNative(dataToSend);
	}

	const handleUpdateProfile = async (dataOBJ: object, type: string) => {
		const data = { ...accountProfile, ...dataOBJ }
		const oldMs = accountProfile?.measurement_system
		if (userInfo?.internal_id && dataOBJ && Object.keys(dataOBJ)?.length) {
			setLoading(true)
			await UpdateAccountProfile({ user_id: userInfo.internal_id, ...dataOBJ }).then(async (res) => {
				if (res.status === "ok")
					if (type === 'CHANGE_LOCATION')
						await CreateChangeLog(
							{
								user_id: userInfo?.internal_id,
								plan_id: userInfo?.uid,
								change_type: 'CHANGE_LOCATION',
								week: selectedWeek.key,
								day: selectedDay + 1,
								previous_value: currentLocation || location, new_value: String(data.location),
							})
					else if (type === 'CHANGE_UNITS') {
						await CreateChangeLog(
							{
								user_id: userInfo?.internal_id,
								plan_id: userInfo?.uid,
								change_type: 'CHANGE_UNITS',
								week: selectedWeek.key,
								day: selectedDay + 1,
								previous_value: String(oldMs),
								new_value: String(data.measurement_system),
							})
					}
				mutate?.()
			})
			setLoading(false)
		}
		setChangeLoc(false)
		setChangeUnits(false)
	}

	const handleChangeName = async () => {
		const id = gptPlanId ? Number(gptPlanId) : 0
		if (eventName !== eventNameDrawer.trim() && id) {
			await UpdateEventName(id - 50000, eventNameDrawer.trim()).then(async (res) => {
				if (res.status === "ok")
					await CreateChangeLog(
						{
							user_id: userInfo?.internal_id,
							plan_id: id,
							change_type: 'CHANGE_EVENT_NAME',
							week: 0,
							day: 0,
							previous_value: eventName, new_value: eventNameDrawer.trim(),
						})
			});
			setEventName(eventNameDrawer.trim())
			setChangeEventName(false)
			setShowOptions(false)
		}
	}

	const handleUpdateRG = async () => {
		let { hr, min, sec } = rgTime;
		hr = hr ? Number(hr) : 0
		min = min ? Number(min) : 0
		sec = sec ? Number(sec) : 0
		if (hr || min || sec) {
			const rgType = rgList[rgSelected]
			const rgPlan = rgType === '5K Time' ? '5k' : (rgType === 'Half Marathon Time' ? 'hm' : 'M')
			const vdotData: ICreateTrainingPlanParams = {
				userId: userInfo?.uid as number,
				marathon: userInfo?.type as number,
				weeks: userInfo?.weeks as number,
				mileage: userInfo?.distance,
				plan: rgPlan,
				time: { hr, min, sec },
				date: userInfo?.race_date,
				km: userInfo?.km === "Y" ? true : false,
				agg: userInfo?.aggressiveness,
				monday: userInfo?.monday_start,
				vdot_adj: false,
				training_level: userInfo?.training_level
			}

			const adjustmentData: UserVdotAdjustRecord = {
				uid: userInfo?.uid,
				week: selectedWeek.key,
				day: selectedDay,
				vdot: 0,
				targetm: 0
			}

			if (userInfo?.uid) {
				// const current = userVdotAdjustments?.find((x: UserVdotAdjustRecord) => x.uid == userInfo?.uid && x.day == selectedDay && x.week == selectedWeek.key)

				const data: AdjustVdotParams = {
					params: vdotData,
					adjustments: adjustmentData,
					type: "insert"
				}
				await SaveVdotAdjustments(data).then(async (res) => {
					if (res.status === "ok")
						await CreateChangeLog(
							{
								user_id: userInfo?.internal_id,
								plan_id: userInfo?.uid,
								change_type: 'CHANGE_GOAL_TIME',
								week: selectedWeek.key,
								day: selectedDay + 1,
								previous_value: targetTime, new_value: String(ConvertM2H2(ConvertH2M(hr, min, sec))),
							})
				})
				getUserVdotChanges()
				setChangeRG(false)
			}
		}

	}

	const handleRgTimeChange = (e: any, t: string) => {
		if (!(((t === 'min' && !rgSelected) || t === 'sec') && Number(e?.target?.value) > 59)) {
			const newRgTime = { ...rgTime, [`${t}`]: e?.target?.value }
			setRgTime(newRgTime)
		}
	}

	const handleUpdateLvl = async () => {
		if (gptPlanId) {
			await ChangePlanLevel(gptPlanId, openLvlConfirm, new AbortController().signal).then(async (res) => {
				res.status === "ok" && res.data.messages === "success" && setReload(true)
				if (res.status === "ok")
					await CreateChangeLog(
						{
							user_id: userInfo?.internal_id,
							plan_id: userInfo?.uid,
							change_type: 'CHANGE_PLAN_LEVEL',
							week: selectedWeek.key,
							day: selectedDay + 1,
							previous_value: String(userInfo?.training_level),
							new_value: String(openLvlConfirm),
						})

			})
		}
		else
			await UpdateTrainingPlanLevel(userInfo?.uid, openLvlConfirm, userInfo?.internal_id).then(async (res) => {
				res.status === "ok" && res.data.messages === "success" && setReload(true)
				if (res.status === "ok")
					await CreateChangeLog(
						{
							user_id: userInfo?.internal_id,
							plan_id: userInfo?.uid,
							change_type: 'CHANGE_PLAN_LEVEL',
							week: selectedWeek.key,
							day: selectedDay + 1,
							previous_value: String(userInfo?.training_level),
							new_value: String(openLvlConfirm),
						})
			})

		setOpenLvlConfirm(0)
		setShowOptions(false)
		setChangeLevel(false)
	}

	const handleDelete = async () => {
		const abortController = new AbortController()
		if (userInfo?.uid) {
			setLoading(true)
			if (gptPlanId) {
				await DeactivateGPTPlan(gptPlanId, abortController.signal).then(async () => {
					navigate('/#/', { replace: true })
				})
			}
			await DeactivatePlan(userInfo?.uid, abortController.signal).then(async () => {
				navigate('/#/', { replace: true })
			})
		}
	}

	const renderHeader = (text: string, handleClick: any) => {
		return <RSHeaderCont drawer backClick={handleClick} title={text} />
	}

	const renderEditLocation = () => {
		//const description = 'By entering your location, we will track current weather conditions and help you pace accordingly. Change this anytime.'
		return <Grid container justifyContent='center'>
			{renderHeader('Edit Location', () => setChangeLoc(false))}
			<Box sx={{ width: '100%', p: 2 }}>
				<Location data={accountProfile} handleUpdateProfile={handleUpdateProfile} getLocation={isNativeApp ? handleGetNativeLocation : undefined} />
			</Box>
		</Grid>
	}

	const renderEditUnits = () => {
		return <Grid container justifyContent='center'>
			{renderHeader('Edit Units', () => setChangeUnits(false))}
			<Box sx={{ width: '100%', p: 2 }}>
				<MeasurementSystem data={accountProfile} handleUpdateProfile={handleUpdateProfile} />
			</Box>
		</Grid>
	}

	const renderAdjustRG = () => {
		return <Grid container justifyContent='center'>
			{renderHeader('Adjust Race Goal', () => setChangeRG(false))}
			<Box sx={{ width: '100%', p: 2 }}>
				<Grid container xs={12} sx={{ lineHeight: '1.2em', fontFamily: 'Poppins-Light !important', fontSize: '18px', p: '0px 0px 40px 0px' }} justifyContent="flex-start" alignItems="center">
					{`Adjust ${userInfo?.type === 4 ? 'the difficulty of your plan by entering the finish time of an event' : 'your plan by updating your finish time or entering a recent 5k time'} below.`}
				</Grid>
				<Grid container alignItems="center" xs={12} sx={{
					border: '1px solid rgba(0, 0, 0, 0.176)',
					p: '15px 20px',
					borderRadius: '5px',
					backgroundColor: 'white',
					mb: '10px'
				}}
				>
					<Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '20px' }}>
						Current Goal
					</Grid>
					<Grid container xs={12} sx={{ fontFamily: 'Poppins-Light !important', fontSize: '18px', lineHeight: '1em', pb: '10px' }}>
						{targetTime}
					</Grid>
				</Grid>

				<Grid container xs={12} sx={{ pt: '20px', pb: '5px', fontSize: '18px' }} justifyContent="center" alignItems="center">
					Select One
				</Grid>
				<Grid container xs={12} justifyContent="space-between" alignItems="center" sx={{ flexDirection: userInfo?.type === 4 ? 'column' : 'row' }}>
					{rgList.map((rg: string, rgI: number) =>
						<Grid container key={rgI} xs={12} sx={{ mb: '5px' }} justifyContent="center" alignItems="center">
							<Button disabled={rgI === rgSelected} onClick={() => setRgSelected(rgI)} sx={{ boxShadow: '0px 2px 6px rgba(128,128,128,0.2)', backgroundColor: `${rgI === rgSelected ? '#742F4E' : 'white'} !important`, textTransform: 'none', color: `${rgI === rgSelected ? 'white' : 'black'} !important`, width: '100%', fontFamily: 'Poppins-Light', fontSize: '16px', px: 0 }}>{rg}</Button>
						</Grid>
					)}
				</Grid>

				<Grid container xs={12} sx={{ pt: '20px', pb: '5px', fontSize: '18px' }} justifyContent="center" alignItems="center">
					Enter Time
				</Grid>
				<Grid container xs={12} justifyContent="center" alignItems="center">
					{(rgList.length > 1 && rgSelected !== rgList.length - 1) &&
						<Grid container xs={4} justifyContent="flex-start" alignItems="center">
							<input value={rgTime.hr} onChange={(e) => handleRgTimeChange(e, 'hr')} placeholder="HH" type='number' className='enter-time' onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} />
						</Grid>
					}
					<Grid container xs={(rgList.length > 1 && rgSelected !== rgList.length - 1) ? 4 : 6} justifyContent="center" alignItems="center">
						<input value={rgTime.min} onChange={(e) => handleRgTimeChange(e, 'min')} placeholder="MM" type='number' className='enter-time' onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} />
					</Grid>
					<Grid container xs={(rgList.length > 1 && rgSelected !== rgList.length - 1) ? 4 : 6} justifyContent="flex-end" alignItems="center">
						<input value={rgTime.sec} onChange={(e) => handleRgTimeChange(e, 'sec')} placeholder="SS" type='number' className='enter-time' onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} />
					</Grid>
				</Grid>

				<Grid container xs={12} sx={{ mb: '20px', mt: '20px' }} justifyContent='center' alignItems="center">
					<RSButton1 onClick={handleUpdateRG} sx={{ mt: '10px', maxWidth: '400px' }} >Save</RSButton1>
				</Grid>
			</Box>
		</Grid>
	}

	const lvlDesc = ['BASE | EASY', ' | TEMPO', ' | INTERVALS']
	const typeOfRuns = [
		<><span className='pL-18 underline'>Base runs</span> are conversation-paced runs that are great for building mileage and endurance.</>,
		<><span className='pL-18 underline'>Easy runs</span> are slower than base pace and great for recovery.</>,
		<><span className='pL-18 underline'>Tempo runs</span>  include 10 – 20 minute comfortable-hard repeats. They are great for building power and speed.</>,
		<><span className='pL-18 underline'>Interval runs</span> are shorter, more intense works that are typically completed on a track. They are great for improving speed.</>
	]
	const renderChangeLevel = () => {
		const tLvl = userInfo?.training_level ? userInfo.training_level : 0
		return <Grid container justifyContent='center'>
			{renderHeader('Change Level', () => setChangeLevel(false))}
			<Box sx={{ width: '100%', p: 2 }}>
				<Grid container xs={12} sx={{ lineHeight: '1.2em', fontFamily: 'Poppins-Light !important', fontSize: '18px', p: '0px 0px 40px 0px' }} justifyContent="flex-start" alignItems="center">
					Choose the aggressiveness of your plan below.
				</Grid>
				<Grid container xs={12} sx={{ lineHeight: '1.2em', fontFamily: 'Poppins-Light !important', fontSize: '18px' }} justifyContent="flex-start" alignItems="center">
					{lvlDesc.map((lvl: string, lvlI: number) =>
						<Grid container key={lvlI} xs={12} sx={{ fontFamily: 'Poppins-Light !important', fontSize: '18px !important', mb: '10px', height: 'auto !important' }} justifyContent="flex-start" alignItems="center">
							<RSButton1 secondary={tLvl !== lvlI + 1} onClick={() => setOpenLvlConfirm(lvlI + 1)} disabled={tLvl === lvlI + 1} sx={{ px: '15px', height: 'auto !important', width: '100%', textAlign: 'left', lineHeight: '1em !important', border: 'none !important'}}>
								<Grid container xs={2} sx={{ fontFamily: 'Poppins !important', fontSize: '40px !important', color: tLvl === lvlI + 1 ? '#010101' : '#9fcd2b' }}>
									{lvlI + 1}
								</Grid>
								<Grid container xs={10} sx={{ flexDirection: 'column' }}>
									<Grid container xs={12} sx={{ color: '#010101', fontSize: '18px !important', fontFamily: 'Poppins-Light !important' }}>
										{`Level ${lvlI + 1}`}
									</Grid>
									<Grid container xs={12} sx={{ color: '#010101', fontSize: '16px !important', fontFamily: 'Poppins-Light !important' }}>
										{lvlDesc.slice(0, lvlI + 1)}
									</Grid>
								</Grid>
							</RSButton1>
						</Grid>
					)}
				</Grid>
				<Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '18px', pt: '30px', pb: '5px' }} justifyContent="flex-start" alignItems="center">
					Types Of Runs
				</Grid>
				{typeOfRuns.map((tor: any, topI: number) =>
					<Grid container xs={12} key={topI} sx={{ lineHeight: '1.2em' }} justifyContent="flex-start" alignItems="center">
						<p className='pL-18'>{tor}</p>
					</Grid>
				)}
				<Modal
					open={openLvlConfirm ? true : false}
					onClose={() => setOpenLvlConfirm(0)}
				>
					<Box className='bg-main' sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', position: 'absolute', borderRadius: '5px', pt: '10px', top: '50%', left: '50%', width: '80%', maxWidth: '400px', backgroundColor: 'white', transform: 'translate(-50%, -50%)' }}>
						<Grid container xs={12} sx={{ px: '20px', fontFamily: 'Poppins', fontSize: '16px', borderBottom: '1px solid rgba(0, 0, 0, 0.176)' }} justifyContent='flex-start'>
							{`Change Level`}
						</Grid>
						<Grid container xs={12} sx={{ px: '20px', pt: '10px', fontFamily: 'Poppins-Light', fontSize: '16px' }} justifyContent='flex-start'>
							{`You're about to change level:`}
						</Grid>
						<Grid container xs={12} sx={{ px: '20px' }} justifyContent='flex-start'>
							<Box sx={{ fontFamily: 'Poppins-Light', fontSize: '16px' }}>
								LEVEL {tLvl}
							</Box>
							<Box sx={{ px: '3px' }}>
								<DArrowForward sx={{ fontSize: '28px' }} />
							</Box>
							<Box sx={{ fontFamily: 'Poppins-medium', fontSize: '16px' }}>
								LEVEL {openLvlConfirm}
							</Box>
						</Grid>
						<Grid container xs={12} sx={{ px: '10px', fontFamily: 'Poppins', fontSize: '16px', pt: '25px', pb: '15px' }} justifyContent='flex-end' alignItems='center'>
							<Grid container xs={4} justifyContent='center' alignItems='center' >
								<RSButton1 onClick={() => setOpenLvlConfirm(0)} secondary sx={{ width: '100%', mr: '2px' }}>Cancel</RSButton1>
							</Grid>
							<Grid container xs={4} justifyContent='center' alignItems='center'>
								<RSButton1 onClick={handleUpdateLvl} sx={{ width: '100%', ml: '2px' }}>Proceed</RSButton1>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			</Box>
		</Grid>
	}

	const renderEditEventName = () => {
		return <Grid container justifyContent='center'>
			{renderHeader('Edit Event Name', () => setChangeEventName(false))}
			<Box sx={{ width: '100%', p: 2 }}>
				<SH1 size={1} sx={{width: '100%', mb: 2}}>Event Name</SH1>
				<Grid container xs={12} justifyContent="center" alignItems="center">
					<input value={eventNameDrawer} onChange={(e) => setEventNameDrawer(e.target.value)} placeholder={event_name || 'Event name'} className='enter-time' style={{ height: '60px', padding: '20px', textAlign: 'left' }} />
				</Grid>
				<Grid container xs={12} sx={{ mb: '20px', mt: '20px' }} justifyContent='center' alignItems="center">
					<RSButton1 disabled={event_name === eventNameDrawer} onClick={handleChangeName} sx={{ maxWidth: '400px', mt: '10px' }}>Save</RSButton1>
				</Grid>
			</Box>
		</Grid>
	}

	const renderMain = () => {
		const isRecovery = userInfo?.uid == rr?.maintenance_plan_id && userInfo?.type == 4

		if (isRecovery) {
			delete infoList[3]
			delete infoList[5]
			delete infoList[6]
		} else if (userInfo?.type === 4) {
			delete infoList[3]
		}

		return <Grid container justifyContent='center'>
			{renderHeader(isRecovery ? 'Recovery Plan Options' : userInfo?.type == 4 ? 'Maintenance Plan Options' : 'Training Plan Options', () => setShowOptions(false))}
			<Box sx={{ width: '100%', p: 2 }}>
				<Grid container xs={12} justifyContent="flex-start" alignItems="center" sx={{ '& *': { fontSize: '16px' } }}>
					<Box sx={{ p: '0px 10px', mx: 'auto', backgroundColor: 'white', color: 'gray', width: '100%', borderRadius: '10px' }}>
						{infoList.map((listData, i) => {
							const { label, value, isEditable, labelMaxWidth, allowGPTEdit } = listData
							const optionInfoElement = <>
								<Box sx={{ textAlign: 'left', lineHeight: 1.2 }} >
									{label}
								</Box>
								{<Box sx={{ color: 'black', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: labelMaxWidth ? `calc(100% - ${labelMaxWidth})` : 'auto' }}>
									{<Box className='ellipsis-2l' sx={{ pr: 1, fontFamily: 'Poppins-Light !important', textAlign: 'right', lineHeight: 1.2, width: '100%' }}>{value}</Box>}
									{(!gptPlanId || allowGPTEdit) && isEditable && <IconButton sx={{ color: 'black' }}>
										<ArrowForward fontSize="medium" sx={{ stroke: 'white', strokeWidth: '1.3px' }} />
									</IconButton>}
								</Box>}
							</>

							return <>
								<Grid container key={i} xs={12} sx={{ p: '10px 0px', height: '50px', borderBottom: i === infoList.length - 1 ? '' : '1px solid #8080803b' }} justifyContent="space-between" alignContent="center" alignItems="center">
									{isEditable ? <Button onClick={isEditable} sx={{ p: 0, width: '100%', color: 'gray', justifyContent: 'space-between', textTransform: 'none' }}>
										{optionInfoElement}
									</Button> : optionInfoElement}
								</Grid>
							</>
						})}
					</Box>
				</Grid>
				{
					!isRecovery && userInfo?.race_link && tokenSearchQuery ? (
						<Grid container xs={12} justifyContent="center" alignItems="center">
							<RSButton1 sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								p: '15px 10px 15px 20px',
								mt: '10px', maxWidth: '400px'
							}}
								onClick={() => window.open(`${userInfo.race_link}${tokenSearchQuery}','_blank', 'rel=noopener noreferrer`)}
							>
								Register for Your Race
								<ArrowForward fontSize="large" sx={{ stroke: '#9fcd2b', strokeWidth: '1.3px', fontSize: '20px', mr: "10px" }} />
							</RSButton1>
						</Grid>
					) : undefined
				}
				{/* <Grid container xs={12} justifyContent="center" alignItems="center">
					<RSButton secondary customized sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						p: '15px 15px 15px 20px',
						mt: '10px', maxWidth: '400px'
					}}
						onClick={() => console.log('userDataPrompt', userDataPrompt)}
					>
						Generate plan with this info
						<i className="fa-sharp fa-solid fa-brain-circuit" style={{ marginRight: "10px", fontSize: '30px' }}></i>
					</RSButton>
				</Grid> */}
				<Grid container xs={12} justifyContent="center" alignItems="center">
					<RSButton1 secondary sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						p: '15px 15px 15px 20px',
						mt: '10px', maxWidth: '400px'
					}}
						onClick={() => setDeleteConfirmation(true)}
					>
						Delete Plan
						<i className="fa-sharp fa-solid fa-trash" style={{ marginRight: "15px", fontSize: '25px' }}></i>
					</RSButton1>
				</Grid>
				<Modal
					open={deleteConfirmation ? true : false}
					onClose={() => setDeleteConfirmation(false)}
				>
					<Box className='bg-main' sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', position: 'absolute', borderRadius: '5px', pt: '10px', top: '50%', left: '50%', width: '80%', backgroundColor: 'white', transform: 'translate(-50%, -50%)', maxWidth: '400px' }}>
						<Grid container xs={12} sx={{ px: '20px', fontFamily: 'Poppins', fontSize: '16px', borderBottom: '1px solid rgba(0, 0, 0, 0.176)' }} justifyContent='flex-start'>
							Delete Plan
						</Grid>
						<Grid container xs={12} sx={{ px: '20px', pt: '10px', fontFamily: 'Poppins-Light', fontSize: '16px' }} justifyContent='flex-start'>
							{`You're about to delete this plan.`}
						</Grid>
						<Grid container xs={12} sx={{ px: '20px', fontFamily: 'Poppins-Light', fontSize: '16px' }} justifyContent='flex-start'>
							Do you want to proceed?
						</Grid>
						<Grid container xs={12} sx={{ px: '10px', fontFamily: 'Poppins', fontSize: '16px', pt: '25px', pb: '15px' }} justifyContent='flex-end' alignItems='center'>
							<Grid container xs={4} justifyContent='center' alignItems='center' >
								<RSButton1 onClick={() => setDeleteConfirmation(false)} secondary sx={{ mr: '2px' }}>Cancel</RSButton1>
							</Grid>
							<Grid container xs={4} justifyContent='center' alignItems='center'>
								<RSButton1 onClick={handleDelete} sx={{ width: '100%', ml: '2px' }}>Proceed</RSButton1>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			</Box>
		</Grid>
	}

	const wrapDrawer = (element: any, open: boolean, setOpen: Function) => {

		return <RSDrawer
			open={open}
			onClose={() => setOpen(false)}
		>
			<Box>{element}</Box>
		</RSDrawer>
	}

	const infoList = [...(gptPlanId ? [{
		label: 'Event',
		value: eventName || 'N/A',
		labelMaxWidth: '60px',
		isEditable: () => setChangeEventName(true),
		allowGPTEdit: true
	}] : []), {
		label: selectedRace?.value == 4 ? 'Training Ends' : 'Race Day',
		value: userInfo?.race_date && moment(userInfo?.race_date).format('MMMM DD YYYY')
	}, {
		label: 'Distance',
		value: userInfo?.uid == rr?.maintenance_plan_id ? 'RECOVERY PLAN' : (selectedRace?.value == 0 || selectedRace?.value == 1) ? `${selectedRace?.title} ${selectedRace?.subTitle}` : selectedRace?.title
	}, {
		label: 'Length of Training',
		value: `${userInfo?.uid == rr?.maintenance_plan_id ? recWeeks : userInfo?.weeks} weeks`
	}, {
		label: 'Level',
		value: userInfo?.training_level ? levelSelection[userInfo.training_level - 1]?.title_2 : levelSelection[2]?.title_2,
		isEditable: () => gptPlanId ? () => { null } : setChangeLevel(true)
	}, {
		label: 'Units',
		value: metricArial || (userInfo?.km === "Y" ? 'Kilometers' : 'Miles'),
		isEditable: () => setChangeUnits(true),
		allowGPTEdit: true
	}, ...(userInfo?.rawUserInfo?.average_distance ? [{
		label: 'Average Weekly Mileage',
		value: userInfo?.km === "Y" ? (userInfo?.rawUserInfo?.average_distance * 1.60934).toPrecision(3) : userInfo?.rawUserInfo?.average_distance
	}] : []), {
		label: 'Peak Weekly Mileage',
		value: userInfo?.km === "Y" ? (userInfo?.distance * 1.60934).toPrecision(3) : userInfo?.distance
	}, ...(selectedRace?.value === 4 ? [] : [{
		label: 'Race Goal',
		value: targetTime,
		isEditable: () => gptPlanId ? () => { null } : setChangeRG(true)
	}]), {
		label: 'Training Location',
		value: location || currentLocation,
		isEditable: () => setChangeLoc(true),
		labelMaxWidth: '160px',
		allowGPTEdit: true
	}]

	// const userDataPrompt =
	// 	`I will be joining an upcoming ${selectedRace?.title} marathon (which is 13.1 miles), ${userInfo?.weeks} from now.
	// My goal is to finish in ${targetTime}.
	// While training, my weekly mileage is ${userInfo?.km === "Y" ? (userInfo?.distance * 1.60934).toPrecision(3) : userInfo?.distance} miles.`

	useEffect(() => {
		const type = (userInfo?.plan_type?.toUpperCase()?.includes('GPT') && 1) || userInfo?.type;
		let targetTime = 0;

		if (userVdotAdjustments?.length > 0) {
			const sorted = userVdotAdjustments.sort((a: any, b: any) => a.id - b.id)
			const last = sorted[sorted?.length - 1]
			targetTime = last?.targetm
		} else {
			targetTime = userInfo?.targetm
		}

		switch (type) {
			case 0:
				setTargetTime(ConvertFullToHalf(targetTime));
				setRgList(['Desired Finish Time', '5K Time'])
				break;
			case 1:
				setTargetTime(ConvertM2H2(targetTime));
				setRgList(['Desired Finish Time', '5K Time'])
				break;
			case 2:
				setTargetTime(ConvertFullTo5k(targetTime));
				setRgList(['Desired Finish Time'])
				break;
			case 3:
				setTargetTime(ConvertFullto10k(targetTime));
				setRgList(['Desired Finish Time'])
				break;
			case 4:
				setTargetTime(ConvertM2H2(targetTime));
				setRgList(['Marathon Time', 'Half Marathon Time', '5K Time'])
				break;
			default:
				break;
		}

	}, [userInfo, userVdotAdjustments, selectedDay, selectedWeek])

	useEffect(() => {
		location !== currentLocation && setCurrentLocation(location)
	}, [location])

	useEffect(() => {
		setRgTime({ hr: '', min: '', sec: '' })
	}, [rgSelected])

	useEffect(() => {
		setRgSelected(0)
		setRgTime({ hr: '', min: '', sec: '' })
	}, [changeRG])

	useEffect(() => {
		setOpenLvlConfirm(0)
	}, [changeLevel])

	useEffect(() => {
		setChangeLevel(false)
		setChangeRG(false)
		setChangeLoc(false)
	}, [showOptions])

	useEffect(() => {
		setEventName(event_name)
		setEventNameDrawer(event_name)
	}, [event_name])

	useEffect(() => {
		!changeEventName && setEventNameDrawer(eventName)
	}, [changeEventName])

	return (<>
		<Loader active={showOptions && (loading || accountLoading)} />
		{wrapDrawer(renderMain(), showOptions, setShowOptions)}
		{wrapDrawer(renderChangeLevel(), changeLevel, setChangeLevel)}
		{wrapDrawer(renderAdjustRG(), changeRG, setChangeRG)}
		{wrapDrawer(renderEditLocation(), changeLoc, setChangeLoc)}
		{wrapDrawer(renderEditEventName(), changeEventName, setChangeEventName)}
		{wrapDrawer(renderEditUnits(), changeUnits, setChangeUnits)}
	</>)
}

export default Options;
