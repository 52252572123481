/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import useGPTPrompts from '../pages/admin/useGPTPrompts';

export interface AIUserDetails {
  planWeeks: number
  dob: any
  gender: string
  currentWeeklyMileage:
  number
  suggestedPlanStartDistance?: number
  unit: string
  lastLongestRun: number
  maxRunPerWeek: number
  buildNumber?: number
  raceType?: string
  runDaysPerWeek?: number
  race_day?: string
  maintenanceType?: string
}

export interface Stage2Details {
  preferredLongRun: string
  availableRunDays: Array<string>
  runDaysPerWeek: number
  raceType?: string
  dob?: any
  gender?: string
  stepResults?: any
  initLongRuns?: any
  currentStep?: number
  planWeeks?: number
  crossTraining?: Array<string>
  level?: any
  latestRevision?: number
  race_day?: string
  stretchingPlan?: Array<string>
  isMaintenanceBuild?: boolean
}

const useAIPlanPrompts = (version?: number, race_type?: string) => {

  const { getTrainingPlanPrompts, prompts, getTotalSteps } = useGPTPrompts({ version, race_type })

  const buildUserDetails = (data?: AIUserDetails) => {

    const { planWeeks, dob, gender, currentWeeklyMileage, suggestedPlanStartDistance, unit, lastLongestRun, maxRunPerWeek, raceType, race_day, maintenanceType } = data || {}
    const age = dob ? moment().diff(moment(dob), 'years') : 36
    return data ? getTrainingPlanPrompts("1", raceType?.toLowerCase() as string, { planWeeks, dob, age, gender, currentWeeklyMileage, suggestedPlanStartDistance, unit, lastLongestRun, maxRunPerWeek, raceType, race_day, maintenanceType }) : ''
  }

  const outputJsonFormat = {
    plan: [{
      w: [1, 2, 3, 4, 5, 6, 7],
      m: [0, 0, 0, 0, 0, 0, 0],
      l: [0, 0, 0, 0, 0, 0, 0],
      t: [0, 0, 0, 0, 0, 0, 0],
      r: [0, 0, 0, 0, 0, 0, 0],
    }]
  }

  const defaultSytemPrompt = `
    You are a running coach who helps runners train for marathons. Utilize the step-by-step process below to create running plans based on the specifics provided. Build a table that reflects each week of the training program.

    Take the information provided about the runner and understand their running history and persona to create a plan that emphasizes performance but also injury prevention.

    Build Out a Weekly Mileage Plan According to Runner Specifications

    - output legend: w = weeks , m = weekly mileage, l = long run, t = taper (0 - false, 1-true), r = recovery (0 - false, 1-true)
    - Return results in json format. Sample JSON format to follow is ${JSON.stringify(outputJsonFormat)}.
    `
  const buildUserRecommendation = (data?: AIUserDetails, isMaintenancePrompt?: boolean) => {
    const { planWeeks, dob, gender, currentWeeklyMileage, unit, lastLongestRun, runDaysPerWeek, raceType } = data || {}
    const age = dob ? ((moment().diff(moment(dob), 'years')) + '-year-old') : ''

    let race = raceType
    if (raceType?.toLowerCase().includes('half') || raceType?.toLowerCase().includes('full')) race += ' marathon'


    return data ?
      `You are an expert running coach. Based on the following inputs, determine if I have adequate training to enter a ${isMaintenancePrompt ? "maintenance training plan." : "training plan for the upcoming race."}
        For your suggestion, take into account that I am a ${age} ${gender}.
  
        Guidelines:
        If the runner has adequate training, but the ${isMaintenancePrompt ? "training" : "race"} is soon, allow the runner to enter the training plan.
  
        Inputs:
        - Length of plan: ${planWeeks} weeks
        - Plan type: ${race}
        - Average weekly mileage over the last 4 weeks: ${currentWeeklyMileage} ${unit || 'miles'}
        - Longest run completed in the last 2 weeks: ${lastLongestRun} ${unit || 'miles'}
        - Number of running days per week: ${runDaysPerWeek}
        
        Results:
        - Tell me if I'm ready to enter a Training Plan: Yes/No
        - Short Explanation only if No: [Short Explanation (35-50 words) of why I may not be ready to enter a training plan ${isMaintenancePrompt ? "." : "for the race"}]
        Output:
        - Provide the output in the following JSON format
        - Example output: { "ready": "no", "reason": "You do not have a strong base yet and only have a few days to train. Therefore, most like you are not ready to enter a training plan. It is not recommended because etc..." }` : ''

  }

  const buildStage2InputPrompts = (data?: Stage2Details) => {
    const { raceType, gender, dob, preferredLongRun, availableRunDays, initLongRuns, stepResults, currentStep, planWeeks, runDaysPerWeek, crossTraining, level, latestRevision, race_day, stretchingPlan } = data || {}
    const age = dob ? ((dob ? moment().diff(moment(dob), 'years') : 36)) : ''
    const unit = 'miles'
    const trainingLevel = level || 3
    const yoga = crossTraining?.includes('Yoga') || false
    const strength = crossTraining?.includes('Strength') || false
    const stretch = (stretchingPlan && stretchingPlan.length > 0) || crossTraining?.includes('Stretch')
    const allCurrentStepResults = stepResults.filter((x: any) => x?.step == currentStep && x?.revision === latestRevision)
    const previousResults = (allCurrentStepResults && allCurrentStepResults.length > 0) ? allCurrentStepResults[allCurrentStepResults.length - 1]?.data?.modified : ''
    const plan = stepResults?.length ? JSON.stringify(previousResults || stepResults.filter((x: any) => x?.step == currentStep)[0]?.data) : ''

    const longRunDayPrev = preferredLongRun ? moment().day(preferredLongRun).subtract(1, 'days').format('dddd') : ''
    const longRunDayNext = preferredLongRun ? moment().day(preferredLongRun).add(1, 'days').format('dddd') : ''

    // console.log('Preferred Long Run Day: ', preferredLongRun, longRunDayPrev, longRunDayNext)

    const allDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const promptsData: string[] = []
    try {
      const totalSteps = getTotalSteps(raceType?.toLowerCase() as string, prompts)
      if (totalSteps && totalSteps > 0)
        Array.from({ length: totalSteps as number }, (_, i) => {
          promptsData?.push(getTrainingPlanPrompts(`${i + 2}`, raceType?.toLowerCase() as string, { longRunDayNext, longRunDayPrev, raceType, gender, dob, preferredLongRun, availableRunDays, initLongRuns, stepResults, currentStep, planWeeks, runDaysPerWeek, age, allDays, plan, trainingLevel, yoga, strength, stretch, race_day }))
        })
    } catch (e) {
      console.log(e)
    }

    return data ? promptsData : []
  }

  // PROVIDES SUGGESTED PEAK/MAX WEEKLY MILEAGE
  const buildPromptSuggestedWeeklyMileage = (weeklyPlanData: any, isMaintenancePrompt?: boolean) => {

    const { dob, gender, planWeeks, lastLongestRun, currentWeeklyMileage, raceType, runDaysPerWeek, unit } = weeklyPlanData || {}

    const age = dob ? (moment().diff(moment(dob), 'years') + '-year-old') : '36-year-old'
    const isMarathon = raceType?.toLowerCase().includes('half') || raceType?.toLowerCase().includes('full')
    let race = raceType
    if (isMarathon) race += ' marathon'

    return `${isMarathon ? `You are a running coach determining the average and highest weekly mileage for a runner based on the following information.` : isMaintenancePrompt ? `You are a running coach determining the average and highest weekly mileage for a runner who is looking to build their running volume.` : `You are a running coach determining the average and highest weekly mileage for a runner training for a ${race}`}

    Guidelines:
    1. Be sure to take age, gender, and number of running days into account.
    2. Be conservative in your recommendation (15% rule)
    3. ${isMarathon ? `Account for recovery weeks and taper` : 'Ensure you account for recovery weeks and taper'}
    
    Inputs:
    - Age: ${age}
    - Gender: ${gender}
    - Length of plan: ${planWeeks} weeks
    - Plan type: ${race || ''}
    - Average weekly mileage over the last 4 weeks: ${currentWeeklyMileage} ${unit || 'miles'}
    - Longest run completed in the last 2 weeks: ${lastLongestRun} ${unit || 'miles'}
    - Number of running days per week: ${runDaysPerWeek || 4}

    Rules for weekly distances:
    ${isMarathon ? `
    - For input of 3 running days: Do not exceed 25 mi (40km)
    - For input of 4 running days: Do not exceed 35 mi (56km)
    - For input of 5 running days: Do not exceed 45 mi (72km)
    - For input of 6 running days: Do not exceed 60 mi (97km)
    - For input of 7 running days: Do not exceed 75 mi (113km)` :
        `
    - For input of 3 running days: Do not exceed 18 mi (40km)
    - For input of 4 running days: Do not exceed 23 mi (56km)
    - For input of 5 running days: Do not exceed 30 mi (72km)
    - For input of 6 running days: Do not exceed 36 mi (97km)
    - For input of 7 running days: Do not exceed 50 mi (113km)
        `}
    
    Output:
    - Recommended Average Weekly Mileage: [provide number range]
    - Recommended Peak Weekly Mileage: [provide number range]

    Output format: JSON
    Example: { average: '18-22', peak: '26-28' }`
  }

  // PROVIDES SUGGESTED GOAL FINISH TIME
  const buildPromptSuggestedGoalTime = (weeklyPlanData: any) => {

    const { dob, gender, planWeeks, raceType, raceDistance, runPace } = weeklyPlanData || {}

    const age = (dob ? moment().diff(moment(dob), 'years') : 33) + '-year-old'

    const prompt = `I am a ${age} ${gender || ''} training for a ${raceType || ''} marathon of ${raceDistance || 26} miles, ${planWeeks || 13}-weeks from now.
      Help me come up with a finish goal time.
      Suggest me the best finish goal time so that I could run like I am in a ${runPace}
      
      Your output should be in this time format: { goalFinishTime: { hours: 0, minutes: 0, seconds: 0 } }.
      Example Output: { goalFinishTime: { hours: 2, minutes: 33, seconds: 26 } }
      `
    // console.log('Suggested Goal Time Prompt: ', prompt)

    return prompt

  }


  const buildPromptSuggestedPlanStartDistance = (weeklyPlanData: any, isMaintenancePrompt?: boolean) => {

    const { dob, gender, planWeeks, lastLongestRun, currentWeeklyMileage, raceType, runDaysPerWeek, unit } = weeklyPlanData || {}

    const age = dob ? (moment().diff(moment(dob), 'years') + '-year-old') : '36-year-old'
    const isMarathon = raceType?.toLowerCase().includes('half') || raceType?.toLowerCase().includes('full')
    let race = raceType
    if (isMarathon) race += ' marathon'

    const prompt = `You are a running coach whose sole job is to evaluate the running preferences below and determine the distance of the first long run in a training plan.
    
    Plan Distance: ${isMaintenancePrompt ? 'maintenance plan' : race}
    Plan Length: ${planWeeks} weeks 
    Runner's preferences: 
    Average running last 4 weeks: ${currentWeeklyMileage} ${unit || 'miles'}
    Last long run: ${lastLongestRun} ${unit || 'miles'}
    
    Output: provide a single number without explanation
    Example: { first_long_run: 12 }`

    console.log('Suggested Plan Start Distance Prompt: ', prompt)

    return prompt


  }


  return { defaultSytemPrompt, buildUserDetails, buildStage2InputPrompts, prompts, buildPromptSuggestedWeeklyMileage, buildPromptSuggestedGoalTime, buildUserRecommendation, buildPromptSuggestedPlanStartDistance }
}

export default useAIPlanPrompts


