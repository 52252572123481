import { getReq, postReq } from "./apiConsume"

export const authorizeCorosUser = async (code: string, userId: number) => {
    const authResponse = await postReq('/v2/coros/authorize', { code, userId })
    return authResponse
}

export const deAuthorizeCorosUser = async (userId: number) => {
    const response = await postReq('/v2/coros/deauthorize', { userId })
    return response.data
}

export const getCorosUserByUserId = async (userId: number) => {
    const response = await getReq('/v2/coros/user?user_id=' + userId)
    return response.data
}

export const getUserWorkouts = async (userId: number, activity_date: string) => {
    const response = await getReq(`/v2/coros/workouts?user_id=${userId}&activity_date=${activity_date}`)
    return response.data.data
}