import { useState, useEffect, FC } from 'react'

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

export type RSPopUpModalProps = {
	open: boolean,
	onClose: any,
	children?: any,
	sx?: object,
	bodySx?: object,
	popUpHeight?: string,
	popUpWidth?: string,
	popUpMaxHeight?: string,
	popUpMaxWidth?: string,
	preview?: boolean
}


const RSPopUpModal: FC<RSPopUpModalProps> = ({children, open, onClose, sx = {}, bodySx = {}, popUpHeight, popUpWidth, popUpMaxHeight, popUpMaxWidth, preview}) => {
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		isOpen && document?.getElementsByClassName('selected-phase')?.[0]?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
	},[isOpen])

	useEffect(() => {
		setIsOpen(open)
	},[open])

	return <Modal
			open={open}
			onClose={onClose}
			sx={{
				width: '100%',
				maxWidth: '900px',
				border: 'none !important',
				margin: 'auto',
				'& .MuiTableContainer-root, & .MuiTable-root': {
					height: preview ? '82%' : '100%',
					overflow: 'hidden',
					backgroundColor: '#f6f6f6'
				},
				'& .MuiBackdrop-root': {
					backdropFilter: 'blur(5px)'
				},
				...sx
			}}
		>
			<Box className='pop-up-drawer' sx={{outline: 'none', borderRadius: '4px',position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#f6f6f6', width: popUpWidth ? popUpWidth : '100%', height: popUpHeight ? popUpHeight : 'auto', maxHeight: popUpMaxHeight ? popUpMaxHeight : '100%', maxWidth: popUpMaxWidth ? popUpMaxWidth : '100%', overflowY: 'auto', overflowX: 'hidden', ...bodySx}}>
				{children}
			</Box>
		</Modal>
}

export default RSPopUpModal;
