import {FC, useState, useEffect} from 'react'
import moment from 'moment';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {RSButton1} from '../../components/buttons';
import { SH1 } from '../../components/text/RSTypography'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type DOBProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
}

const DOB: FC<DOBProps> = ({data, handleUpdateProfile}) => {
	const {dob} = data
	const [birthDate, setBirthDate] = useState<any>(dob || ''  );

	const handleSave = async () => {
		handleUpdateProfile({ dob: moment(new Date(birthDate)).format('YYYY-MM-DD')})
	}

	useEffect(()=>{
		setBirthDate(dob)
	},[dob])

	return (
		<Box>
			<SH1 size={1} sx={{width: '100%', mb: 1}}>Date of Birth</SH1>
			<Grid container xs={12} justifyContent='space-between'>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DemoItem>
						<DatePicker
							sx={{
								'& .MuiOutlinedInput-root': {
									height: '45px',
								},
								'& .MuiInputBase-input': {
									mt: '4px',
									zIndex: 1
								},
								'& .MuiOutlinedInput-notchedOutline': {
									backgroundColor: 'white'
								},
								width: '100%',
							}}
							slotProps={{
								textField: {
									error: false
								}
							}}
							maxDate={dayjs(moment().format('YYYY/MM/DD'))}
							openTo="year"
							value={dayjs(moment(new Date(birthDate)).format('YYYY/MM/DD'))}
							onChange={(newValue: any) => setBirthDate(moment(new Date(newValue)).format('YYYY/MM/DD'))}

						/>
					</DemoItem>
				</LocalizationProvider>
			</Grid>
			<RSButton1 onClick={handleSave} disabled={!birthDate} sx={{mt: 3}}>Save</RSButton1>
		</Box>
	)
}

export default DOB;
