/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import InstabotHide from '../../components/styles/InstabotHide'
import AppMenus from '../../components/layout/AppMenus'
import RSButton from '../../components/buttons/RSButton'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GenericAlert from '../../components/misc/GenericAlert'
import useAlert from '../../hooks/useAlert'
import AccountSubmitButtons, { PrimaryButton } from '../../components/buttons/AuthSubmitButtons'
import useQueryParams from '../../hooks/useQueryParams'
import useAppStorage from '../../hooks/useAppStorage'
import useLocalStorage from '../../hooks/useLocalStorage'
// import Badge from 'react-bootstrap/Badge'
import {
	APPLE_CLIENT_ID, APPLE_REDIRECT_URI, AUTH_COOKIE,
	GOOGLE_CLIENT_AUTH,
	HAS_SALE,
	IMPACT_TRIAL_ID,
	WS_URL
} from '../../modules/cliEnvValues'
// import { aboutLinks } from '../../modules/values'
import SignInForm, {
	SignInFormInfo,
	defaultFormInfo,
} from '../../components/forms/SignInForm'
import { navReq, postReq } from '../../modules/apiConsume'
import { createImpactClickId, logIn, signUp, updateAccountInfo } from '../../modules/accountActions'
import { FC, useState, useEffect, useCallback, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import classNames from 'classnames'
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
// import AppleLogin from 'react-apple-login';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'
import { IAppleTokenDetails, IGoogleUser } from '../../store/types'
import { appleAuthHelpers } from 'react-apple-signin-auth';
import jwt_decode from "jwt-decode";
import startCase from 'lodash.startcase'
import ReactPixel from 'react-facebook-pixel';
import secureLocalStorage from "react-secure-storage";
import { Spinner } from 'react-bootstrap'
import useScript from '../../hooks/useScript'
import { sha1 } from 'locutus/php/strings'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {
	GoogleReCaptchaProvider,
	useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { WebSocketContext } from '../../WebsocketProvider'
import useStoreWSMessages from '../../hooks/useStoreWSMessages'
import { CreateShareTransaction } from '../../modules/shareActions';

// Props
export type SignInProps = {
	isSignUp?: boolean,
	handleSuccessEmailSignup?: (accountId: number) => any
}

// URL With Offer
export const urlWithOffer = (url: string, offerType?: number) =>
	offerType ? `${url}?offer=${offerType}` : url

// Sign In Header
// const SIGNIN_HEADER = `
// Get everything you need for fast & injury-free running - all in one place!
// `

/**
 * SignIn Component
 */

const EmailSignup: FC<SignInProps> = ({ isSignUp, handleSuccessEmailSignup }) => {
	const { setHasOffer, currentUser } = useStore((state: ICurrentUser) => state)

	const { executeRecaptcha } = useGoogleReCaptcha();

	// const WS_URL = 'ws://dev.runsmartonline.com';
	const url = useLocation();
	// useScript('impact.js', url)
	useScript('identify.js', url)
	useScript('conversion.js', url)

	// Properties
	const title = isSignUp ? 'Join RunSmart' : 'Log In'

	// Params
	const { offer, irclickid, requestKey, newVersion } = useQueryParams()

	//impact click id
	const CLICK_ID = localStorage.getItem("irclickid") || irclickid || undefined
	const DEVICEKEY = localStorage.getItem("deviceRequestKey") || undefined
	const NEW_VERSION = newVersion || localStorage.getItem('appVersion') || undefined


	const link = 'signup' + url.search
	const linkHref = isSignUp
		? undefined
		: `/#/${link}${HAS_SALE ? '?offer=2' : ''}`
	const linkName = isSignUp ? undefined : 'Sign Up'
	const linkDetails = isSignUp
		? undefined
		: "Don't have an account?"
	const [info, setInfo] = useState<SignInFormInfo>(defaultFormInfo)
	const [isValid, setIsValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [redirect, setRedirect] = useState('')

	const offerType = Number(offer) || undefined

	const { mobileType } = useDeviceDetect()

	const [messageData, setMessageData] = useState<{ requestKey: string, payload: any, requestType: string, timestamp: number } | undefined>();
	// Hooks
	useAppStorage()
	const { storedValue: authCookie } = useLocalStorage({ key: AUTH_COOKIE })
	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
		setAlertVariant,
	} = useAlert()

	const { sendJsonMessage, lastJsonMessage } = useContext(WebSocketContext);
	const { removeMessageByType } = useStoreWSMessages()

	// const { sendJsonMessage, lastMessage, readyState } = useWebSocket(WS_URL,
	// 	{
	// 		shouldReconnect: (closeEvent) => {
	// 			return true;
	// 		},
	// 		reconnectAttempts: 30,
	// 		reconnectInterval: 30000,
	// 	}, requestKey || DEVICEKEY ? true : false);

	const createShareTransaction = async (account_id: number) => {
		const referralCode = localStorage.getItem('referral_code')
		if (referralCode && account_id) {
			await CreateShareTransaction(account_id, referralCode, '', 'create_account', 0)
		}
	}
	// On Submit
	const onSubmit = async (type?: string, googleUser?: IGoogleUser, appleUser?: IAppleTokenDetails, autoSignUp?: boolean, userDetails?: any) => {
		const honeypotField = info?.emailConfirm?.value

		// const isVerified = await handleReCaptchaVerify();

		// if (!honeypotField && isVerified || honeypotField == '' && isVerified) {
		if (!honeypotField || honeypotField == '') {
			setIsLoading(true)
			// Sign Up
			// if (isSignUp) {
			const response = await signUp({
				username: info.email.value,
				email: info.email.value,
				password: info.password.value,
			})
			const account_id = response?.data?.account_id
			// Login and get token
			if (account_id != null) {
				await createShareTransaction(account_id)
				const response = await logIn({
					username: info.email.value,
					password: info.password.value,
				})
				// Go to User Info Page
				if (response?.data?.account_id != null) {
					const data = {
						username: info.email.value,
						password: info.password.value,
					}

					await initImpactConversion(sha1(response?.data?.account_id), response?.data?.account_id, sha1(info.email.value))

					// setTimeout(() => {
					setIsLoading(false)
					localStorage.setItem("surveyType", "new_account")
					secureLocalStorage.setItem("workerKeys", data)
					secureLocalStorage.setItem("isAppleSignup", false)
					secureLocalStorage.setItem("isGoogleSignup", false)
					handleSuccessEmailSignup ? handleSuccessEmailSignup(response?.data?.account_id) : undefined

					// setRedirect(`/#/start?type=new_account${offer ? '&offer=' + offer : ''}`)
					// }, 2000)
				}
			}

			// User or email exists
			else if (response?.error?.message?.includes('exist')) {
				setIsLoading(false)
				setAlertVariant('danger')
				setAlertMessage(response.error.message)
				onAlertOpen()
			}

			// Uncaught error
			else {
				setIsLoading(false)
				setAlertVariant('danger')
				setAlertMessage('Signup failed')
				onAlertOpen()
			}
			// }
		}
		// }
	}

	const initImpactConversion = async (orderId: string, account_id: number, email: string) => {
		if (CLICK_ID) {
			// window?.callConversion?.(IMPACT_TRIAL_ID, orderId, String(account_id), sha1(email))
			await createImpactClickId(account_id as number, CLICK_ID, orderId, 'created')
		}
	}

	// Create an event handler so you can call the verification on button click event or form submit
	const handleReCaptchaVerify = async () => {
		setIsLoading(true)
		if (!executeRecaptcha) {
			console.log('Execute recaptcha not yet available');
			return false;
		}

		const token = await executeRecaptcha();
		const response = await postReq('/verify-recaptcha-token', { token: token })
		setIsLoading(false)
		if (response.data.verified)
			return true
		else
			return false
		// Do whatever you want with the token
	};

	// You can use useEffect to trigger the verification as soon as the component being loaded
	// useEffect(() => {
	// 	handleReCaptchaVerify();
	// }, [handleReCaptchaVerify]);

	// useEffect(() => {
	// 	localStorage.setItem("surveyType", "new_account")
	// 	setRedirect(urlWithOffer(`/#/start?type=new_account`, offerType))
	// }, [])

	// useEffect(() => {
	// 	if (development)
	// 		setTimeout(() => {
	// 			setRedirect(urlWithOffer(`/#/choose-plan${development ? '?development=1' : undefined}`, offerType))
	// 		}, 3000)
	// }, [development])

	const signInApple = async () => await appleAuthHelpers.signIn({
		authOptions: {
			clientId: APPLE_CLIENT_ID,
			scope: 'email name',
			redirectURI: APPLE_REDIRECT_URI,
			state: 'state',
			nonce: 'nonce',
			usePopup: true
		},
		onSuccess: (response: any) => {
			const tokenDetails: IAppleTokenDetails = jwt_decode(response.authorization.id_token)
			onSubmit("apple", undefined, tokenDetails)
		},
		onError: (error: any) => {
			if (error.error !== "popup_closed_by_user") {
				setAlertVariant('danger')
				setAlertMessage(startCase(error.error))
				onAlertOpen()
			}
		},
	});

	const handleGoogleLogin = () => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		if (requestKey || localStorageRequestKey) {
			sendJsonMessage && sendJsonMessage({ requestType: 'google-signin-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
		}
		else
			return googleLogin()
	}

	const handleAppleLogin = () => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		if ((requestKey || localStorageRequestKey) && NEW_VERSION) {
			sendJsonMessage && sendJsonMessage({ requestType: 'apple-signin-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
		}
		else
			return signInApple()
	}


	const googleLogin = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			const userInfo = await axios.get(GOOGLE_CLIENT_AUTH, { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } });

			if (userInfo) {
				onSubmit("google", userInfo?.data)
				// setGoogleUser(userInfo?.data as IGoogleUser)
			}
			else {
				setAlertVariant('danger')
				setAlertMessage('Signup failed')
				onAlertOpen()
			}
		},
		onError: () => {
			setAlertVariant('danger')
			setAlertMessage('Signup failed')
			onAlertOpen()
		},
	});

	// Redirect/Cookie Effect
	useEffect(() => {
		if (authCookie && redirect) navReq(redirect)
	}, [authCookie, redirect])

	// useEffect(() => {
	// 	// secureLocalStorage.clear();
	// 	// setRedirect(`/#/start?type=new_account${offer ? '&offer=' + offer : ''}`)

	// 	ReactPixel.trackCustom('ViewContent', { k: new Date().getTime() });
	// }, [])

	useEffect(() => {
		if (irclickid)
			localStorage.setItem("clickid", irclickid)
	}, [irclickid])

	useEffect(() => {
		if (CLICK_ID) {
			window?.callIdentify?.(String(currentUser?.account_id), sha1(currentUser?.email))
		}
	}, [currentUser, url])

	useEffect(() => {
		if (requestKey)
			localStorage.setItem("deviceRequestKey", requestKey)
	}, [requestKey])

	useEffect(() => {
		if (lastJsonMessage) {
			const message = lastJsonMessage
			setMessageData(message)
		}
	}, [lastJsonMessage]);

	useEffect(() => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		// alert(JSON.stringify(messageData))
		if ((messageData?.requestKey === requestKey || messageData?.requestKey === localStorageRequestKey) && messageData?.requestType === 'google-signin-mobileapp' && messageData.payload) {
			removeMessageByType('google-signin-mobileapp')
			onSubmit("google", messageData.payload)
		}

		if ((messageData?.requestKey === requestKey || messageData?.requestKey === localStorageRequestKey) && messageData?.requestType === 'apple-success-signin' && messageData.payload) {
			const tokenDetails: IAppleTokenDetails = jwt_decode(messageData?.payload?.identityToken)
			const fullName = messageData?.payload?.fullName
			removeMessageByType('apple-success-signin')

			sendJsonMessage && sendJsonMessage({ requestType: 'success-signin', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })

			onSubmit("apple", undefined, tokenDetails, (fullName?.familyName || fullName?.givenName) ? true : false, fullName)
		}
	}, [messageData]);

	const renderBTN = (icon: any, text: string, action: any) => {
		return <RSButton sx={{ mb: '10px' }} onClick={action}>
			{icon}
			<Box sx={{ pl: '10px' }}>{text}</Box>
		</RSButton>
	}

	// Render
	return (
		<>
			{/* Alert */}
			<GenericAlert
				show={showAlert}
				title={alertTitle}
				message={alertMessage}
				variant={alertVariant}
				onClose={onAlertClose}
			/>

			{/* Form */}
			<div className="account-form mt-3 mx-n2">
				{/* Form */}

				<SignInForm
					isSignUp={isSignUp}
					setInfo={setInfo}
					setIsValid={setIsValid}
				/>

				{/* Terms of Service */}
				{/* {isSignUp && (
							<p className="mb-4 text-center">
								{`By clicking "Continue" you agree to our `}
								<a href={aboutLinks.terms} style={{ color: 'black' }}>
									<strong>Terms of Service</strong>
								</a>
								{', '}
								<a href={aboutLinks.privacy} style={{ color: 'black' }}>
									<strong>Privacy Policy</strong>
								</a>
								{', '}
								<a href={aboutLinks.waiver} style={{ color: 'black' }}>
									<strong>Waiver of Liability</strong>
								</a>
								, and to receive our email updates
							</p>
						)} */}


				{/* Submit Buttons */}
				<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<RSButton
						disabled={!isValid || !executeRecaptcha || isLoading}
						sx={{ width: '220px', mb: '10px' }} onClick={onSubmit}
					>
						<Box sx={{ pl: '10px' }}>{isLoading ? <Spinner size="sm" animation='border' /> : "Continue"}</Box>
					</RSButton>
				</Box>

				{/* Switch Page Link */}
				{!isSignUp && (
					<Row className="mb-1 footer-info">
						<Col className="text-center">
							<span className="text-black me-2">{linkDetails}</span>
							<span>
								<a href={linkHref}><u>{linkName}</u></a>
							</span>
						</Col>
					</Row>
				)}

				{/* Forgot Password Link */}
				{!isSignUp && (
					<Row className="mb-3 footer-info">
						<Col className="text-center">
							<span className="text-black me-2">
								Forgot your password?
							</span>
							<span>
								<a href="/#/forgot-password"><u>Reset Password</u></a>
							</span>
						</Col>
					</Row>
				)}
				{!isSignUp ? (
					<Row xs={12}>
						<div className='other-login'>
							<Box sx={{ width: '220px' }}>
								{mobileType !== "Android" && renderBTN(<AppleIcon />, 'Log in with Apple', handleAppleLogin)}
								{renderBTN(<GoogleIcon />, 'Log in with Google', (mobileType === "Android" || mobileType === "Ios") ? handleGoogleLogin : googleLogin)}
							</Box>
						</div>
					</Row>) : undefined}
			</div>
		</>
	)
}

export default EmailSignup
