/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useRef, useState } from 'react'
import { useGeolocated } from "react-geolocated";
import { useNavigate } from 'react-router-dom'
import { DotLoader } from 'react-spinner-overlay';
import moment from 'moment'
import useSWR from 'swr'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Input from '@mui/material/InputBase';
import { Alert, AlertColor } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew'
import ArrowForward from '@mui/icons-material/ArrowForwardIos'
import prev from 'locutus/php/array/prev'

import SearchCards from './SearchCards'
import SearchAdvance from './SearchAdvance'
import SearchFilters from './SearchFilters2'
import RaceEventsStyleFlag from '../explore/RaceEventsFlagStyle';
import ManualAdd from '../training-plan/ManualAdd';
import WithAuth from '../../components/WithAuth'
import RSNavCont from '../../components/layout/RSNavCont'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import {RSButton1, RSButton2} from '../../components/buttons'
import Loader from '../../components/animation/Loader'
import RSDrawer from '../../components/layout/RSDrawer';
import RSChurrosButton from '../../components/buttons/RSChurrosButton';
import { H2, SH3, P2 } from '../../components/text/RSTypography'
import { AuthPageProps, SearchFiltersProps } from '../../modules/types'
import { getReq } from '../../modules/apiConsume';
import { HOME_LOGO } from '../../modules/miscImages'
import useSearchRaces from '../../hooks/useSearchRaces'
import useQueryParams from '../../hooks/useQueryParams'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useScrollEnd from '../../hooks/useScrollEnd';
import RaceBuild from '../race/RaceBuild';
import useRNBridge from '../../hooks/useRNBridge';
import useStore from '../../store/useStore';
import { ICurrentUser } from '../../store/createAccountStore';
import { UpdateAccountProfile } from '../../modules/accountActions';
import { IBuildPlanAvailability } from '../../api/v2/training-plan/training-plan.types';

const RaceSearch: FC<AuthPageProps & { raceName?: string, onBack?: any }> = ({ userInfo, hasLoaded }) => {
	const { raceName } = useQueryParams()
	const element = document.getElementsByClassName('MuiDrawer-paperAnchorBottom')
	const navigate = useNavigate()
	const [value, setValue] = useState('')
	const [filters, setFilters] = useState<SearchFiltersProps | undefined>()
	const [provider, setProvider] = useState('RunSignUp')
	const [alertComponent, setAlertComponent] = useState({ show: false, message: '', type: 'error' as AlertColor })
	const [location, setLocation] = useState<any | undefined>()
	const [isNear, setIsNear] = useState(false)
	const [geoLoading, setGeoLoading] = useState(false)
	const [isGeolocated, setIsGeolocated] = useState(false)
	const [showManualAddForm, setShowManualAddForm] = useState(false)
	const [retries, setRetries] = useState(0)
	const [inputRemoved, setInputRemoved] = useState(true)
	const [initLoading, setInitLoading] = useState(true)

	const inputRef = useRef<any>();
	const { data: accountProfile, isLoading: accountProfileLoading } = useSWR(userInfo?.account_id ? `/account-profile?account_id=${userInfo?.account_id}` : null, { refreshInterval: 0 })
	const { races, isLoading, isLastResultEmpty, isPaginatedLoading, page, setPage } = useSearchRaces(filters, accountProfile?.data?.result?.[0]?.measurement_system === 'metric')
	const { isScrollEnd } = useScrollEnd(document.getElementsByClassName('MuiDrawer-paperAnchorBottom')?.[0], 136)
	const isMobile = useIsMobileScreen()
	//	const { country, city, zipcode } = filters || {}
	//	const locationObj: any = { country, city, zipcode }
	//	const locationKeys = Object.keys(locationObj).filter((key: any) => locationObj[key])
	//	const addHeight = locationKeys?.length ? '245px' : '205px'
	const [details, setDetails] = useState<any>({});
	const [isStepOpen, setIsStepOpen] = useState<any>(false)
	const [isInitialLoading, setIsInitialLoading] = useState(true)
	const allLoader = !hasLoaded || isLoading || accountProfileLoading || isInitialLoading
	const featuredRacesList = races?.length ? races.filter((rf: any, rfI: number) => rf.isFeatured && races.indexOf(rf) == rfI) : []
	const raceList = races?.filter((rf: any) => !rf.isFeatured)
	const [isFeaturedOpen, setIsFeaturedOpen] = useState(true)

	const [hasActiveFilters, setHasActiveFilters] = useState(false)
	const [showSearchBar, setShowSearchBar] = useState(false)
	const locationStr = filters ? filters?.city || filters?.state || filters?.country || filters?.zipcode : ''

	const { sendDataToReactNative } = useRNBridge()

	const { userCoords, setUserCoords } = useStore((state: ICurrentUser) => state)

	const [planBuilds, setPlanBuild] = useState<Array<IBuildPlanAvailability>>([])
	const { data } = useSWR('/build-plan-availability', { refreshInterval: 0, revalidateOnFocus: true })
	const [disabledRaces, setDisabledRaces] = useState<Array<string>>([])

	const [failedNativeLocation, setFailedNativeLocation] = useState(false)

	const temp_coord = {
		"accuracy": 322515.12021243706,
		"latitude": 42.767460,
		"longitude": -78.743910,
		"altitude": null,
		"altitudeAccuracy": null,
		"heading": null,
		"speed": null
	}

	const { coords, getPosition, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
		suppressLocationOnMount: true,
		positionOptions: {
			enableHighAccuracy: true,
		},
		userDecisionTimeout: 5000,
		watchLocationPermissionChange: true,
	});

	const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false

	const handleUpdateLocation = async (location: string) => {
		if (userInfo?.account_id && location) {
			await UpdateAccountProfile({ user_id: userInfo?.account_id, location: location })
		}
	}

	const scrollReset = () => {
		const element = document.getElementsByClassName('MuiDrawer-paperAnchorBottom')
		element?.length > 1 && element[1]?.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}

	const handleSearch = () => {
		const newFilters = filters || {}
		setFilters({ ...newFilters, ...location, event_name: value.trim() })
		scrollReset()
	}

	const handleEnter = (e: any) => {
		allLoader && e.preventDefault()
		if (e.keyCode == 13) {
			handleSearch()
			setPage(1)
			inputRef?.current?.blur()
			setIsFeaturedOpen(false)
		}
	}

	const handleGetZipcode = async (coords: GeolocationCoordinates) => {
		const { latitude, longitude } = coords;
		const zipcodes = await getReq(`/v2/findNearbyPostalCodes?latitude=${latitude}&longitude=${longitude}`)
		if (zipcodes?.data?.postalCodes?.length > 0) {
			const address = zipcodes.data.postalCodes[0]
			setLocation({ zipcode: address.postalCode, city: address.placeName, country: address.countryCode, state: address.countryCode == "US" ? address.adminCode1 : '', isNearMe: true })
			setIsGeolocated(true)
			setGeoLoading(false)
			await handleUpdateLocation(address.placeName + ', ' + address.countryCode).then(() => {
				// console.log('Location updated')
			}).catch((err) => {
				console.log('Error updating location: ', err)
			})
		}
		else {
			setIsGeolocated(false)
			setGeoLoading(false)
		}
	}

	const handleRemoveInput = () => {
		setValue('')
		inputRef?.current?.focus()
		setInputRemoved(true)
		setIsFeaturedOpen(true)
	}

	const handleGetNativeLocation = () => {
		const dataToSend = {
			requestType: 'geolocation-enable',
			payload: true,
			timestamp: new Date().getTime(),
		}
		if (userCoords) {
			handleGetZipcode(userCoords)
		}
		else
			sendDataToReactNative(dataToSend);
	}

	useEffect(() => {
		if (coords && !isNativeApp && coords.latitude && coords.longitude) {
			handleGetZipcode(coords);
		}
		// else if (!coords && !isNativeApp) {
		// 	console.log('no coords')
		// 	// setFilters({ start_date: moment().format('YYYY-MM-DD'), ...raceName && { race_type: [raceName] } })
		// }
		else if (userCoords && isNativeApp) {
			handleGetZipcode(userCoords);
			setGeoLoading(false)
			setIsGeolocated(true)
		}
		// else if ((userCoords && userCoords.error) && isNativeApp) {
		// 	setFilters({ start_date: moment().format('YYYY-MM-DD'), ...raceName && { race_type: [raceName] } })
		// 	setIsGeolocated(false)
		// 	setGeoLoading(false)
		// }
	}, [coords, isNativeApp, userCoords])

	useEffect(() => {
		if (isScrollEnd && !isLastResultEmpty) {
			setPage(page + 1)
		}
	}, [isScrollEnd, isLastResultEmpty])

	useEffect(() => {
		setValue(filters?.event_name || '')
		filters && setIsInitialLoading(false)
	}, [filters])


	useEffect(() => {
		if (data && data.data.result.length > 0) {
			setPlanBuild(data.data.result)
		}
	}, [data])

	useEffect(() => {
		if (planBuilds.length > 0) {
			const disabledRaces: Array<string> = []
			planBuilds.map((raceType) => {
				if (raceType.status == 0) {
					disabledRaces.push(raceType.race_type)
				}
			})

			setDisabledRaces(disabledRaces)
		}
	}, [planBuilds])

	{/* useEffect(() => {
		if (races && races.length < 5 && retries < 2) {
			console.log('Retrying: ', retries)
			setRetries(prev => prev + 1)
			setPage(prev => prev + 1)
		}
	}, [races]) */}

	useEffect(() => {
		value && setInputRemoved(false)
	}, [value])


	useEffect(() => {
		if (alertComponent.show) setTimeout(() => {
			setAlertComponent({ show: false, message: '', type: alertComponent.type })
		}, 3000)
	}, [alertComponent])

	useEffect(() => {
		if (!isGeolocationEnabled) {
			isNativeApp ? setGeoLoading(false) : noGeoAlert()
			!isNativeApp ? setFilters({ start_date: moment().format('YYYY-MM-DD'), ...raceName && { race_type: [raceName] } }) : undefined
		}
	}, [isGeolocationEnabled, isNativeApp, failedNativeLocation])

	useEffect(() => {
		if (isNativeApp && !userCoords) {
			handleGetNativeLocation()
		}
	}, [isNativeApp, userCoords])

	useEffect(() => {
		if (allLoader && isNativeApp) {
			setTimeout(() => {
				if (!userCoords) {
					setFailedNativeLocation(true)
				}
				else
					setFailedNativeLocation(false)
			}, 5000)
		}
	}, [allLoader, isNativeApp, userCoords])

	useEffect(() => {
		if (failedNativeLocation && isNativeApp && !userCoords) {
			setFilters({ start_date: moment().format('YYYY-MM-DD'), ...raceName && { race_type: [raceName] } })
		}
	}, [failedNativeLocation, userCoords, isNativeApp])

	// useEffect(() => {
	// 	console.log('userCoords: ', userCoords)
	// 	console.log('isGeolocated: ', isGeolocated)
	// 	if (userCoords && !isGeolocated) {
	// 		handleGetZipcode(userCoords)
	// 		setIsGeolocated(true)
	// 		setGeoLoading(false)
	// 		setUserCoords(undefined)
	// 	}
	// 	else if (userCoords && isGeolocated) {
	// 		handleGetZipcode(userCoords)
	// 	}
	// }, [userCoords, isGeolocated])

	// useEffect(() => {
	// 	if (isNativeApp && !userCoords) {
	// 		setFilters({ start_date: moment().format('YYYY-MM-DD'), ...raceName && { race_type: [raceName] } })
	// 		setGeoLoading(false)
	// 		setIsGeolocated(false)
	// 	}
	// }, [isNativeApp, userCoords])

	const renderAlert = () => {
		return (
			<Box sx={{ mt: 2, mb: 2, position: 'absolute', width: 'inherit', top: 0, zIndex: 2001 }}>
				<Alert severity={alertComponent.type} onClose={() => { setAlertComponent({ show: false, message: '', type: alertComponent.type }) }}>
					{alertComponent.message}
				</Alert>
			</Box>
		)
	}
	const noGeoAlert = () => {
		setAlertComponent({ show: true, message: 'Location is not enabled.', type: 'error' })
		setGeoLoading(false)
	}

	const renderEmptyResults = () => {
		if (retries == 0) {
			setRetries(1)
			setPage(2)
		}
		return <Box sx={{ mt: '90px', textAlign: 'center', height: '100%', width: '100%', p: 1 }}>
			<Box sx={{ color: '#404040', fontSize: '16px', fontFamily: 'Poppins', mb: 2 }}>{"We couldn't find any events."}</Box>
			<Box sx={{ color: '#404040', fontSize: '13px', fontFamily: 'Poppins' }}>{"We're adding partners regularly. For now, use the button above to build your training plan manually."}</Box>
			{/* <div onClick={() => setShowManualAddForm(true)}>
				<Box sx={{ color: '#4285f4', fontSize: '16px', fontFamily: 'Poppins-Medium', display: 'block', mt: '30px' }}>Build Training Plan Manually</Box>
			</div> */}
		</Box>
	}

	const renderSearchbar = () => {
		const searchBar = <Grid container className='transition-all' sx={{ maxWidth: isMobile ? (showSearchBar ? 400 : 45) : 'auto', width: `calc(100% - ${isMobile ? '0px' : '105px'})`, borderRadius: '5px', backgroundColor: 'white', height: '45px', overflow: 'hidden', border: (showSearchBar || !isMobile) ? '1px solid #80808057' : '' }}>
			{/* search icon */}
			{isMobile && <RSButton1 onClick={() => {
				showSearchBar && handleSearch()
				setShowSearchBar(true)
			}}
				sx={{ backgroundColor: 'transparent !important', border: 'unset', borderRadius: '0px', height: '100%', width: '45px', minWidth: '0px', justifyContent: 'center', color: 'gray !important', mr: showSearchBar || !isMobile ? '0px':'-10px' }}
			>
				<i className='fa-regular fa-magnifying-glass' style={{ fontSize: '20px' }}></i>
			</RSButton1>}
			{/* input */}
			{(showSearchBar || !isMobile) && <>
				<Input autoFocus inputProps={{ enterkeyhint: 'search' }} inputRef={inputRef} placeholder="Search..."
					onKeyDown={handleEnter}
					onChange={(e) => setValue(e.target.value)}
					value={value}
					sx={{ width: `calc(100% - ${isMobile && value ? 90 : 45}px)`, pl: isMobile ? 0 : '10px', height: '100%', backgroundColor: 'transparent', fontSize: '18px', fontFamily: 'Poppins-Light !important' }} /
				>
				{value && <RSButton1 onClick={handleRemoveInput} sx={{ position: isMobile ? 'absolute' : 'relative', backgroundColor: 'transparent !important', border: 'unset', borderRadius: '0px', height: '100%', width: '45px', minWidth: '0px', justifyContent: 'center', alignItems: 'center', top: 0, right: 0 }}>
					<i className='fa-light fa-xmark' style={{ fontSize: '20px', color: 'gray' }}></i>
				</RSButton1>}
			</>}
		</Grid>

		const searchHeader = <Grid container justifyContent='flex-start' alignItems='flex-start'>

			{/* advance filters and near me */}
			<Grid container xs={12} justifyContent='space-between'>
				<SearchFilters disabledRaces={disabledRaces} accountProfile={accountProfile?.data?.result?.[0] || {}} inputRemoved={inputRemoved} isLoading={allLoader} scrollReset={scrollReset} raceName={value} setGeoLoading={setGeoLoading} isNear={isNear} setIsNear={setIsNear} filters={filters} setFilters={setFilters} hide={false} noGeoAlert={noGeoAlert} location={location} setLocation={setLocation} getLocation={isNativeApp ? handleGetNativeLocation : getPosition} isGeolocationAvailable={isGeolocationAvailable} isGeolocationEnabled={isGeolocationEnabled} isGeolocated={isGeolocated} setIsGeolocated={setIsGeolocated} geoLoading={geoLoading} setHasActiveFilters={setHasActiveFilters} />
			</Grid>
		</Grid>
		return <>
			<RSHeaderCont title={showSearchBar || !isMobile ? '' : 'Race Events'} onboarding noShadow secondary={!isMobile} contSX={{ px: isMobile ? 0 : '20px' }} barSX={{ pl: isMobile ? '20px' : 0, pr: isMobile ? 3 : 0, backgroundColor: '' }} childrenSX={{ width: isMobile ? 'auto' : '100%'}}
				backClick={() => navigate('/explore', { replace: true })}
			>
				<Grid container xs={12} sx={{ pl: 1, mt: isMobile ? 0 : '12px', mb: isMobile ? 0 : '5px'}}>
					{searchBar}
					{!isMobile && <RSButton1 onClick={() => handleSearch()} sx={{ ml: '5px', height: '45px', width: '100px', minWidth: '0px', justifyContent: 'center' }}>
						Search
					</RSButton1>}
				</Grid>
			</RSHeaderCont>
			<RSHeaderCont childFullWidth onboarding className='overflow-visible transition-all' sx={{ height: `${64 + (hasActiveFilters ? 38 : 0)}px`, zIndex: 9 }} barSX={{ p: '10px 0px', overflow: 'hidden', mt: isMobile ? '50px' : '110px' }} contSX={{ alignItems: 'flex-start' }}>
				{searchHeader}
			</RSHeaderCont>
		</>
		//		return <RSHeaderCont className='transition-height-halfsec' sx={{ height: openFilter ? '205px' : '125px', zIndex: 9 }} barSX={{ p: '10px 0px', overflow: 'hidden' }}>
		//			{window.innerWidth > 1260 && <img className='header-logo' src={HOME_LOGO} alt="RunSmart Home Logo" style={{ position: 'absolute', top: 10, left: -160 }} />}
		//			{searchBar}
		//		</RSHeaderCont>
	}

	const renderManualBuildPlan = (hasDesc?: boolean) => {
		return <Grid container xs={12} sx={{ mb: 2 }}>
			<RSButton2 sx={{ alignItems: 'center', height: '60px', py: 1, px: '15px', mb: 1, justifyContent: 'space-between' }} onClick={() => setShowManualAddForm(true)} >
				<Box>
					{hasDesc && <SH3 title>{`Don't see your event listed?`}</SH3>}
					<P2 subtitle>Build a training plan manually</P2>
				</Box>
				<Box>
					<i className="fa-sharp fa-light fa-chevron-right" style={{ fontSize: '30px', color: '#010101' }} />
				</Box>
			</RSButton2>
		</Grid>
	}
	const renderAfterResult = () => races?.length ?
		(raceList?.length ?
			(!filters?.event_name && renderManualBuildPlan(true))
			: (`No ${filters?.event_name ?
				`results listed for "${filters?.event_name}"`
				: (locationStr ? `races near ${locationStr}` : `results`)}.`))
		: `We couldn't find any events`

	const renderBody = () => {
		return <RSNavCont hideHeader={isMobile} hideFooter={true} plain>
			<Loader active={allLoader && !isPaginatedLoading} />
			<Grid container xs={12} sx={{ height: '100%', minHeight: 'inherit', position: 'relative', width: '100%' }} justifyContent='center' alignItems='flex-start'>
				{alertComponent.show && renderAlert()}

				<Box sx={{ position: 'relative', width: '100%' }}>

					{/* runsmart banner */}
					{/*!races && <Box sx={{ width: 'auto', position: 'absolute', top: '-100px', left: '50%', transform: 'translateX(-50%)' }}>
			<img src={HOME_LOGO} style={{ width: '300px', height: 'auto' }} alt="RunSmart Logo" />
		</Box>*/}

					{/* find a race search bar */}
					{renderSearchbar()}

					{races && races.length > 0 ? <Grid container xs={12} sx={{ px: 3 }}>
						{featuredRacesList?.length > 0 && <Grid container sx={{ pb: 1, pt: 1 }}>
							<Grid container className='pointer' xs={12} sx={{ position: 'relative', mb: 1 }} justifyContent='space-between' alignItems='center' onClick={() => setIsFeaturedOpen(!isFeaturedOpen)}>
								<H2 size={1} sx={{ zIndex: 1, pr: '10px' }}>Featured races</H2>
								<Box sx={{ backgroundColor: '#f6f6f6 !important', zIndex: 1, fontSize: '20px', pl: '5px', minWidth: '0px', py: 0, pr: 0, height: 20, display: 'flex' }}>
									<i className={`fa-duotone fa-circle-chevron-${isFeaturedOpen ? 'up' : 'down'} duo-tone`} />
								</Box>
							</Grid>
							<Grid container xs={12} className='transition-all' sx={{ height: isFeaturedOpen ? featuredRacesList.length * 134 : 0, overflow: 'hidden' }}>
								{featuredRacesList?.map((rData: any, index: number) => <SearchCards hasLoader disabledRaces={disabledRaces} userInfo={userInfo} isFeatured accountProfile={accountProfile?.data?.result?.[0] || {}} key={'search-cards-featured-' + index} data={rData} />)}
							</Grid>
						</Grid>}

						{filters?.event_name ? renderManualBuildPlan(true) : <></>}

						{!(!raceList?.length && allLoader) && <H2 size={1} sx={{ zIndex: 1, pr: '10px', mb: 1 }}>
							{filters?.event_name ? `${hasActiveFilters ? 'Filtered' : 'Search'} results for "${filters?.event_name}"` : (locationStr && hasActiveFilters ? `Upcoming races near ${locationStr}` : `${hasActiveFilters ? 'Filtered' : 'All'} races`)}
						</H2>}
						{raceList?.map((rData: any, index: number) => {
							let isDuplicate = false
							if (index > 0 && races[index].date === races[index - 1].date && races[index].place === races[index - 1].place && races[index].logo === races[index - 1].logo) {
								isDuplicate = true
							} else return <SearchCards hasLoader disabledRaces={disabledRaces} userInfo={userInfo} accountProfile={accountProfile?.data?.result?.[0] || {}} key={'search-cards-' + index} data={rData} />
						})}
						{isPaginatedLoading ? <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', m: 1 }}><DotLoader size={10} color={"#9fcd2b"} /></Box>
							: (raceList?.length ? <Grid container xs={12} sx={{ justifyContent: 'center', alignItems: 'center', mt: 2, fontFamily: 'Poppins !important', fontSize: '14px !important', color: 'gray' }}>-- End of results --</Grid> : <></>)
						}

					</Grid> : null}

					{!allLoader &&
						<Grid container xs={12} sx={{ pt: 3, px: 3, flexDirection: 'column', mb: 1 }} justifyContent='center' alignItems='center'>
							<Grid container xs={12} sx={{ fontSize: '16px', fontFamily: 'Poppins-Bold', lineHeight: '1.2em', textAlign: 'center', mb: 1 }} justifyContent='center' alignItems='center'>
								{renderAfterResult()}
							</Grid>
							{races?.length ?
								<></> : <>
									<Box sx={{ fontSize: '15px', fontFamily: 'Poppins-Light !important', lineHeight: '1.2em', textAlign: 'center', mb: 2 }}>{`We're adding partners regularly. For now, use the button above to build your training plan manually.`}</Box>
									{renderManualBuildPlan()}
								</>
							}
						</Grid>
					}

				</Box>
			</Grid>

			<RSDrawer bottom fitContent popUpWidth='400px' popUpHeight='auto' open={showManualAddForm} onClose={() => {
				setShowManualAddForm(false)
			}}>
				<Box sx={{ py: 3, px: 2 }}>
					<ManualAdd disabledRaces={disabledRaces} setShowManualAddForm={setShowManualAddForm} setDetails={setDetails} details={details} setIsStepOpen={setIsStepOpen} />
				</Box>
			</RSDrawer>
			<RSDrawer fullWidth popUp popUpHeight='600px' open={isStepOpen} onClose={() => setIsStepOpen(false)}>
				<RaceBuild popUpHeight={'600px'} accountProfile={accountProfile?.data?.result.length > 0 ? accountProfile?.data?.result[0] : { user_id: userInfo?.account_id }} raceDetails={{ ...details }} onClose={() => {
					setIsStepOpen(false)
					setShowManualAddForm(true)
				}} />
			</RSDrawer>
		</RSNavCont>
	}

	return (isMobile ? <RSDrawer bottom fitContent popUpWidth='100%' popUpHeight='90%' open={true} noClose={isMobile} onClose={undefined}>
		{renderBody()}
	</RSDrawer> : renderBody())
}

export default WithAuth(RaceSearch);
