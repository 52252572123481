import { FC, useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import {RSButton1, RSButton2} from '../../components/buttons';
import { SH1 } from '../../components/text/RSTypography'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type LongRunDayProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
}

const LongRunDay: FC<LongRunDayProps> = ({ data, handleUpdateProfile }) => {
	const { run_day } = data
	const [runDay, setRunDay] = useState<any>(run_day || '');

	const dayList = ['Sunday', 'Saturday']

	const handleSave = async () => {
		handleUpdateProfile({ run_day: runDay })
	}

	useEffect(() => {
		setRunDay(run_day)
	}, [run_day])

	return (
		<Box>
			<SH1 size={1} sx={{width: '100%', mb: 2}}>What is your preferred long run day?</SH1>
			<Grid container xs={12} justifyContent='space-between'>
				{dayList.map((activity: any, ai: number) => {
					const isActive = activity === runDay
					return <Grid item container xs={5.6} key={'activity-' + ai} sx={{ minHeight: '100px', width: '100px' }} justifyContent='center' alignItems='center'>
						<RSButton2 secondary={!isActive} sx={{ height: '100%', flexDirection: 'column' }} onClick={() => setRunDay(activity)}>
							<Box sx={{ fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px' }}>{activity}</Box>
						</RSButton2>
					</Grid>
				})}
			</Grid>
			<RSButton1 onClick={handleSave} disabled={!runDay || run_day === runDay} sx={{ mt: 3 }}>Save</RSButton1>
		</Box>
	)
}

export default LongRunDay;
