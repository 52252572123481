/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState, useRef, SetStateAction } from 'react'

import { AnimatePresence, motion } from "framer-motion";

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import useSWR from 'swr'
import RSButton from '../../components/buttons/RSButton'
import RSFlexCard from '../../components/cards/RSFlexCard'
import exerciseActions from '../../modules/exerciseActions'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import { SH3, P2 } from '../../components/text/RSTypography'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import RSDrawer from '../../components/layout/RSDrawer'
import ExercisePage from '../exercises/ExercisePage'
import { UserInfoProps } from '../../modules/types'
import textHelperUtils from '../../utils/textHelperUtils'
import allExercises from '../../modules/videos/allNewExercises.json'

import Loader from '../../components/animation/Loader'

const variants = {
	enter: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "100%" : "-100%"
		};
	},
	center: {
		opacity: 1,
		x: 0
	},
	exit: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "-100%" : "100%"
		};
	}
};

export type ExerciseTabProps = UserInfoProps & {
	searchResult?: any
	searchValue?: string
}

const ExerciseTab: FC<ExerciseTabProps> = ({ userInfo, searchResult, searchValue }) => {
	const [initTab, setInitTab] = useState(0)
	const [activeTab, setActiveTab] = useState(0)

	const prevActiveTab = useRef(0)

	const initialCap = 6

	const {
		getExercisesByRegion,
		getBodyRegions,
		getExerciseVideosByExerciseId
	} = exerciseActions()

	const isMobile = useIsMobileScreen();

	const [prevResults, setPrevResults] = useState<any[]>([])
	const [selectedRegion, setSelectedRegion] = useState('');
	const [selectedRegionTitle, setSelectedRegionTitle] = useState('');
	const [regionExerciseVideos, setRegionExerciseVideos] = useState([]);
	const [bodyRegions, setBodyRegions] = useState([])
	const [showExerciseVideo, setShowExerciseVideo] = useState(false)
	const [exerciseVideoId, setExerciseVideoId] = useState<number>()
	const [searchResultTitle, setSearchResultTitle] = useState('')
	const [searchResultsEmbeddings, setSearchResultsEmbeddings] = useState<any[]>([])
	const [exerciseVideosListEmbeddings, setExerciseVideosListEmbeddings] = useState<any[]>([])
	const [isLoadingDisplay, setIsLoadingDisplay] = useState(false)
	const [cap, setCap] = useState(initialCap)

	const activeSlide = selectedRegion || searchResultTitle ? 1 : 0

	const { capitalizeEachWord } = textHelperUtils()

	const { data: exercises, isLoading, error } = useSWR(searchValue ? `/exercise-embeddings/search?searchQuery=${searchValue}` : undefined, { revalidateOnFocus: false, revalidateOnReconnect: false })


	const handleChangeActive = (val: number) => {
		prevActiveTab['current'] = activeTab
		setInitTab(val);
	}

	const getExerciseTitle = (exerciseId: number) => {
		const exercise = allExercises.find((x: { exercise_id: number }) => x.exercise_id === exerciseId)
		return exercise?.exercise_title
	}

	const getExerciseType = (exerciseId: number) => {
		const exercise = allExercises.find((x: { exercise_id: number }) => x.exercise_id === exerciseId)
		return exercise?.exercise_type
	}

	const fetchExerciseVideos = async () => {

		setIsLoadingDisplay(true)
		let loopCount = 0
		const exerciseVideoList: any = []

		for (const exercise of searchResultsEmbeddings) {
			loopCount++
			if (loopCount > cap) break
			const exerID = exercise?.exercise?.exercise_id
			await getExerciseVideosByExerciseId(exerID).then((result: any) => {
				const video = result?.data?.result
				exerciseVideoList.push({ ...exercise, exercise_title: getExerciseTitle(exerID), exercise_type: getExerciseType(exerID), intro: video.find((x: { video_type: string }) => x.video_type === "intro"), exercise: video.find((x: { video_type: string }) => x.video_type === "exercise") })
			})
		}
		// exerciseVideoList.push({ exercise_title: 'Load More' })

		// console.log('>>> exerciseVideoList', exerciseVideoList)
		setExerciseVideosListEmbeddings(exerciseVideoList)
	}

	const renderExerciseRegionsList = () => {
		return bodyRegions?.sort((a: any, b: any) => a?.id - b?.id).map((activity: any, ai: number) => {

			const img = '.' + activity.logo
			return <>
				<RSButton card secondary={true} onClick={() => {
					//console.log('showing exercises by region: ', activity)
					handleChangeActive(1)
					setSelectedRegion(activity.search_value);
					setSelectedRegionTitle(activity.body_region);
				}}
					key={ai + '-plan'} sx={{ maxWidth: '450px', height: '80px', p: 0, overflow: 'hidden', justifyContent: 'start', alignItems: 'flex-start' }}>

					{img && <Grid sx={{ width: '40%', my: '5px' }} justifyContent={'end'} alignContent={'center'} alignItems={'center'}>
						<img src={img} style={{ height: '70px' }} />
					</Grid>}

					<Grid sx={{ position: 'absolute', right: '0', height: '100%', width: '60%', top: '33%' }}>
						{activity.body_region}
					</Grid>

				</RSButton>
			</>
		})
	}

	const renderExercisePlayer = () => {
		return (
			<RSDrawer
				bottom
				noClose
				open={showExerciseVideo}
				onClose={() => setShowExerciseVideo(false)}
			>
				<Box>
					<ExercisePage closePlayer={() => {
						setShowExerciseVideo(false)
					}} isFromTP={true} userInfo={userInfo} videoIdProps={String(exerciseVideoId)} />
				</Box>
			</RSDrawer>
		)
	}

	const renderExerciseVideosByRegion = () => {
		return regionExerciseVideos?.length ? regionExerciseVideos.map((exercise: any, ei: number) => {

			const video_id = exercise.exercise.video_id
			return <>
				<RSButton card secondary={true}
					onClick={() => {
						setExerciseVideoId(video_id)
						setShowExerciseVideo(true)
					}}
					key={ei + '-plan'}
					sx={{ maxWidth: '450px', height: '80px', p: 0, m: 0, overflow: 'hidden', justifyContent: 'start', alignItems: 'flex-start', ml: '-5px' }}
				>

					{video_id && <div className='card-slanted' style={{ width: '40%', height: '100%' }} ><img className='flip' src={`https://vumbnail.com/${video_id}.jpg`} style={{ height: '100%', backgroundPosition: 'center' }} /></div>}

					<Grid container sx={{ height: '100%', width: '55%', flexDirection: 'column', pl: 2 }} justifyContent='center' >

						<SH3 title>{exercise.exercise_title}</SH3>
						<P2 subtitle>{capitalizeEachWord(exercise?.exercise_type || '')}</P2>

					</Grid>

				</RSButton>
			</>
		}) : [<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '18px' }} key='nrf-rs-search'>No exercise found.</Box>]
	}

	const renderExerciseVideosBySearchEmbeddings = () => {
		return exerciseVideosListEmbeddings?.length ? exerciseVideosListEmbeddings.map((exercise: any, ei: number) => {

			// const lastOne = ei == exerciseVideosListEmbeddings.length - 1
			const lastOne = false
			const video_id = !lastOne && exercise.exercise.video_id
			return !lastOne ? <>
				<RSButton card secondary={true}
					onClick={() => {
						setExerciseVideoId(video_id)
						setShowExerciseVideo(true)
					}}
					key={ei + '-plan'}
					sx={{ maxWidth: '450px', height: '80px', p: 0, m: 0, overflow: 'hidden', justifyContent: 'start', alignItems: 'flex-start', ml: '-5px' }}
				>

					{video_id && <div className='card-slanted' style={{ width: '40%', height: '100%' }} ><img className='flip' src={`https://vumbnail.com/${video_id}.jpg`} style={{ height: '100%', backgroundPosition: 'center' }} /></div>}

					<Grid container sx={{ height: '100%', width: '55%', flexDirection: 'column', pl: 2 }} justifyContent='center' >
						<SH3 title>{exercise.exercise_title}</SH3>
						<P2 subtitle>{capitalizeEachWord(exercise?.exercise_type || '')}</P2>
					</Grid>

				</RSButton>
			</>
				:
				<RSButton card secondary={true}
					onClick={() => {
						setCap(prev => prev + 4)
						fetchExerciseVideos()
					}}
					sx={{ maxWidth: '450px', height: '80px', p: 0, m: 0, overflow: 'hidden', justifyContent: 'start', alignItems: 'flex-start', ml: '-5px' }}
				>
					<Grid sx={{ position: 'absolute', right: '0', height: '100%', width: '55%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

						<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', lineHeight: '1.2em', textAlign: 'left' }}>{`Load More`}</Box>

					</Grid>
				</RSButton>
		})


			: [<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '18px' }} key='nrf-rs-search'>No exercise found.</Box>]
	}

	const renderSlides = () => {
		return activeTab ? <>
			<RSHeaderCont header secondary={!isMobile} isStatic={!isMobile} noShadow={true} barSX={{ backgroundColor: 'transparent', px: isMobile ? "-22px" : '-5px' }}
				title={searchResultTitle ? searchResultTitle : selectedRegionTitle ? `Exercises for ${selectedRegionTitle}` : 'Back'}
				backClick={() => {
					handleChangeActive(0)
					setSearchResultTitle('')
					setSelectedRegion('');
				}}
			></RSHeaderCont>
			<Grid container xs={12} sx={{ ml: '5px', my: -2 }} >
				<RSFlexCard videoList={exerciseVideosListEmbeddings?.length > 0 ? renderExerciseVideosBySearchEmbeddings() : renderExerciseVideosByRegion()} sx={{ pt: 0, my: 0, overflow: 'hidden' }} />
			</Grid>
		</> : <>
			<SH3 size={1} >Choose a body region:</SH3>
			<RSFlexCard className='transition-height' videoList={renderExerciseRegionsList()} sx={{ pt: 0, pb: '1rem', my: 0, overflow: 'hidden' }} />
		</>
	}

	useEffect(() => {
		setActiveTab(initTab)
	}, [initTab])

	useEffect(() => {
		if (searchResult && searchValue) {
			setSearchResultTitle(`Search Results for "${searchValue}"`)
			setRegionExerciseVideos(searchResult)
			handleChangeActive(2)
		}
	}, [searchResult])

	useEffect(() => {
		if (exercises?.data?.result && exercises?.data?.result.length > 0 && !isLoading && !error)
			// console.log('>>> exercise embeddings results: ', exercises)
			setCap(initialCap)
		setSearchResultsEmbeddings(exercises?.data?.result)
	}, [exercises, isLoading, error])

	useEffect(() => {

		if (selectedRegion) {
			getExercisesByRegion(selectedRegion).then((result: any) => {
				setRegionExerciseVideos(result?.data?.result)
			})
		}

	}, [selectedRegion])

	useEffect(() => {
		fetchExerciseVideos()
	}, [cap])


	useEffect(() => {

		getBodyRegions().then((result: any) => {
			setBodyRegions(result?.data?.result)
		})

	}, [searchValue])


	useEffect(() => {

		if (searchResultsEmbeddings && searchResultsEmbeddings.length > 1) fetchExerciseVideos()

	}, [searchResultsEmbeddings])

	useEffect(() => {

		if (searchResultsEmbeddings && searchResultsEmbeddings.length > 1
			&& exerciseVideosListEmbeddings && exerciseVideosListEmbeddings.length < 1)
			setIsLoadingDisplay(true)
		else setIsLoadingDisplay(false)

	}, [exerciseVideosListEmbeddings])


	return (
		<Box sx={{ px: 3, pb: '80px', pt: !activeTab ? 3 : 0 }}>

			<AnimatePresence mode='wait' initial={false}>
				<motion.div
					key={activeTab + 'slide'}
					custom={initTab - prevActiveTab?.current}
					variants={isMobile ? variants : undefined}
					initial="enter"
					animate="center"
					exit='exit'
					transition={{ duration: 0.2 }}
					style={{ width: '100%' }}
				>
					{renderSlides()}
					{activeTab && exerciseVideosListEmbeddings?.length > 0 ?
						<Grid sx={{ display: 'flex', mt: '2rem', justifyContent: 'center' }}>
							<RSButton sx={{ width: '16rem' }} onClick={()=> {
								setCap(prev => prev + 4)
								fetchExerciseVideos()
							}}>
								Load More
							</RSButton>
						</Grid> : ''}
				</motion.div>
			</AnimatePresence>

			{renderExercisePlayer()}
			<Loader active={isLoading || isLoadingDisplay} />
		</Box>
	)
}

export default ExerciseTab

