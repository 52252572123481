/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, Grid } from '@mui/material'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { RSButton1 } from '../../components/buttons'
import RSInput from '../../components/input/RSInput'
import { H1, SH2 } from '../../components/text/RSTypography'
import RSDateInput from '../../components/input/RSDateInput'
import RSChurrosButton from '../../components/buttons/RSChurrosButton'
import raceSelection from '../training-plan/build/RaceSelection.json'
import RSDrawer from '../../components/layout/RSDrawer'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'


const RACE_EVENTS = ['5K', '8K', '10K', '10 mile', 'HALF', 'FULL']

export type ManualAddProps = {
    setShowManualAddForm: any
    setDetails: any
    details: any
    setIsStepOpen: any
    disabledRaces?: any
}
const ManualAdd: FC<ManualAddProps> = ({ setShowManualAddForm, setDetails, setIsStepOpen, details, disabledRaces }) => {
    const [dateValue, setDateValue] = useState<any>(moment().add(3, 'months').format('MM/DD/YYYY'));
    const [eventName, setEventName] = useState<any>('');

    const isMobile = useIsMobileScreen()

    const [selectedRaceType, setSelectedRaceType] = useState<any>('HALF');

    const [raceTitle, setRaceTitle] = useState('')
    const [openOops, setOpenOops] = useState(false)

    // const [open, setOpen] = useState(false)

    const handleRaceTitle = (raceTitle?: any) => {
        console.log('raceTitle', raceTitle)
        const notAllowed = moment(dateValue).diff(moment(), 'days') < 13
        const rs = raceSelection.find(rs => rs.title.toUpperCase().includes(selectedRaceType.toUpperCase()))
        const distanceValue = rs?.distanceValue || 0

        if (raceTitle === 'close') {
            setIsStepOpen(false)
            setShowManualAddForm(true)
        }

        else {
            if (rs)
                if (notAllowed || rs.not_available) setOpenOops(true)
                else {
                    setRaceTitle((distanceValue > 13 ? rs.subtitle : rs.title))
                    setIsStepOpen(true)
                    setShowManualAddForm(false)
                }
        }

    }

    const renderRaceDistances = () => RACE_EVENTS.map((rd: any, rdI: number) => {
        const rs = raceSelection.find(rs => rs.title.toUpperCase().includes(rd.toUpperCase()))
        const isDisabled = disabledRaces && disabledRaces.length > 0 ? disabledRaces.indexOf(rd.toLowerCase()) > -1 : false
        return rs && <Box key={rdI} sx={{ mb: '10px' }}><RSChurrosButton
            symbol={rs.logo}
            title={rs.subtitle}
            dipColor={rs.color}
            onClick={() => setSelectedRaceType(rd)}
            isActive={selectedRaceType === rd}
            disabled={isDisabled}
        /></Box>
    })

    useEffect(() => {
        let rs;
        if (selectedRaceType)
            rs = raceSelection.find(rs => rs.title.toUpperCase().includes(selectedRaceType.toUpperCase()))

        setDetails(
            {
                name: eventName || '',
                date: (dateValue && moment(dateValue).format('MMMM D, YYYY')) || '',
                dateEnd: (dateValue && moment(dateValue).format('MM/DD/YYYY')) || '',
                place: '',
                type: [selectedRaceType],
                desc: '',
                homepage: '',
                logo: '',
                raceTitle: rs?.subtitle || '',
                selectedRaceDistance: selectedRaceType
            })
    }, [dateValue, eventName, selectedRaceType])

    return (
        <>
            <H1 size={1} sx={{ mb: 2, textAlign: 'center' }}>
                Build Training Plan Manually
            </H1>
            <Box sx={{ px: 1 }}>
                <SH2 size={1} sx={{ width: '100%', mb: 1 }}>Event Details</SH2>
                <Grid container xs={12}>
                    <RSInput value={eventName} setValue={setEventName} title={'Race Name'} />
                </Grid>

                <SH2 size={1} sx={{ width: '100%', mb: 1, mt: 2 }}>Event Date</SH2>
                <RSDateInput month date={dateValue} setDate={setDateValue} minDate={moment().add(5, 'weeks')} maxDate={moment().add(1, 'year')} />

                <SH2 size={1} sx={{ width: '100%', mb: 1, mt: 2 }}>Choose Event Distance</SH2>
                <Grid container xs={12} sx={{ lineHeight: '1.2em', fontFamily: 'Poppins-Light !important', fontSize: '18px' }} justifyContent="flex-start" alignItems="center">
                    {renderRaceDistances()}
                </Grid>

                <RSButton1 disabled={!eventName || !dateValue || !selectedRaceType} onClick={() => handleRaceTitle()} sx={{ mt: 3 }}>{'Build a Plan'}</RSButton1>
            </Box>


            <RSDrawer noClose className='sorry-pop-up' bottom maxHeight={isMobile ? '40%' : '100%'} popUpWidth='400px' popUpHeight='550px' open={openOops} onClose={() => setOpenOops(false)}>

                {!isMobile && <Box className='sorry-pop-up-desktop' />}
                <Grid container xs={12} sx={{ flexDirection: 'column', height: isMobile ? '100%' : '275px', background: isMobile ? 'none' : 'linear-gradient(180deg, rgba(254,183,154,1) 0%, rgba(254,183,154,0) 100%)' }} justifyContent='space-between' alignItems='center'>
                    <Box>
                        <Box sx={{ color: 'rgb(220,53,69) !important', width: '100%', fontSize: '24px', fontFamily: 'Poppins-Bold', p: 2, display: 'flex', justifyContent: 'center' }}>
                            Oops!
                        </Box>
                        <Box sx={{ fontSize: '18px', fontFamily: 'Poppins', display: 'flex', justifyContent: 'center', px: 2, lineHeight: '1.2em', textAlign: 'center' }}>
                            Only Half Marathon race is available for now with at least 2 weeks of preparation time for safe and effective training.
                        </Box>
                    </Box>
                    <RSButton1 onClick={() => setOpenOops(false)} sx={{ width: isMobile ? '100%' : '200px', mb: isMobile ? 0 : 3 }}>
                        Go Back
                    </RSButton1>
                </Grid>
            </RSDrawer>
        </>
    )
}

export default ManualAdd
