import { FC, useRef, useEffect } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {RSButton2} from '../buttons'
import {SH6} from '../text/RSTypography'

export type RSAccordionProps = {
	title?: string,
	className?: string,
	children?: any,
	sx?: object,
	isActive?: boolean,
	setActive?: any,
	height?: number
}

const RSAccordion: FC<RSAccordionProps> = ({ title = '', className = '', children, sx, isActive = false, setActive, height = 50 }) => {
	const accordionRef = useRef<any>({current: ''})

	useEffect(()=>{
		if(isActive)
			accordionRef?.current?.scrollIntoView({behavior: 'smooth'})
	},[isActive])

	return <Grid ref={accordionRef} container xs={12} className={`${isActive ? 'orange-border border-1 border-solid' : ''} default-bs ${className}`} sx={{borderRadius: '5px',overflow: 'hidden', ...sx}}>
		<RSButton2 secondary={!isActive} borderless onClick={()=>setActive?.(isActive ? '' : title)} sx={{p: 0, flexDirection: 'column', overflow: 'hidden', boxShadow: 'none !important', borderRadius: '0px !important'}}>
			<SH6 sx={{position: 'relative', width: '100%', p: '10px'}}>{title}
				<Box sx={{ width: '20px', position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }}>
					<i className="fa-solid fa-chevron-down transition-all-quarter" style={{fontSize: '18px', transform: `scaleY(${isActive?-1:1})`}}/>
				</Box>
			</SH6>
		</RSButton2>
		<Box className='transition-all' sx={{height: isActive ? `${height}px` : '0px', width: '100%', backgroundColor: '#fff', display: 'flex'}}>
			{children}
		</Box>
	</Grid>
}

export default RSAccordion;
