import Card from 'react-bootstrap/Card'
import MultiLineText from '../text/MultiLineText'
import classNames from 'classnames'
import { FC } from 'react'
import { RSButton1 } from "../buttons"

// Props
export type SplitCardProps = {
	bgImage: string
	logoImage: string
	text: string
	title?: string
	buttonText?: string
	buttonLink?: string
	buttonDisabled?: boolean
	isColored?: boolean
	isSmall?: boolean
	className?: string
	buttonAction?: () => any
}

/**
 * SplitCard Component
 */

const SplitCard: FC<SplitCardProps> = ({
	bgImage,
	logoImage,
	text,
	title,
	children,
	buttonText,
	buttonLink,
	buttonDisabled,
	className,
	buttonAction,
}) => {
	// Has Button
	const hasButton = buttonText ? true : false

	const textClass = classNames(
		'text-black',
		hasButton ? 'text-center' : undefined,
		className
	)

	// Render
	return (
		<Card bg="white" style={{ maxWidth: '100%' }}>
			<div className="flex-content-center justify-content-center text-center">
				<Card.Img variant="top" src={bgImage} />
				<img src={logoImage} className="card-logo-center" />
			</div>
			<Card.Body>
				{(title ? true : false) && <Card.Title as="h3">{title}</Card.Title>}
				<Card.Text as="div" className={textClass}>
					<div>
						{/* Normal Content */}
						{text ? <MultiLineText text={text as string} /> : children}
					</div>
					<MultiLineText text={text} />
					{hasButton && (
						<RSButton1
							disabled={buttonDisabled}
							href={buttonLink || undefined}
							onClick={buttonAction || undefined}
							sx={{width: '100%', maxWidth: '250px', mb: 2, textTransform: 'uppercase !important'}}
						>
							{buttonText}
						</RSButton1>
					)}
					<div></div>
				</Card.Text>
			</Card.Body>
		</Card>
	)
}

export default SplitCard
