import { FC, useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import RSInput from '../../components/input/RSInput'
import {RSButton1} from '../../components/buttons';
import { SH1 } from '../../components/text/RSTypography'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type LocationProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
	getLocation?: any,
	description?: string
}

const Location: FC<LocationProps> = ({ data, handleUpdateProfile, getLocation, description }) => {
	const { location } = data
	const [locationValue, setLocation] = useState<any>(location || '');

	const handleSave = async () => {
		handleUpdateProfile({ location: locationValue }, 'CHANGE_LOCATION')
	}

	useEffect(() => {
		setLocation(location)
	}, [location])

	return (
		<Box>
			<SH1 size={1} sx={{width: '100%', mb: 1, ...description && {fontFamily: 'Poppins-Light !important'}}}>{description || 'Where are you located?'}</SH1>
			<Grid container xs={12} sx={{ position: 'relative' }}>
				<RSInput sx={{ width: getLocation ? 'calc(100% - 50px)' : '100%' }} value={locationValue} setValue={setLocation} title={'City or Zip Code'} />
				{getLocation ? <RSButton1 secondary onClick={() => getLocation?.()} sx={{ height: 'auto', width: '45px', minWidth: '0px', justifyContent: 'center', alignItems: 'center', ml: '5px', mt: '10px' }}>
					<i className='fa-light fa-location-arrow' style={{ fontSize: '22px' }} />
				</RSButton1> : <></>}
			</Grid>
			<RSButton1 onClick={handleSave} disabled={location == locationValue} sx={{ mt: 3 }}>Save</RSButton1>
		</Box>
	)
}

export default Location;
