import {FC, useState, useEffect} from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import {RSButton1, RSButton2} from '../../components/buttons';
import { SH1 } from '../../components/text/RSTypography'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type RunningStyleProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
}

const RunningStyle: FC<RunningStyleProps> = ({data, handleUpdateProfile}) => {
	const {running_style} = data
	const [runningStyle, setRunningStyle] = useState<any>(running_style || ''  );

	const activities = [{
		title: 'Heel Strike',
		rotate: -20
	},{
		title: 'Midfoot Strike',
		rotate: 0
	},{
		title: 'Toe Strike',
		rotate: 20
	},{
		title: 'Unsure'
	}]

	const handleSave = async () => {
		handleUpdateProfile({ running_style: runningStyle })
	}

	useEffect(()=>{
		setRunningStyle(running_style)
	},[running_style])
	return (
		<Box>
			<SH1 size={1} sx={{width: '100%', mb: 1}}>What is your current running style?</SH1>
			<Grid container xs={12} justifyContent='space-between'>
				{activities.map((activity: any, ai: number)=>{
					const title = activity.title
					const isActive = title?.toLowerCase() === runningStyle?.toLowerCase()
					const hasImg = activity.rotate !== undefined
					return <Grid item container xs={5.6} key={'activity-'+ai} sx={{minHeight: '100px', width: '100px', mb: '20px'}} justifyContent='center' alignItems='center'>
						<RSButton2 secondary={!isActive} sx={{height: '100%', flexDirection: 'column'}} onClick={()=>setRunningStyle(title)}>
							{hasImg && <Box sx={{mt: '-10px'}}><img src="./img-new/get-started/running-style-shoe.png" style={{width: '70px', transform: `rotate(${activity.rotate}deg)`}} /></Box>}
							<Box sx={{fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px'}}>{title}</Box>
						</RSButton2>
					</Grid>
				})}
			</Grid>
			<RSButton1 onClick={handleSave} disabled={!runningStyle || runningStyle?.toLowerCase() === running_style?.toLowerCase() } sx={{mt: 1}}>Save</RSButton1>
		</Box>
	)
}

export default RunningStyle;
