import { FC } from 'react'

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import RSPopUpModal from './RSPopUpModal'
import RSHeaderCont from './RSHeaderCont'
import RSCloseBtn from '../buttons/RSCloseBtn'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

export type RSDrawerProps = {
	open: boolean,
	onClose: any,
	children?: any,
	sx?: object,
	light?: boolean,
	bottom?: boolean,
	fitContent?: boolean,
	popUpHeight?: string,
	popUpWidth?: string,
	drawerHeight?: string,
	noClose?: boolean,
	headerTitle?: string,
	maxHeight?: string,
	maxWidth?: string,
	fullWidth?: boolean,
	fullHeight?: boolean,
	btnCloseFixed?: boolean,
	popUp?: boolean,
	className?: string,
	preview?: boolean
}


const RSDrawer: FC<RSDrawerProps> = ({ className = '',children, open, onClose, sx = {}, bottom, fitContent, popUpHeight, popUpWidth, drawerHeight, noClose, headerTitle, maxHeight, maxWidth, fullWidth, fullHeight, btnCloseFixed, popUp, preview }) => {
	const isMobile = useIsMobileScreen();
	const bottomsStyle = bottom ? {
		height: fitContent ? 'auto' : (drawerHeight ? drawerHeight : '100%'),
		maxHeight: maxHeight ? maxHeight : '100%',
		borderRadius: (fitContent || drawerHeight || maxHeight) ? '10px 10px 0px 0px' : '0px',
		'& .MuiTableContainer-root, & .MuiTable-root': {
			height: '100%',
			overflow: 'hidden',
			backgroundColor: '#F8F8FA'
		}
	} : {}

	const renderClose = () => {
		return bottom && !noClose ? <Button onClick={onClose} sx={{ zIndex: 10, position: btnCloseFixed ? 'fixed' : 'absolute', top: 5, right: 5, minWidth: '0px', borderRadius: '50%', p: '5px', color: '#000000', stroke: 'white', strokeWidth: '1px', transform: 'rotate(90deg)', height: '27px', width: '27px' }}>
			<RSCloseBtn />
		</Button> : <></>
	}

	const renderBody = () => {
		return <>
			{headerTitle && <RSHeaderCont drawer title={headerTitle} backClick={onClose} />}
			{renderClose()}
			{children}
		</>
	}

	return (bottom || popUp) && !isMobile ?
		<RSPopUpModal
			open={open}
			onClose={(popUp && !isMobile) ? '' : onClose}
			popUpHeight={popUpHeight}
			popUpWidth={popUpWidth}
			popUpMaxHeight={maxHeight}
			popUpMaxWidth={maxWidth}
			preview={preview}
		>
			{renderBody()}
		</RSPopUpModal> : <Drawer
			className={`rs-drawer ${className}`}
			anchor={bottom ? 'bottom' : 'right'}
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					width: '100%',
					maxWidth: bottom ? '900px' : (fullWidth ? '100%' : '600px'),
					border: 'none !important',
					borderRadius: (fullHeight || fullWidth || bottomsStyle?.height === '100%' || !bottom) ? '0' : '10px',
					margin: 'auto',
					fontFamily: 'Poppins',
					backgroundColor: '#fff',
					...bottomsStyle,
					...sx
				}
			}}
		>
			{renderBody()}
		</Drawer>
}

export default RSDrawer;
